import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { Input, Divider, Radio } from 'antd'
import { FORMS } from '../../enums/forms'
import MapContainer from '../../atoms/Map'
import { connect } from "react-redux"
import { withNamespaces } from 'react-i18next'
import { compose, bindActionCreators } from "redux"
import Breadcrumbs from "../../components/header/Breadcrumbs"
import LocationSearchInput from '../../atoms/LocationSearchInput'
import { get } from "lodash"
import { loadCampaignLocation, updateLocation } from "../../actions/locationsActions"
import { makeField } from "../../atoms/utils"
import { withPermissions } from '../../utils/permissionsHoc'
import { PERMISSION } from '../../enums/general'
import { googleMapURL } from '../../utils/globals'

const RadioGroup = Radio.Group

const AInput = makeField(Input)
const ARadioGroup = makeField(RadioGroup)

class EditLocationPage extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		submitting: PropTypes.bool.isRequired,
		change: PropTypes.func.isRequired,
		initialize: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		computedMatch: PropTypes.shape().isRequired,
		updateLocation: PropTypes.func.isRequired,
		loadCampaignLocation: PropTypes.func.isRequired,
		location: PropTypes.shape(),
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		// default values for Zilina
		this.state = {
			radius: 50,
			lat: 49.2290821,
			long: 18.7316373,
			mapLat: 49.2290821,
			mapLng: 18.7316373
		}
	}

	componentDidMount() {
		const { computedMatch, loadCampaignLocation, initialize } = this.props
		const locationId = get(computedMatch, 'params.locationId')
		const campaignId = get(computedMatch, 'params.campaignId')

		if (locationId && campaignId) {
			loadCampaignLocation(campaignId, locationId).then((response) => {
				this.setState(response)
				initialize(response)
			})
		}

	}

	breadcrumbsInit = () => {
		const { t } = this.props
		return {
			items: [{
				link: t('paths:campaigns.path'),
				name: t('paths:campaigns.title')
			}, {
				name: t('paths:locations.title')
			}],
			title: t('pages:Edit location')
		}
	}

	onRadiusChange = (e) => {
		const { name, value } = e.target
		if (name === 'radius') {
			this.setState({ radius: parseInt(value) })
		}
		if (name === 'latitude') {
			this.setState({ lat: parseFloat(value) })
		}
		if (name === 'longitude') {
			this.setState({ long: parseFloat(value) })
		}
	}

	changeLocation = (e) => {
		const { change } = this.props
		this.setState({ long: e.latLng.lng(), lat: e.latLng.lat() })
		change('latitude', e.latLng.lat().toFixed(8))
		change('longitude', e.latLng.lng().toFixed(8))
	}

	selectLocation = (place) => {
		const { change } = this.props
		this.setState({
			mapLng: place.location.lng(),
			mapLat: place.location.lat(),
			long: place.location.lng(),
			lat: place.location.lat()
		})
		change('latitude', place.location.lat().toFixed(8))
		change('longitude', place.location.lng().toFixed(8))
	}

	locationFormHandler = (values) => {
		const { computedMatch, updateLocation } = this.props
		const locationId = get(computedMatch, 'params.locationId')
		const campaignId = get(computedMatch, 'params.campaignId')

		const data = {
			name: get(values, 'name'),
			longitude: parseFloat(get(values, 'longitude')),
			latitude: parseFloat(get(values, 'latitude')),
			radius: parseInt(get(values, 'radius'))
		}
		if (locationId && campaignId) {
			updateLocation(campaignId, locationId, data)
		}

	}

	render = () => {
		const { handleSubmit, t } = this.props
		const { radius, long, lat, mapLat, mapLng } = this.state

		return (
			<React.Fragment>
				<div className={'container-fluid breadcrumbs'}>
					<Breadcrumbs breadcrumbs={this.breadcrumbsInit()}/>
				</div>
				<div className={'container'} style={{ paddingBottom: '100px' }}>
					<form onSubmit={handleSubmit(this.locationFormHandler)} onChange={this.onRadiusChange} noValidate>
						<div className={'row'} style={{ padding: '30px 0' }}>
							<div className={'col-6'}>
								<div className="ant-form-item-label">
									<label title={t('atoms:Search')}>
										{t('atoms:Search')}
									</label>
								</div>
								<LocationSearchInput
									googleMapURL={googleMapURL}
									loadingElement={<div style={{ height: '100%' }}/>}
									containerElement={<div/>}
									placeholder={t('components:Search location')}
									onPlaceSelected={this.selectLocation}
								/>
							</div>
						</div>
						<Divider/>
						<div className={'row'}>
							<div className={'col-md-4'}>
								<Field
									label={t('components:Location name')}
									name='name'
									type={'text'}
									size={'large'}
									required
									component={AInput}
								/>
								<Field
									label={t('components:Latitude')}
									name='latitude'
									type={'text'}
									size={'large'}
									required
									component={AInput}
								/>
								<Field
									label={t('components:Longitude')}
									name='longitude'
									type={'text'}
									size={'large'}
									required
									component={AInput}
								/>
								<Field size={'large'} required name={"radius"} label={t('components:Radius')} component={ARadioGroup} value={50}>
									<Radio value={50}>50m</Radio>
									<Radio value={100}>100m</Radio>
									<Radio value={500}>500m</Radio><br/>
									<Radio value={1000}>1km</Radio>
									<Radio value={2000}>2km</Radio>
									<Radio value={5000}>5km</Radio>
								</Field>
							</div>
							<div className={'col-md-8'} style={{ paddingTop: '40px' }}>
								<MapContainer
									googleMapURL={googleMapURL}
									loadingElement={<div style={{ height: '100%' }}/>}
									containerElement={<div style={{ height: '560px' }}/>}
									mapElement={<div style={{ height: '100%' }}/>}
									lat={lat}
									long={long}
									zoom={18}
									radius={radius}
									mapLat={mapLat}
									mapLng={mapLng}
									radiusMarker
									onLocationChange={this.changeLocation}
								/>
							</div>
						</div>
						<div className={'row'} style={{ paddingTop: '100px' }}>
							<div className={'col-12'}>
								<button type="submit" className={'btn primary pull-right'}>
									{t('pages:Edit location')}
								</button>
							</div>
						</div>
					</form>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	location: get(state, 'locations.location.data'),
})

const mapDispatchToProps = (dispatch) => ({
	loadCampaignLocation: bindActionCreators(loadCampaignLocation, dispatch),
	updateLocation: bindActionCreators(updateLocation, dispatch),
})

export default compose(
	reduxForm({
		form: FORMS.EDIT_LOCATION,
		touchOnChange: true,
		destroyOnUnmount: true
	}),
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces(['atoms', 'components', 'paths']),
	connect(mapStateToProps, mapDispatchToProps)
)(EditLocationPage)
