import React from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import { map, filter, indexOf, get } from 'lodash'
import { Upload, Modal, Icon, Button, Form, Slider } from 'antd'
import { uploadFile } from '../utils/helper'

class MyUpload extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		input: PropTypes.shape({}),
		showCropper: PropTypes.bool,
		width: PropTypes.number,
		height: PropTypes.number,
		multiple: PropTypes.bool,
		listType: PropTypes.string,
		mockup: PropTypes.bool,
		showUploadList: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.shape({
				showPreviewIcon: PropTypes.bool
			})
		]),
		meta: PropTypes.shape({}),
		hasFeedback: PropTypes.bool,
		label: PropTypes.string,
		help: PropTypes.string,
		required: PropTypes.bool,
		gallery: PropTypes.bool
	}

	constructor(props) {
		super(props)

		this.state = {
			uploadedFiles: [],
			cropper: {
				imageUrl: null,
				cropModalVisible: false,
				isLoading: false,
				crop: { x: 0, y: 0 },
				croppedAreaPixels: null,
				zoom: 1
			}
		}
	}

	handleBeforeUpload = (file, fileList) => {
		const { showCropper } = this.props
		const { cropper } = this.state 

		if (showCropper) {
			const cropperImageUrl = get(fileList, '[0]') ? URL.createObjectURL(get(fileList, '[0]')) : null

			this.setState({
				uploadedFiles: fileList,
				cropper: {
					...cropper,
					cropModalVisible: true,
					imageUrl: cropperImageUrl
				}
			})
		} else {
			// upload files
			this.uploadFiles(fileList)
		}

		return false
	}

	uploadFiles = async (fileList) => {
		const { input } = this.props

		const uploadFileList = map(fileList, (file) => {
			const { name, type } = file

			return uploadFile({ name, type, file })
		})
		const uploadedFileList = await Promise.all(uploadFileList)

		// create file object ({ uid, name, type, id, url })
		const uploadedFileObjectList = map(fileList, (file, index) => {
			const { uid, name, type } = file

			return { uid, name, type, id: get(uploadedFileList, `[${index}].data.data.id`), url: get(uploadedFileList, `[${index}].data.data.url`) }
		})

		const joinedUploadList = [...input.value, ...uploadedFileObjectList]

		// change redux value
		input.onChange(joinedUploadList)
	}

	handleCropModalOk = async () => {
		const { uploadedFiles, cropper } = this.state
		const { input, gallery } = this.props

		this.setState({
			cropper: {
				...cropper,
				isLoading: true
			}
		})

		const canvas = await this.getCroppedCanvas()
		
		canvas.toBlob(async (blob) => {
			const { uid, name } = uploadedFiles[0]
			const { type } = blob

			// name without extension
			const extensionStartIndex = name.lastIndexOf('.')
			const nameWithoutExt = name.substring(0, extensionStartIndex)
			const nameWithPNGExt = `${nameWithoutExt}.png`

			// upload file
			const { data } = await uploadFile({ name: nameWithPNGExt, type, file: blob })
			const uploadedFileObjectList = [{ uid, name, type, id: get(data, 'data.id'), url: get(data, 'data.url') }]

			URL.revokeObjectURL(cropper.imageUrl)

			// change redux value
			if (gallery){
				const joinedUploadList = [...input.value, ...uploadedFileObjectList]
				input.onChange(joinedUploadList)
			} else {
				input.onChange(uploadedFileObjectList)
			}

			// set default parameters to cropper
			this.setState({
				cropper: {
					imageUrl: null,
					cropModalVisible: false,
					isLoading: false,
					crop: { x: 0, y: 0 },
					croppedAreaPixels: null,
					zoom: 1
				}
			})
		})
	}

	handleCropModalCancel = () => {
		const { cropper } = this.state

		URL.revokeObjectURL(cropper.imageUrl)

		// set default parameters to cropper
		this.setState({
			cropper: {
				imageUrl: null,
				cropModalVisible: false,
				isLoading: false,
				crop: { x: 0, y: 0 },
				croppedAreaPixels: null,
				zoom: 1
			}
		})
	}

	getCroppedCanvas = async () => {
		try {
			const { cropper } = this.state
			const image = await new Promise((resolve, reject) => {
				const image = new Image()
				image.addEventListener('load', () => resolve(image))
				image.addEventListener('error', error => reject(error))
				image.src = cropper.imageUrl
			})

			const canvas = document.createElement('canvas')
			canvas.width = cropper.croppedAreaPixels.width
			canvas.height = cropper.croppedAreaPixels.height
			const ctx = canvas.getContext('2d')

			ctx.fillStyle = '#ffffff'
			ctx.fillRect(0, 0, canvas.width, canvas.height)
			ctx.drawImage(
				image,
				cropper.croppedAreaPixels.x,
				cropper.croppedAreaPixels.y,
				cropper.croppedAreaPixels.width,
				cropper.croppedAreaPixels.height,
				0,
				0,
				cropper.croppedAreaPixels.width,
				cropper.croppedAreaPixels.height
			)

			return canvas
		} catch (err) {
			console.log('err')
			return Promise.reject(err)
		}
	}

	handleRemoveImage = (file) => {
		const { input } = this.props

		const fileIndex = indexOf(input.value, file)
		const newFileList = filter(input.value, (file, index) => index !== fileIndex)

		input.onChange(newFileList)
	}

	onCroppedAreaPixelsChange = (croppedAreaPixels) => {
		const { cropper } = this.state

		this.setState({
			cropper: {
				...cropper,
				croppedAreaPixels
			}
		})
	}

	onCropChange = (crop) => {
		const { cropper } = this.state

		this.setState({
			cropper: {
				...cropper,
				crop
			}
		})
	}

	onZoomChange = (zoom) => {
		const { cropper } = this.state

		this.setState({
			cropper: {
				...cropper,
				zoom
			}
		})
	}

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		const { cropper } = this.state

		this.setState({
			cropper: {
				...cropper,
				croppedAreaPixels
			}
		})
	}

	render = () => {
		const { cropper } = this.state
		const { input, multiple, listType, mockup, showUploadList, width, height } = this.props
		const value = input.value
		let listTypeSettings = 'picture'
		if (listType) {
			listTypeSettings = listType
		} else {
			listTypeSettings = multiple ? 'picture' : 'picture-card'
		}
		const uploadButtonSingle = (
			<div>
				<Icon type="plus"/>
				<div className="ant-upload-text">Nahrať</div>
			</div>
		)

		const uploadButtonMultiple = (
			<Button>
				<Icon type="upload"/> Select image
			</Button>
		)
		let mockupUpload = null
		if (mockup){
			if (value.length < 1){
				mockupUpload = uploadButtonSingle
			} else if (value){
				mockupUpload = <img src={get(value, '[0].url')} alt={get(value, '[0].name')} style={{ width: '307px', height: 'auto' }}/>
			}
		}

		const { meta, hasFeedback, label, help, required, ...rest } = this.props
		const hasError = get(meta, 'touched') && get(meta, 'invalid')
		const helpProp = help || (hasError && get(meta, 'error'))

		return (
			<Form.Item
				label={label}
				help={helpProp}
				required={required}
				validateStatus={hasError ? 'error' : 'success'}
				hasFeedback={hasFeedback && hasError}
			>
				<Upload
					listType={listTypeSettings}
					fileList={value}
					showUploadList={showUploadList}
					beforeUpload={this.handleBeforeUpload}
					onRemove={this.handleRemoveImage}
					className="avatar-uploader" {...rest}
				>
					{!mockup && !multiple && value.length <= 10 && uploadButtonSingle}
					{mockupUpload}
					{mockupUpload && uploadButtonSingle}
					{multiple && uploadButtonMultiple}
				</Upload>
				<Modal
					title="Crop image"
					visible={cropper.cropModalVisible}
					forceRender={true}
					onOk={this.handleCropModalOk}
					onCancel={this.handleCropModalCancel}
					confirmLoading={cropper.isLoading}
				>
					<div style={{ position: 'relative', height: '400px', width: '100%' }}>
						<Cropper
							image={cropper.imageUrl}
							crop={cropper.crop}
							aspect={width / height}
							showGrid={false}
							restrictPosition={false}
							zoom={cropper.zoom}
							minZoom={0.1}
							maxZoom={3}
							zoomSpeed={0.1}
							onCropChange={this.onCropChange}
							onCropComplete={this.onCropComplete}
							onZoomChange={this.onZoomChange}
						/>
					</div>
					<Slider
						value={cropper.zoom}
						step={0.01}
						min={0.1}
						max={3}
						tooltipVisible={false}
						onChange={this.onZoomChange}
					/>
				</Modal>
			</Form.Item>
		)
	}
}

export default MyUpload
