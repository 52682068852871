import {
	STORES_LOAD_START,
	STORES_LOAD_FAIL,
	STORES_LOAD_DONE,
	STORE_LOAD_DONE,
	STORE_LOAD_FAIL,
	STORE_LOAD_START
} from '../types/store'

import {RESET_STORE} from "../types/global"

export const initState = {
	stores: {
		isLoading: false,
		isFailure: false,
		data: []
	},
	store: {}
}

export default (state = initState, action) => {
	switch (action.type) {
		case STORES_LOAD_START:
			return {
				...state,
				stores: {
					data: [],
					isFailure: false,
					isLoading: true
				}
			}
		case STORES_LOAD_DONE:
			return {
				...state,
				stores: {
					data:  action.payload.stores,
					isFailure: false,
					isLoading: false
				}
			}

		case STORES_LOAD_FAIL:
			return {
				...state,
				stores: {
					data:  [],
					isFailure: true,
					isLoading: false
				}
			}
		case STORE_LOAD_START:
			return {
				...state,
				store: {}
			}
		case STORE_LOAD_DONE:
			return {
				...state,
				store: action.payload
			}

		case STORE_LOAD_FAIL:
			return {
				...state,
				store: {}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
