import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Input, DatePicker, TimePicker, Checkbox, Select, Icon } from 'antd'
import { get } from 'lodash'
import { withNamespaces } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import moment from 'moment'

import { FORMS } from '../../enums/forms'
import { COMPONENTS, PATHS, ATOMS } from '../../enums/namespaces'
import { makeField, makeFieldDateRange, makeFieldDatePicker, makeCheckboxField } from '../../atoms/utils'
import validateCampaign from './validateCampaign'
import { submitCampaignInfo } from '../../actions/campaignActions'
import { momentToMinutes, minutesToMoment } from '../../utils/formatters'

const { RangePicker } = DatePicker
const { Option } = Select

const AInput = makeField(Input)
const ARangePicker = makeFieldDateRange(RangePicker, { format: 'DD.MM.YYYY' })
const ACheckboxGroup = makeCheckboxField(Checkbox.Group)
const ASelect = makeField(Select)
const ATimePicker = makeFieldDatePicker(TimePicker, { format: 'HH:mm' })

const VIEW_MODE = {
	TURBO: 'TURBO',
	STANDARD: 'STANDARD',
}

const ACTIVATION_MODE = {
	NO_ACTION: 'NO_ACTION',
	LINK: 'LINK',
	QR: 'QR',
	BUTTON: 'BUTTON'
}

const DAYS_SELECTION = {
	WEEKDAYS: 'WEEKDAYS',
	WEEKEND: 'WEEKEND',
	ALL_WEEK: 'ALL_WEEK'
}

class CampaignInfoForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		initialize: PropTypes.func.isRequired,
		nextPage: PropTypes.func.isRequired,
		submitCampaignInfo: PropTypes.func.isRequired,
		submitting: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		campaign: PropTypes.shape(),
		change: PropTypes.func.isRequired
	}

	componentDidMount = () => {
		const { reset, campaign, initialize } = this.props
		const activeDate = (get(campaign, 'validFrom') && get(campaign, 'validTo')) ? [moment(get(campaign, 'validFrom')), moment(get(campaign, 'validTo'))] : []

		const startTime = get(campaign, 'startTime') ? minutesToMoment(get(campaign, 'startTime')) : null
		const endTime = get(campaign, 'endTime') ? minutesToMoment(get(campaign, 'endTime')) : null
		reset()
		initialize({
			name: get(campaign, 'name'),
			activeDate,
			startTime: startTime,
			endTime: endTime,
			weekDays: get(campaign, 'weekDays'),
			views: get(campaign, 'views'),
			viewMode: get(campaign, 'viewMode') || [],
			activationMode: get(campaign, 'activationMode') || [],
			language: get(campaign, 'language') || [],
		})
	}

	campaignFormHandler = (values) => {
		const { campaign, nextPage, submitCampaignInfo } = this.props
		const validFrom = get(values, 'activeDate[0]') ? get(values, 'activeDate[0]').unix() : null
		const validTo = get(values, 'activeDate[1]') ? get(values, 'activeDate[1]').unix() : null
		const startTime = get(values, 'startTime') ? momentToMinutes(moment(get(values, 'startTime'))) : null
		const endTime = get(values, 'endTime') ? momentToMinutes(moment(get(values, 'endTime'))) : null

		values.validFrom = validFrom * 1000
		values.validTo = validTo * 1000
		values.startTime = startTime
		values.endTime = endTime

		submitCampaignInfo(campaign.id, values)
		nextPage()
	}

	daysMultiSelectHandler = (e, type) => {
		const { change } = this.props

		e.preventDefault()

		switch (type) {
			case DAYS_SELECTION.WEEKDAYS:
				change('weekDays', [0, 1, 2, 3, 4])
				break
			case DAYS_SELECTION.WEEKEND:
				change('weekDays', [5 , 6])
				break
			case DAYS_SELECTION.ALL_WEEK:
				change('weekDays', [0, 1, 2, 3, 4, 5, 6])
				break
		}
	}

	render = () => {
		const { handleSubmit, t, campaign } = this.props
		const disabled = campaign.state !== 'DRAFT'
		// const disabled = false
		return (
			<form onSubmit={handleSubmit(data => this.campaignFormHandler(data))} noValidate>
				<div className={'row'}>
					<div className={'col-md-8'}>
						<Field
							label={t('components:CampaignInfoForm.Názov kampane')}
							placeholder={t('components:CampaignInfoForm.Zadajte názov kampane')}
							name='name'
							type={'text'}
							size="large"
							required
							disabled={disabled}
							component={AInput}
						/>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<Field
									label={t('components:Active date')}
									name="activeDate"
									size="large"
									required
									component={ARangePicker}
									placeholder={[t('components:From'), t('components:To')]}
									hasFeedback
									onFocus={e => e.preventDefault()}
									onBlur={e => e.preventDefault()}
									disabled={disabled}
									ranges={{
										[t('atoms:dateRanges.Today')]: [moment(), moment()],
										[t('atoms:dateRanges.This Week')]: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
										[t('atoms:dateRanges.This Month')]: [moment().startOf('month'), moment().endOf('month')],
										[t('atoms:dateRanges.This Year')]: [moment().startOf('year'), moment().endOf('year')]
									}}
								/>
							</div>
						</div>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<Field
									label={t('components:Start time')}
									name="startTime"
									component={ATimePicker}
									placeholder={t('components:CampaignInfoForm.Čas začiatku')}
									hasFeedback
									minuteStep={30}
									size="large"
									onFocus={e => e.preventDefault()}
									onBlur={e => e.preventDefault()}
									disabled={disabled}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									label={t('components:End time')}
									name="endTime"
									component={ATimePicker}
									placeholder={t('components:CampaignInfoForm.Čas ukončenia')}
									hasFeedback
									minuteStep={30}
									size="large"
									onFocus={e => e.preventDefault()}
									onBlur={e => e.preventDefault()}
									disabled={disabled}
								/>
							</div>
						</div>
						<div className={'row'}>
							<div className={'col-12'}>
								<Field
									name="weekDays"
									size="large"
									label={t('components:Only in these days')}
									component={ACheckboxGroup}
									disabled={disabled}
								>
									<Checkbox value={0}>{t('components:Mon')}</Checkbox>
									<Checkbox value={1}>{t('components:Tue')}</Checkbox>
									<Checkbox value={2}>{t('components:Wed')}</Checkbox>
									<Checkbox value={3}>{t('components:Thu')}</Checkbox>
									<Checkbox value={4}>{t('components:Fri')}</Checkbox>
									<Checkbox value={5}>{t('components:Sat')}</Checkbox>
									<Checkbox value={6}>{t('components:Sun')}</Checkbox>
								</Field>
							</div>
						</div>
						<div className={'row'} style={{ paddingBottom: '25px' }}>
							<div className={'col-md-4'}>
								<button
									onClick={(e) => this.daysMultiSelectHandler(e, DAYS_SELECTION.WEEKDAYS)}
									className={'btn'}
									style={{ width: '100%' }}
								>
									{t('components:Weekdays')}
								</button>
							</div>
							<div className={'col-md-4'}>
								<button
									onClick={(e) => this.daysMultiSelectHandler(e, DAYS_SELECTION.WEEKEND)}
									className={'btn'}
									style={{ width: '100%' }}
								>
									{t('components:Weekend')}
								</button>
							</div>
							<div className={'col-md-4'}>
								<button
									onClick={(e) => this.daysMultiSelectHandler(e, DAYS_SELECTION.ALL_WEEK)}
									className={'btn'}
									style={{ width: '100%' }}
								>
									{t('components:AllWeek')}
								</button>
							</div>
						</div>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<Field
									label={t('components:Number of views')}
									placeholder={t('components:CampaignInfoForm.Počet zobrazení')}
									name='views'
									type={'text'}
									size="large"
									required
									component={AInput}
									disabled={disabled}
								/>
							</div>
						</div>
						<Field
							size="large"
							label={t('components:CampaignInfoForm.Zobrazovací mód')}
							name="viewMode"
							component={ASelect}
							placeholder={t('components:CampaignInfoForm.Vyberte mód')}
							required
							disabled={disabled}
						>
							<Option value={VIEW_MODE.STANDARD}>
								<div className={'select-option-title'}>{t('components:Standard title')}</div>
								<div className={'select-option-text'}>{t('components:Standard text')}</div>
							</Option>
							<Option value={VIEW_MODE.TURBO}>
								<div className={'select-option-title'}>{t('components:Turbo title')}</div>
								<div className={'select-option-text'}>{t('components:Turbo text')}</div>
							</Option>
						</Field>
						<Field
							size="large"
							label={t('components:CampaignInfoForm.Aktivovací mód')}
							name="activationMode"
							component={ASelect}
							placeholder={t('components:CampaignInfoForm.Vyberte mód')}
							required
							disabled={disabled}
						>
							<Option value={ACTIVATION_MODE.NO_ACTION}>
								<div className={'select-option-title'}>{t('components:Automatic activation.title')}</div>
								<div className={'select-option-text'}>{t('components:Automatic activation.text')}</div>
							</Option>
							<Option value={ACTIVATION_MODE.BUTTON}>
								<div className={'select-option-title'}>{t('components:ActivationByButton.title')}</div>
								<div className={'select-option-text'}>{t('components:ActivationByButton.text')}</div>
							</Option>
						</Field>
						<Field
							size="large"
							required
							label={t('components:Language')}
							name="language"
							placeholder={t('components:CampaignInfoForm.Vyberte jazyk')}
							component={ASelect}
							disabled={disabled}
						>
							<Option value="en">{t('components:English')}</Option>
							<Option value="sk">{t('components:Slovak')}</Option>
						</Field>
					</div>
					<div className={'col-md-4'}>
						<h2>{t('components:How to start?')}</h2>
						<ul className={'disc-list'}>
							<li><b>{t('components:CampaignInfoForm.Vyplňte základné údaje')}</b> {t('components:CampaignInfoForm.ako názov, doba trvania, spôsob aktivácie, limit zobrazení a spôsob zobrazenia pre čo najlepší výsledok kampane')}</li>
							<li><b>{t('components:CampaignInfoForm.Pridajte grafiku')}</b> {t('components:CampaignInfoForm.pre zvýšenie vizuálnej atraktivity kupónu a ľahšiu rozpoznateľnosť')}</li>
							<li><b>{t('components:CampaignInfoForm.Vyberte si miesto')}</b> {t('components:CampaignInfoForm., na ktorom by ste radi zákazníkov oslovili')}</li>
							<li><b>{t('components:CampaignInfoForm.Zvoľte si obchody')}</b> {t('components:CampaignInfoForm.a reťazce, ktoré budú Váš kupón pri nákupe akceptovať')}</li>
							<li><b>{t('components:CampaignInfoForm.Skontrolujte si kampaň')}</b> {t('components:CampaignInfoForm.pred tým, než ju dokončíte')}</li>
							<li>{t('components:CampaignInfoForm.V prípade akéhokoľvek problému sa viete vrátiť do konkrétneho kroku a po oprave pokračovať ')}&nbsp;{t('components:CampaignInfoForm.v procese vytvorenia novej kampane')}</li>
						</ul>
					</div>
					<div className={'col-md-12 steps-action'}>
						<button className={'btn primary-yellow'} type={'submit'} style={{ float: 'right' }}>{t('components:Next step')}&nbsp;<Icon type="right"/></button>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.CAMPAIGN_INFO_FORM,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateCampaign,
})(CampaignInfoForm)

const mapDispatchToProps = (dispatch) => ({
	submitCampaignInfo: bindActionCreators(submitCampaignInfo, dispatch),
})

export default compose(
	connect(null, mapDispatchToProps),
	withNamespaces([COMPONENTS, PATHS, ATOMS]),
)(form)
