import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUser, loadUser, resetPassword } from '../../actions/userActions'
import { isLoggedIn } from '../../utils/auth'
import ProfileForm from '../../components/forms/ProfileForm'

// import PasswordForm from "../../components/forms/PasswordForm"

class UserPage extends React.Component {
	static propTypes = {
		updateUser: PropTypes.func.isRequired,
		resetPassword: PropTypes.func.isRequired,
		user: PropTypes.shape({})
	}

	profileHandler = (data) => {
		const { updateUser } = this.props
		updateUser(data)
	}

	// passwordHandler = (data) => {
	// 	const { resetPassword } = this.props
	// 	resetPassword(data)
	// }

	componentDidMount() {
		loadUser()
	}

	render() {
		const { user } = this.props
		if (!isLoggedIn()) {
			return (<Redirect to={'/'}/>)
		}
		return (
			<div className={'flex-container'}>
				<ProfileForm profile={user} onSubmit={this.profileHandler}/>
				{/* <PasswordForm onSubmit={this.passwordHandler} /> */}
			</div>
		)
	}

}

const mapStateToProps = (state) => ({
	user: state.user.profile,
})

const mapDispatchToProps = (dispatch) => {
	return {
		updateUser: bindActionCreators(updateUser, dispatch),
		resetPassword: bindActionCreators(resetPassword, dispatch),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
