import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { Input, Divider, Radio } from 'antd'
import { FORMS } from '../../enums/forms'
import MapContainer from '../../atoms/Map'
import { connect } from "react-redux"
import { withNamespaces } from 'react-i18next'
import { compose, bindActionCreators } from "redux"
import Breadcrumbs from "../../components/header/Breadcrumbs"
import LocationSearchInput from '../../atoms/LocationSearchInput'
import { get } from "lodash"
import { createLocation } from "../../actions/locationsActions"
import { makeField } from "../../atoms/utils"

import validateLocation from '../../components/forms/validators/validateLocation'
import { withPermissions } from '../../utils/permissionsHoc'
import { PERMISSION } from '../../enums/general'
import { googleMapURL } from '../../utils/globals'

const RadioGroup = Radio.Group

const AInput = makeField(Input)
const ARadioGroup = makeField(RadioGroup)

class AddLocationPage extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		submitting: PropTypes.bool.isRequired,
		change: PropTypes.func.isRequired,
		computedMatch: PropTypes.shape().isRequired,
		createLocation: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
		this.state = {
			radius: 50,
			lat: 49.2290821,
			long: 18.7316373,
			mapLat: 49.2290821,
			mapLng: 18.7316373
		}
	}

	componentDidMount() {
		const { reset } = this.props
		reset()
	}

	breadcrumbsInit = () => {
		const { t } = this.props
		return {
			items: [{
				link: t('paths:campaigns.path'),
				name: t('paths:campaigns.title')
			},{
				name: t('paths:locations.title')
			}],
			title: t('pages:Create location')
		}
	}

	onRadiusChange = (e) => {
		const { name, value } = e.target
		if (name === 'radius'){
			this.setState({ radius: parseInt(value)})
		}
		if (name === 'latitude'){
			this.setState({ lat: parseFloat(value)})
		}
		if (name === 'longitude'){
			this.setState({ long: parseFloat(value)})
		}
	}

	changeLocation = (e) => {
		const {change} = this.props
		this.setState({ long: e.latLng.lng(), lat: e.latLng.lat()})
		change('latitude', e.latLng.lat().toFixed(8))
		change('longitude', e.latLng.lng().toFixed(8))
	}

	selectLocation = (place) => {
		const {change} = this.props
		this.setState({ mapLng: place.location.lng() ,mapLat: place.location.lat(), long: place.location.lng(), lat: place.location.lat()})
		change('latitude', place.location.lat().toFixed(8))
		change('longitude', place.location.lng().toFixed(8))
	}

	locationFormHandler = (data) => {
		const { computedMatch, createLocation } = this.props
		const campaignId = get(computedMatch, 'params.campaignId')

		if (campaignId) {
			createLocation(campaignId,data)
		}
	}

	render = () => {
		const { handleSubmit, t } = this.props
		const { radius, long, lat , mapLat, mapLng} = this.state
		return (
			<React.Fragment>
				<div className={'container-fluid breadcrumbs'}>
					<Breadcrumbs breadcrumbs={this.breadcrumbsInit()}/>
				</div>
				<div className={'container'} style={{paddingBottom: '100px'}}>
					<form onSubmit={handleSubmit(data => this.locationFormHandler(data))} onChange={(e) => this.onRadiusChange(e)} noValidate>
						<div className={'row'} style={{padding: '30px 0'}}>
							<div className={'col-6'}>
								<div className="ant-form-item-label">
									<label title={t('atoms:Search')}>
										{t('atoms:Search')}
									</label>
								</div>
								<LocationSearchInput
									googleMapURL={googleMapURL}
									loadingElement={<div style={{ height: '100%' }} />}
									containerElement={<div />}
									onPlaceSelected={(location) => this.selectLocation(location)}
								/>
							</div>
						</div>
						<Divider/>
						<div className={'row'}>
							<div className={'col-md-4'}>
								<Field
									label={t('components:Location name')}
									name='name'
									type={'text'}
									size={'large'}
									required
									component={AInput}
								/>
								<Field
									label={t('components:Latitude')}
									name='latitude'
									type={'text'}
									size={'large'}
									required
									component={AInput}
								/>
								<Field
									label={t('components:Longitude')}
									name='longitude'
									type={'text'}
									size={'large'}
									required
									component={AInput}
								/>
								<Field name={"radius"} required size={'large'} label={t('components:Radius')} component={ARadioGroup} value={50}>
									<Radio value={50}>50m</Radio>
									<Radio value={100}>100m</Radio>
									<Radio value={500}>500m</Radio><br/>
									<Radio value={1000}>1km</Radio>
									<Radio value={2000}>2km</Radio>
									<Radio value={5000}>5km</Radio>
								</Field>
							</div>
							<div className={'col-md-8'} style={{paddingTop: '40px'}}>
								<MapContainer
									googleMapURL={googleMapURL}
									loadingElement={<div style={{ height: '100%' }} />}
									containerElement={<div style={{ height: '560px' }} />}
									mapElement={<div style={{ height: '100%' }} />}
									lat={lat} long={long} zoom={18} radius={radius}
									mapLat={mapLat} mapLng={mapLng}
									radiusMarker
									onLocationChange={this.changeLocation}
								/>
							</div>
						</div>
						<div className={'row'}>
							<div className={'col-12 steps-action'}>
								<button type="submit" className={'btn primary pull-right'}>
									{t('pages:Create location')}
								</button>
							</div>
						</div>
					</form>
				</div>
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	createLocation: bindActionCreators(createLocation, dispatch),
})

const form = reduxForm({
	form: FORMS.ADD_LOCATION,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateLocation
})(AddLocationPage)

export default compose(
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces(['atoms','components', 'paths']),
	connect(null, mapDispatchToProps)
)(form)
