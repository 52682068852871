
export const CAMPAIGN_TYPES = {
	PRODUCT_PROMOTION: "PRODUCT_PROMOTION",
	GEO_FENCING: "GEO_FENCING",
	BEACON: 'BEACON'
}

export const MSG_TYPE = {
	ERROR: 'ERROR',
	INFO: 'INFO',
	SUCCESS: 'SUCCESS',
	WARNING: 'WARNING'
}

export const MSG_TYPE_TRANSLATION = {
	ERROR: 'Chyba',
	INFO: 'Info',
	SUCCESS: 'Hotovo',
	WARNING: 'Upozornenie'
}

export const BRANCHES = {
	EURONICS: 'Euronics',
	TPD: 'TPD',
	DOMOSS: 'Domoss',
}

export const AUDIENCES = {
	WEB_REGISTRATION: 'WEB_REGISTRATION',
	APP_REGISTRATION: 'APP_REGISTRATION',
	WEB_LOGIN: 'WEB_LOGIN',
	APP_LOGIN: 'APP_LOGIN',
	ADMIN_INVITATION: 'ADMIN_INVITATION',
	WEB_FORGOT_PASSWORD: 'WEB_FORGOT_PASSWORD',
	APP_FORGOT_PASSWORD: 'APP_FORGOT_PASSWORD'
}

export const SEARCH_SEPARATOR = ':::'

export const GIFT_CODE = {
	ACTIVE: 'ACTIVE',
	CANCELLED: 'CANCELLED',
	SENT: 'SENT',
	USED: 'USED'
}

export const GIFT_CODE_VALIDATION_STATE = {
	ACTIVE: 'ACTIVE', // not assigned to user
	USED: 'USED', // already used
	CANCELED: 'CANCELED', // canceled
	SENT: 'SENT', // valid
	NON_EXISTING: 'NON_EXISTING', // not existing
	NOT_ASSIGNED_TO_BRANCH: 'NOT_ASSIGNED_TO_BRANCH', // not assigned to branch
	EXPIRED: 'EXPIRED', // expired
	NOT_STARTED: 'NOT_STARTED' // not started
}

export const PERMISSION = {
	ADMINISTRATOR: 'ADMINISTRATOR',
	MANAGER: 'MANAGER',
	CASHIER: 'CASHIER'
}
