import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import cx from 'classnames'
import failure_screen from '../public/images/failure_screen.svg'

class Loading extends Component {
	static propTypes = {
		visible: PropTypes.bool,
		failure: PropTypes.bool,
	}

	render() {
		const {visible, failure} = this.props
		return (
			<React.Fragment>
				<div id="loading" className={cx('page-loading', {'shown': visible})}>
					<div className="loader">Loading...</div>
				</div>
				<div id="loading" className={cx('page-failure', {'shown': failure})}>
					<div className={'flex-container'}>
						<img src={failure_screen} alt={'failure'}/>
						<h2>Oooops. Niekde nastala chyba.</h2>
						<h4>Prosím, akciu opakujte neskôr.</h4>
						<Link to={'/'}><button className={'btn primary-blue'} style={{marginTop: '20px'}}>Späť na hlavnú stránku</button></Link>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

const mapDispatchToProps = () => ({
})

const mapStateToProps = (state) => ({
	visible: state.loading.visible,
	failure: state.loading.failure,
})

export default connect(mapStateToProps, mapDispatchToProps)(Loading)
