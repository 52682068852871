import React from 'react'
import { get } from 'lodash'
import { withNamespaces } from "react-i18next"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { reset } from 'redux-form'

import CreateCampaignModal from '../../components/modals/CreateCampaignModal'

import { createCampaign } from "../../actions/campaignActions"

// enums
import { PATHS, PAGES } from "../../enums/namespaces"
import { MODALS } from "../../enums/modals"
import { CAMPAIGN_TYPES, PERMISSION } from "../../enums/general"
import { FORMS } from '../../enums/forms'
import { withPermissions } from '../../utils/permissionsHoc'

class CreateCampaignPage extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		createCampaign: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			modal: null,
			modalData: null,
			type: null,
		}
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null,
			type: null,
		})
	}

	openModal = (modal,type) => {
		return (modalData) => {
			this.setState({
				modal,
				type,
				modalData
			})
		}
	}

	createHandler = async (values) => {
		const { createCampaign, dispatch } = this.props
		const { type } = this.state
		createCampaign({
			type: type,
			name: values.campaignName
		})
		dispatch(reset(FORMS.CREATE_CAMPAIGN_FORM))
		this.dismissModal()
	}

	render() {
		const { t } = this.props
		const { modal } = this.state
		return (
			<div className={"container"}>
				<div className={'row'}>
					<div className={'col-md-4'}>
						<div className={'campaign-category align-self-stretch'} onClick={this.openModal(MODALS.CREATE_CAMPAIGN_MODAL, CAMPAIGN_TYPES.GEO_FENCING)}>
							<div className={'cover'} style={{ backgroundImage: 'url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/campaign-select-1.png)' }}>
								<div className={'icon'} style={{ backgroundImage: 'url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/icons/marker-white.svg)' }}/>
							</div>
							<div className={'content'}>
								<h2>{t('pages:Geo fencing')}</h2>
								<h4>{t('pages:Geo fencing subtitle')}</h4>
								<p>{t('pages:Geo fencing text')}</p>
							</div>
						</div>
					</div>
					<div className={'col-md-4'}>
						<div className={'campaign-category align-self-stretch'} onClick={this.openModal(MODALS.CREATE_CAMPAIGN_MODAL, CAMPAIGN_TYPES.PRODUCT_PROMOTION)}>
							<div className={'cover'} style={{ backgroundImage: 'url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/campaign-select-2.png)' }}>
								<div className={'icon'} style={{ backgroundImage: 'url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/icons/product-white.svg)' }}/>
							</div>
							<div className={'content'} style={{ backgroundImage: 'url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/icons/marker-white.svg)' }}>
								<h2>{t('pages:Product promotion')}</h2>
								<h4>{t('pages:Product promotion subtitle')}</h4>
								<p>{t('pages:Product promotion text')}</p>
							</div>
						</div>
					</div>
					<div className={'col-md-4'}>
						<div className={'campaign-category inactive align-self-stretch'}>
							<div className={'cover'}>
								<div className={'icon'} style={{ 
									backgroundImage: 'url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/icons/beacons-white.svg)'
								}}/>
							</div>
							<div className={'content'}>
								<h2>{t('pages:Beacons')}</h2>
								<h4>{t('pages:Beacons subtitle')}</h4>
								<p>{t('pages:Beacons text')}</p>
							</div>
						</div>
					</div>
				</div>
				<CreateCampaignModal
					dismissHandler={this.dismissModal}
					onSubmit={this.createHandler}
					shown={modal === MODALS.CREATE_CAMPAIGN_MODAL}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	campaigns: get(state, 'campaigns.data')
})

const mapDispatchToProps = (dispatch) => ({
	createCampaign: bindActionCreators(createCampaign, dispatch),
	dispatch
})

export default compose(
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces([PAGES, PATHS]),
	connect(mapStateToProps, mapDispatchToProps)
)(CreateCampaignPage)
