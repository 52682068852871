import { get } from 'lodash'
//utils
import { getReq, postReq, patchReq, deleteReq } from "../utils/request"
import { history } from '../utils/history'
// enums
import { ENDPOINTS } from "../enums/endpoints"

// types
import {
	LOCATION_LOAD_FAIL,
	LOCATION_LOAD_DONE,
	LOCATION_LOAD_START,
	LOCATIONS_LOAD_FAIL,
	LOCATIONS_LOAD_DONE,
	LOCATIONS_LOAD_START,
	LOCATION_CREATE_START,
	LOCATION_CREATE_FAIL,
	LOCATION_CREATE_DONE,
	LOCATION_UPDATE_START,
	LOCATION_UPDATE_DONE,
	LOCATION_UPDATE_FAIL,
	LOCATION_DELETE_START,
	LOCATION_DELETE_DONE,
	LOCATION_DELETE_FAIL,
	CAMPAIGN_LOCATIONS_ASSIGN_START,
	CAMPAIGN_LOCATIONS_ASSIGN_DONE,
	CAMPAIGN_LOCATIONS_ASSIGN_FAIL,
	CAMPAIGN_LOCATIONS_UNASSIGN_START,
	CAMPAIGN_LOCATIONS_UNASSIGN_DONE,
	CAMPAIGN_LOCATIONS_UNASSIGN_FAIL
} from '../types/location'
import { t } from "i18next"
import { loadCampaign } from "./campaignActions"
import { failedLoading, hideLoading, showLoading } from "./loadingActions"

export const loadCampaignLocations = (campaignId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: LOCATIONS_LOAD_START
			})
			const { data } = await getReq(ENDPOINTS.CAMPAIGN_LOCATIONS(campaignId))

			dispatch({
				type: LOCATIONS_LOAD_DONE,
				payload: {
					locations: data
				}
			})
		} catch (e) {
			dispatch({
				type: LOCATIONS_LOAD_FAIL
			})
		}
	}
}

export const loadCampaignLocation = (campaignId, locationId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: LOCATION_LOAD_START
			})
			const { data } = await getReq(ENDPOINTS.CAMPAIGN_LOCATION(campaignId, locationId))
			dispatch({
				type: LOCATION_LOAD_DONE,
				payload: {
					location: data
				}
			})
			return data
		} catch (e) {
			dispatch({
				type: LOCATION_LOAD_FAIL
			})
		}
	}
}

export function createLocation(campaignId,location) {
	return async (dispatch) => {
		dispatch({
			type: LOCATION_CREATE_START
		})
		try {
			await postReq(ENDPOINTS.CAMPAIGN_LOCATIONS(campaignId), null, location)
			dispatch({
				type: LOCATION_CREATE_DONE
			})
			history.push(t('paths:campaign.path', { campaignId:campaignId, step: 'poloha' } ))
		} catch (error) {
			dispatch({
				type: LOCATION_CREATE_FAIL
			})
		}
	}
}

export function updateLocation(campaignId, locationId, data) {
	return async (dispatch) => {
		dispatch({
			type: LOCATION_UPDATE_START
		})
		try {
			await patchReq(ENDPOINTS.CAMPAIGN_LOCATION(campaignId,locationId), null, data)
			dispatch({
				type: LOCATION_UPDATE_DONE,
				payload: data
			})
		} catch (error) {
			dispatch({
				type: LOCATION_UPDATE_FAIL
			})
		}
	}
}
export function deleteLocation(campaignId, locationId) {
	return async (dispatch) => {
		dispatch({
			type: LOCATION_DELETE_START
		})
		try {
			await deleteReq(ENDPOINTS.CAMPAIGN_LOCATION(campaignId,locationId))
			dispatch({
				type: LOCATION_DELETE_DONE
			})
			dispatch(loadCampaign(campaignId))
		} catch (error) {
			dispatch({
				type: LOCATION_DELETE_FAIL
			})
		}
	}
}

export const loadUserLocations = () => {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch({
				type: LOCATIONS_LOAD_START
			})

			const { data } = await getReq(ENDPOINTS.LOCATIONS)
			
			dispatch({
				type: LOCATIONS_LOAD_DONE,
				payload: {
					locations: get(data, 'locations')
				}
			})
			dispatch(hideLoading())
		} catch (e) {
			dispatch({
				type: LOCATIONS_LOAD_FAIL
			})
			dispatch(failedLoading())
		}
	}
}

export const loadUserLocation = (locationId) => {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch({
				type: LOCATION_LOAD_START
			})
			
			const { data } = await getReq(ENDPOINTS.LOCATION(locationId))
			
			dispatch({
				type: LOCATION_LOAD_DONE,
				payload: {
					location: data
				}
			})
			dispatch(hideLoading())
			
			return data
		} catch (e) {
			dispatch({
				type: LOCATION_LOAD_FAIL
			})
			dispatch(failedLoading())
		}
	}
}

export function createUserLocation(location) {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch({
				type: LOCATION_CREATE_START
			})

			await postReq(ENDPOINTS.LOCATIONS, null, location)

			dispatch({
				type: LOCATION_CREATE_DONE
			})
			dispatch(hideLoading())

			history.push(t('paths:locations.path'))
		} catch (error) {
			dispatch({
				type: LOCATION_CREATE_FAIL
			})
			dispatch(failedLoading())
		}
	}
}

export function updateUserLocation(locationId, data) {
	return async (dispatch) => {
		dispatch(showLoading())
		dispatch({
			type: LOCATION_UPDATE_START
		})
		try {
			await patchReq(ENDPOINTS.LOCATION(locationId), null, data)
			dispatch({
				type: LOCATION_UPDATE_DONE,
				payload: data
			})
			dispatch(hideLoading())
		} catch (error) {
			dispatch({
				type: LOCATION_UPDATE_FAIL
			})
			dispatch(failedLoading())
		}
	}
}
export function deleteUserLocation(locationId) {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch({
				type: LOCATION_DELETE_START
			})

			await deleteReq(ENDPOINTS.LOCATION(locationId))

			dispatch({
				type: LOCATION_DELETE_DONE
			})
			dispatch(loadUserLocations())
		} catch (error) {
			dispatch({
				type: LOCATION_DELETE_FAIL
			})
			dispatch(failedLoading())
		}
	}
}

export const assignCampaignLocations = (campaignId, locationIDs) => {
	return async (dispatch) => {
		dispatch(showLoading())
		dispatch({
			type: CAMPAIGN_LOCATIONS_ASSIGN_START
		})
		try {
			await patchReq(ENDPOINTS.CAMPAIGN_ASSIGN_LOCATIONS(campaignId), null, { locationIDs })
			
			dispatch({
				type: CAMPAIGN_LOCATIONS_ASSIGN_DONE
			})
			dispatch(loadCampaign(campaignId))
		} catch (error) {
			dispatch({
				type: CAMPAIGN_LOCATIONS_ASSIGN_FAIL
			})
			dispatch(failedLoading())
		}
	}
}

export const unassignCampaignLocations = (campaignId, locationID) => {
	return async (dispatch) => {
		dispatch(showLoading())
		dispatch({
			type: CAMPAIGN_LOCATIONS_UNASSIGN_START
		})
		try {
			await deleteReq(ENDPOINTS.CAMPAIGN_UNASSIGN_LOCATIONS(campaignId, locationID))

			dispatch({
				type: CAMPAIGN_LOCATIONS_UNASSIGN_DONE
			})
			dispatch(loadCampaign(campaignId))
		} catch (error) {
			dispatch({
				type: CAMPAIGN_LOCATIONS_UNASSIGN_FAIL
			})
			dispatch(failedLoading())
		}
	}
}
