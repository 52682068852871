import {
	LOADING_END,
	LOADING_START,
	LOADING_FAIL
} from '../types/loading'

export function showLoading() {
	return {
		type: LOADING_START,
	}
}

export function hideLoading() {
	return {
		type: LOADING_END
	}
}

export function failedLoading() {
	return {
		type: LOADING_FAIL
	}
}
