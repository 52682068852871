import React from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { PAGES, PATHS } from '../enums/namespaces'
import { FORMS } from '../enums/forms'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form'

import AUploadImage from './UploadImage'

class Mockup extends React.Component {

	static propTypes = {
		t: PropTypes.func.isRequired,
		description: PropTypes.string,
		name: PropTypes.string,
		label: PropTypes.number,
		labelType: PropTypes.string,
	}

	static defaultProps = {
		description: 'popis...',
		name: 'Názov',
		label: 0,
		labelType: '%',
	}

	render() {
		const { name, label, labelType, description, t } = this.props
		return (
			<React.Fragment>
				<div className="mockup">
					<div className={'ant-col ant-form-item-label'}><label className={'ant-form-item-required'}>{t('components:Titulný obrázok')}</label></div>
					{/*<img src={mockup} style={{ borderRadius: '50px' }}/>*/}
					<div className={'mockup-bg'}>
						<div className="mockup-image empty crop-btn-upload">
							<div className="controls">
								<div className="button crop-btn-upload inverse">
									<Field
										required
										name="mediaID"
										className="avatar-uploader"
										showUploadList={false}
										listType="picture"
										width={247.5}
										height={230}
										mockup={true}
										showCropper={true}
										t={t}
										component={AUploadImage}
									/>
								</div>
							</div>
						</div>
						<div className="mockup-ribbon-content">
							<span className="mockup-ribbon-value">{label || '-'}</span>
							<span className="mockup-ribbon-sale-type">{labelType}</span>
						</div>
						<div className="mockup-content">
							<div className="mockup-title">{name}</div>
							<div className="mockup-distance">{t('components:distance')}</div>
							<div className="mockup-remaining">{t('components:validate')}</div>
							<div className="mockup-description">{description}</div>
						</div>
					</div>
				</div>

			</React.Fragment>
		)
	}
}

const selector = formValueSelector(FORMS.VOUCHER_FORM)

export default compose(
	connect(
		state => ({
			name: selector(state, 'name'),
			label: selector(state, 'label'),
			labelType: selector(state, 'labelType'),
			description: selector(state, 'description'),
		}),
	),
	withNamespaces([PAGES, PATHS, 'components']),
)(Mockup)
