import moment from "moment"

export function momentToMinutes(m) {
	return m.utc().hours() * 60 + m.utc().minutes()
}

export function minutesToMoment(minutes) {
	let m = moment().utcOffset(0)
	m.set({
		hour: ('0' + parseInt(minutes / 60)).slice(-2),
		minute: ('0' + parseInt(minutes % 60)).slice(-2)
	})
	return m
}
