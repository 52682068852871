import {
	HIDE_MENU, MENU_ITEM_SELECTED, MENU_TOGGLE_SHOW
} from '../types/menu'
import {RESET_STORE} from "../types/global"

export const initState = {
	visible: false
}

export default (state = initState, action) => {
	switch (action.type) {
		case MENU_ITEM_SELECTED:
			return {
				...state,
				key: action.payload.key
			}
		case MENU_TOGGLE_SHOW:
			return {
				...state,
				visible: !state.visible
			}
		case HIDE_MENU:
			return {
				...state,
				visible: false
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
