import React from 'react'
import PropTypes from 'prop-types'
import { withScriptjs } from "react-google-maps"
import {compose} from "redux"
const { StandaloneSearchBox } = require("react-google-maps/lib/components/places/StandaloneSearchBox")

export class LocationSearchInput extends React.Component {

	static propTypes = {
		bounds: PropTypes.string,
		placeholder: PropTypes.string,
		onPlaceSelected: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		const refs = {}

		this.state = {
			place: {},
			onSearchBoxMounted: ref => {
				refs.searchBox = ref
			},
			onPlacesChanged: () => {
				const {onPlaceSelected} = this.props
				const places = refs.searchBox.getPlaces()
				{places.map(({formatted_address, geometry: { location } }) =>
					this.setState({
						place: {
							formatted_address,
							location
						},
					})
				)}
				onPlaceSelected(this.state.place)
			}
		}
	}

	render = () => {
		const  {bounds, placeholder} = this.props
		const {onSearchBoxMounted, onPlacesChanged} = this.state
		return (
			<div data-standalone-searchbox="">
				<StandaloneSearchBox
					ref={onSearchBoxMounted}
					bounds={bounds}
					onPlacesChanged={onPlacesChanged}
				>
					<input
						type="text"
						placeholder={placeholder || ''}
						className={'ant-input'}
						style={{height: '40px'}}
					/>
				</StandaloneSearchBox>
			</div>
		)
	}
}

export default compose(withScriptjs)(LocationSearchInput)