export const LOCATION_LOAD_START = 'LOCATION_LOAD_START'
export const LOCATION_LOAD_DONE = 'LOCATION_LOAD_DONE'
export const LOCATION_LOAD_FAIL = 'LOCATION_LOAD_FAIL'
export const LOCATIONS_LOAD_START = 'LOCATIONS_LOAD_START'
export const LOCATIONS_LOAD_DONE = 'LOCATIONS_LOAD_DONE'
export const LOCATIONS_LOAD_FAIL = 'LOCATIONS_LOAD_FAIL'
export const LOCATION_CREATE_FAIL = 'LOCATION_CREATE_FAIL'
export const LOCATION_CREATE_START = 'LOCATION_CREATE_START'
export const LOCATION_CREATE_DONE = 'LOCATION_CREATE_DONE'
export const LOCATION_UPDATE_FAIL = 'LOCATION_UPDATE_FAIL'
export const LOCATION_UPDATE_START = 'LOCATION_UPDATE_START'
export const LOCATION_UPDATE_DONE = 'LOCATION_UPDATE_DONE'
export const LOCATION_DELETE_FAIL = 'LOCATION_DELETE_FAIL'
export const LOCATION_DELETE_START = 'LOCATION_DELETE_START'
export const LOCATION_DELETE_DONE = 'LOCATION_DELETE_DONE'
export const CAMPAIGN_LOCATIONS_ASSIGN_FAIL = 'CAMPAIGN_LOCATIONS_ASSIGN_FAIL'
export const CAMPAIGN_LOCATIONS_ASSIGN_START = 'CAMPAIGN_LOCATIONS_ASSIGN_START'
export const CAMPAIGN_LOCATIONS_ASSIGN_DONE = 'CAMPAIGN_LOCATIONS_ASSIGN_DONE'
export const CAMPAIGN_LOCATIONS_UNASSIGN_FAIL = 'CAMPAIGN_LOCATIONS_UNASSIGN_FAIL'
export const CAMPAIGN_LOCATIONS_UNASSIGN_START = 'CAMPAIGN_LOCATIONS_UNASSIGN_START'
export const CAMPAIGN_LOCATIONS_UNASSIGN_DONE = 'CAMPAIGN_LOCATIONS_UNASSIGN_DONE'
