export default (values, { t }) => {
	const errors = {}

	if (!values.name) {
		errors.name = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.latitude) {
		errors.latitude = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.longitude) {
		errors.longitude = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.radius) {
		errors.radius = t('components:validator.validate.Toto pole je povinné')
	}

	return errors
}
