import {get} from 'lodash'

import {showLoading, hideLoading} from './loadingActions'
import {statusPushCreator} from './statusActions'
import {MSG_TYPE} from '../enums/general'
import {ENDPOINTS} from '../enums/endpoints'
import {USER_LOGIN_SUCCESS, USER_LOGOUT} from '../types/auth'
import {history} from '../utils/history'

import {setAccessToken, isLoggedIn, clearAccessToken} from "../utils/auth"
import {resetStore} from "../actions/storeActions"
import {getReq, postReq} from '../utils/request'
import {UPDATE_SETTINGS} from '../types/settings'
import {t} from 'i18next'

function userLoginSuccess(user) {
	return {
		type: USER_LOGIN_SUCCESS,
		payload: {
			user
		}
	}
}

function userLogoutSuccess() {
	return {
		type: USER_LOGOUT,
	}
}

export const loadSettings = () => {
	return async (dispatch) => {
		try {
			const settingsResponse = await getReq(ENDPOINTS.SETTINGS)
			const branchOptions = await getReq(ENDPOINTS.NETWORKS)
			const permissionsOptions = await getReq(ENDPOINTS.PERMISSIONS)

			dispatch({
				type: UPDATE_SETTINGS,
				payload: {
					settings: get(settingsResponse, 'data'),
					networks: get(branchOptions, 'data.networks'),
					permissions: get(permissionsOptions, 'data.permissions')
				}
			})
		} catch(e) {
			console.log(e)
		}
		
	}
}

export function loginUser(data) {
	return async (dispatch) => {
		dispatch(showLoading('Logging'))
		try {
			const response = await postReq(ENDPOINTS.LOGIN, null, data, null)
			const {token} = response.data.data
			if (token) {
				setAccessToken(token)
				const response = await getReq(ENDPOINTS.PROFILE)

				const profile = response.data
				if (profile) {
					dispatch(userLoginSuccess(profile))
				}
			}
			const url = localStorage.getItem('initUrl')
			localStorage.removeItem('initUrl')
			history.push(url || '/')
			dispatch(hideLoading())
		} catch (error) {
			dispatch(statusPushCreator({
				type: MSG_TYPE.ERROR,
				msg: 'login failed'
			}))
			dispatch(hideLoading())
		}
	}
}

export function updateUser(data) {
	return async (dispatch) => {
		dispatch(showLoading('Logging'))
		try {
			const response = await postReq(ENDPOINTS.LOGIN, null, data, null)
			const {token, profile} = response.data
			if (token) {
				setAccessToken(token)
			}
			if (profile) {
				dispatch(userLoginSuccess(profile))
			}
			const url = localStorage.getItem('initUrl')
			localStorage.removeItem('initUrl')
			history.push(url || '/')
			dispatch(hideLoading())
		} catch (error) {
			dispatch(statusPushCreator({
				type: MSG_TYPE.ERROR,
				msg: 'login failed'
			}))
			dispatch(hideLoading())
		}
	}
}

export function logoutUser() {
	return async (dispatch) => {
		localStorage.removeItem('initUrl')
		if (isLoggedIn()) {
			clearAccessToken()
			dispatch(userLogoutSuccess())
			dispatch(resetStore())
		}
		history.push(t('paths:login.path'))
	}
}

export function registerUser(data, token) {
	return async (dispatch) => {
		dispatch(showLoading('Creating'))
		try {
			const headers = {Authorization: `Bearer ${token}`}
			await postReq(ENDPOINTS.REGISTER, null, data, headers)
			history.push(t('paths:login.path'))
			dispatch(hideLoading())
			dispatch(statusPushCreator({
				type: MSG_TYPE.INFO,
				msg: 'Created'
			}))
		} catch (error) {
			dispatch(hideLoading())
		}
	}
}

