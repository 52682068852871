import React from 'react'
import { compose, bindActionCreators } from "redux"
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
import { get, pick, isEqual } from 'lodash'
import { connect } from "react-redux"
import ReactQueryParams from 'react-query-params'
import { getFormInitialValues } from 'redux-form'
import { Link } from 'react-router-dom'
// enums
import { PATHS, PAGES } from "../../../../enums/namespaces"
import { Table, Icon, Modal } from 'antd'

// actions
import * as AdminActions from '../../../../actions/adminActions'

import AdminBranchFilterForm from './AdminBranchFilterForm'

// enums
import { ENDPOINTS } from '../../../../enums/endpoints'
import { FORMS } from '../../../../enums/forms'
import { PERMISSION } from '../../../../enums/general'

// utils
import { deleteReq } from '../../../../utils/request'
import { withPermissions } from '../../../../utils/permissionsHoc'

const allowQueryParams = ['search', 'parentID']

class AdminBranchesPage extends ReactQueryParams {
	static propTypes = {
		t: PropTypes.func.isRequired,
		adminActions: PropTypes.shape().isRequired,
		branches: PropTypes.shape().isRequired
	}

	state = {
		removeBranch: false
	}

	defaultQueryParams = {
		search: '',
		parentID: '[]'
	}

	componentDidMount = () => {
		const { adminActions, branches, filterValues } = this.props

		const normalizeQueryParams = pick(this.queryParams, allowQueryParams)

		// if query param object is empty then set dat from redux
		if (isEqual(normalizeQueryParams, this.defaultQueryParams)) {
			this.setQueryParams(filterValues)
			adminActions.changeBranchFilters(filterValues, get(branches, 'pagination.page'))
		} else {
			adminActions.changeBranchFilters(normalizeQueryParams, get(branches, 'pagination.page'))
		}
	}

	onChangeTable = (pagination) => {
		const { adminActions } = this.props
		adminActions.loadBranches(pagination.current)
	}

	deleteBranch = async () => {
		const { adminActions, branches } = this.props
		const { removeBranch } = this.state
		try {
			await deleteReq(ENDPOINTS.ADMIN_BRANCH(get(removeBranch, 'key')))

			adminActions.loadBranches(get(branches, 'pagination.page'))

			this.setState({ removeBranch: false })
		} catch (e) {
			console.log(e)
		}
	}

	handleFilterSubmit = (values) => {
		const { adminActions, branches } = this.props

		adminActions.changeBranchFilters(values, get(branches, 'pagination.page'))
		this.setQueryParams(values)
	}

	render = () => {
		const { t, branches, settings } = this.props
		const columns = [{
			title: t('pages:AdminBranchesPage.Názov pobočky'),
			dataIndex: 'branchName',
		}, {
			title: t('pages:AdminBranchesPage.Adresa pobočky'),
			dataIndex: 'branchAddress'
		}, {
			title: t('pages:AdminBranchesPage.Kontakt'),
			dataIndex: 'branchContact'
		}, {
			title: t('pages:AdminBranchesPage.Sieť'),
			dataIndex: 'masterBranchName'
		}, {
			title: '',
			key: 'action',
			render: (text, record) => (
				<span>
					<Link to={t('paths:admin.branches.edit.path', { branchId: record.key })}><Icon type="edit"/></Link>
					<a onClick={() => this.setState({ removeBranch: record })}><Icon type="close"/></a>
				</span>
			)
		}]

		return (
			<div style={{ paddingTop: '30px' }}>
				<Modal
					title={t('pages:AdminBranchesPage.Odstránenie pobočky')}
					visible={!!this.state.removeBranch}
					onOk={this.deleteBranch}
					cancelText={t('pages:AdminBranchesPage.Zrušiť')}
					onCancel={() => this.setState({ removeBranch: false })}
				>
					<p>{t('pages:AdminBranchesPage.Ste si istý')}</p>
				</Modal>
				<AdminBranchFilterForm settings={settings} onSubmit={this.handleFilterSubmit}/>
				<h3>{t('pages:AdminBranchesPage.Zoznam pobočiek')}</h3>
				<Link to={t('paths:admin.branches.create.path')}>
					<button className={'btn primary-yellow'} style={{ margin: '10px 0 20px 0' }}>{t('pages:AdminBranchesPage.Pridať pobočku')}</button>
				</Link>
				<Table
					bordered
					columns={columns}
					loading={branches.isLoading}
					onChange={this.onChangeTable}
					pagination={{
						defaultPageSize: 20,
						total: get(branches, 'pagination.count')
					}}
					dataSource={get(branches, 'data')}
				/>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	adminActions: bindActionCreators(AdminActions, dispatch),
	dispatch
})

const mapStateToProps = (state) => ({
	branches: state.admin.branches,
	settings: state.settings.settings,
	filterValues: getFormInitialValues(FORMS.ADMIN_BRANCHES_FILTER)(state)
})

export default compose(
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces([PAGES, PATHS, 'components']),
	connect(mapStateToProps, mapDispatchToProps)
)(AdminBranchesPage)
