import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { reduxForm, Field } from 'redux-form'
import { Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import { FORMS } from '../../enums/forms'
import Joi from 'joi-browser'

import { withNamespaces } from 'react-i18next'

import { COMPONENTS, PAGES, PATHS } from '../../enums/namespaces'
import { makeField } from '../../atoms/utils'

const AInput = makeField(Input)

class LoginForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		submitting: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
	}

	componentDidMount() {
		const { reset } = this.props
		reset()
	}

	render() {
		const { handleSubmit, submitting, t } = this.props
		return (
			<form onSubmit={handleSubmit} noValidate className={'login-form'}>
				<Field
					label={t('components:E-mail')}
					name='email'
					type={'email'}
					size={'large'}
					placeholder={t('components:E-mail')}
					component={AInput}
				/>
				<Field
					label={t('components:Password')}
					name='password'
					type={'password'}
					size={'large'}
					placeholder={t('components:Password')}
					component={AInput}
				/>
				<Form.Item>
					<Link to={t('paths:forgot-password.path')} className={cx('forgot-password', 'pull-right')}>
						<span style={{ color: 'white' }}>{t('paths:forgot-password.title')}</span>
					</Link>
				</Form.Item>
				<button disabled={submitting} type="submit" className={'btn primary vertical'}>
					{t('components:Login')}
				</button>
			</form>
		)
	}
}

const validate = (values, { t }) => {
	const errors = {}

	if (Joi.string().email().validate(values.email).error) {
		errors.email = t('components:validator.validate.Zlý formát e-mailovej adresy')
	}

	if (!values.password) {
		errors.password = t('components:validator.validate.Toto pole je povinné')
	}

	return errors
}

const form = reduxForm({
	form: FORMS.REGISTRATION_FORM,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validate,
})(LoginForm)

export default withNamespaces([PAGES, COMPONENTS, PATHS])(form)
