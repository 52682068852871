/* eslint-disable import/default */
import "core-js/stable"
import "regenerator-runtime/runtime"
import 'unorm'
import '@babel/polyfill/dist/polyfill'

import React from 'react'
import { render } from 'react-dom'
import { history } from './utils/history'

import configureStore from './store/configureStore'
import Root from './pages/Root'

import { PersistGate } from 'redux-persist/es/integration/react'
import './utils/i18n'

import 'antd/dist/antd.css'
import './styles/grid.min.css'
import './styles/global.sass' //global css

require('./favicon.ico') //favicon for webpack

const { store, persistor } = configureStore()

render(
	<PersistGate
		loading="Loading"
		persistor={persistor}
	>
		<Root store={store} history={history}/>
	</PersistGate>,
	document.getElementById('app')
)
