import React from 'react'
import PropTypes from 'prop-types'
import { compose, bindActionCreators } from 'redux'

import moment from 'moment'
import { withNamespaces } from 'react-i18next'

import { COMPONENTS, PATHS } from '../../enums/namespaces'
import { get } from "lodash"

import Item from '../Item'
import { Modal } from 'antd'
import { removeCampaign } from "../../actions/campaignActions"
import { connect } from "react-redux"

const CAMPAIGN_STATUS = {
	active: '#37BD83',
	draft: '#87899C',
	stopped: '#DF4E4E',
	finished: '#5780E9'
}

class Campaign extends React.Component {

	static propTypes = {
		t: PropTypes.func.isRequired,
		campaign: PropTypes.shape().isRequired,
		removeCampaign: PropTypes.func.isRequired,
		type: PropTypes.string.isRequired
	}

	state = {
		removeCampaign: false
	}

	deleteCampaign = () => {
		const { removeCampaign, campaign } = this.props
		this.setState({ removeCampaign: false })
		removeCampaign(get(campaign, 'id'))
	}

	render = () => {
		const { t, campaign, type } = this.props

		let label = ''
		switch (type) {
			case 'active':
				label = t('components:CampaignStatus.Active')
				break
			case 'stopped':
				label = t('components:CampaignStatus.Stopped')
				break
			case 'finished':
				label = t('components:CampaignStatus.Finished')
				break
			case 'draft':
				label = t('components:CampaignStatus.Draft')
				break
		}

		const subContent = (
			<React.Fragment>
				<div className={'campaign-views'}>
					<div>{get(campaign, 'views.used')}/{get(campaign, 'views.total')}</div>
					<small>{t('components:Views')}</small>
				</div>
				<div className={'campaign-likes'}>
					<div>{get(campaign, 'likes')}</div>
					<small>{t('components:Likes')}</small>
				</div>
			</React.Fragment>
		)

		const iconType = {
			GEO_FENCING: 'environment',
			PRODUCT_PROMOTION: 'gift'
		}

		const iconTitle = {
			GEO_FENCING: t('components:Geo fencing'),
			PRODUCT_PROMOTION: t('components:Product promotion')
		}

		const validFrom = get(campaign, 'validFrom') ? moment(get(campaign, 'validFrom')).format('DD.MM.YYYY') : ''
		const validTo = get(campaign, 'validTo') ? moment(get(campaign, 'validTo')).format('DD.MM.YYYY') : ''
		const subtitle1 = validFrom && validTo ? `${validFrom} - ${validTo}` : ''
		const coverImage = get(campaign, 'cover') || "https://s3.eu-central-1.amazonaws.com/korner-app/assets/placeholder.png"

		return (
			<div className={'col-xl-3 col-lg-4 col-md-6'}>
				<Modal
					title={t('pages:Campaign.Odstránenie kampane')}
					visible={!!this.state.removeCampaign}
					onOk={this.deleteCampaign}
					cancelText={t('pages:Campaign.Zrušiť')}
					onCancel={() => this.setState({ removeCampaign: false })}
				>
					<p>{t('pages:Campaign.Ste si istý')}</p>
				</Modal>
				<Item
					icon={{
						title: iconTitle[get(campaign, 'type')],
						type: iconType[get(campaign, 'type')]
					}}
					image={coverImage}
					title={get(campaign, 'name')}
					content={{
						subtitle1,
						icon1: subtitle1 && 'calendar',
						subtitle2: `${get(campaign, 'label', '')} ${get(campaign, 'labelType', '')}`,
						icon2: 'euro',
						subContent
					}}
					label={{
						title: label,
						color: CAMPAIGN_STATUS[type]
					}}
					onRemove={() => this.setState({ removeCampaign: campaign })}
					path={t('paths:campaign.path', { campaignId: get(campaign, 'id'), step: 'kampan' })}
				/>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	removeCampaign: bindActionCreators(removeCampaign, dispatch),
})

export default compose(
	withNamespaces([COMPONENTS, PATHS]),
	connect(null, mapDispatchToProps)
)(Campaign)
