export const ADMIN_BRANCHES_LOAD_START = 'ADMIN_BRANCHES_LOAD_START'
export const ADMIN_BRANCHES_LOAD_DONE = 'ADMIN_BRANCHES_LOAD_DONE'
export const ADMIN_BRANCHES_LOAD_FAIL = 'ADMIN_BRANCHES_LOAD_FAIL'

export const ADMIN_BRANCH_LOAD_START = 'ADMIN_BRANCH_LOAD_START'
export const ADMIN_BRANCH_LOAD_DONE = 'ADMIN_BRANCH_LOAD_DONE'
export const ADMIN_BRANCH_LOAD_FAIL = 'ADMIN_BRANCH_LOAD_FAIL'

export const ADMIN_BRANCH_USERS_LOAD_START = 'ADMIN_BRANCH_USERS_LOAD_START'
export const ADMIN_BRANCH_USERS_LOAD_DONE = 'ADMIN_BRANCH_USERS_LOAD_DONE'
export const ADMIN_BRANCH_USERS_LOAD_FAIL = 'ADMIN_BRANCH_USERS_LOAD_FAIL'

export const ADMIN_USERS_LOAD_START = 'ADMIN_USERS_LOAD_START'
export const ADMIN_USERS_LOAD_DONE = 'ADMIN_USERS_LOAD_DONE'
export const ADMIN_USERS_LOAD_FAIL = 'ADMIN_USERS_LOAD_FAIL'

export const ADMIN_USER_LOAD_START = 'ADMIN_USER_LOAD_START'
export const ADMIN_USER_LOAD_DONE = 'ADMIN_USER_LOAD_DONE'
export const ADMIN_USER_LOAD_FAIL = 'ADMIN_USER_LOAD_FAIL'
