import React from 'react'
import PropTypes from 'prop-types'
import Menu from './Menu'
import { logoutUser } from "../../actions/authActions"
import { withNamespaces } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Menu as Menus, Dropdown, Icon } from 'antd'
import { Link } from "react-router-dom"
import logo from '../../public/images/Euronics_logotype_2col_wht_rgb.png'

class Header extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		processLogout: PropTypes.func.isRequired,
		menuItem: PropTypes.string
	}

	handleMenuSelect = () => {
	}

	render = () => {
		const { processLogout, t, menuItem } = this.props
		const profileDropdown = (
			<Menus>
				<Menus.Item key="0">
					<Link to={t('paths:profile.path')}><Icon type="user"/>&nbsp;{t('paths:profile.title')}</Link>
				</Menus.Item>
				<Menus.Item key="1" onClick={processLogout}><Icon type="logout"/>&nbsp;{t('components:logout')}</Menus.Item>
			</Menus>
		)
		return (
			<header>
				<div className='container'>
					<a href="/"><img className={"logo"} src={logo} alt="logo"/></a>
					<span style={{ paddingLeft: '15px', verticalAllign: '3px' }}>v{process.env.REACT_APP_VERSION}</span>
					<div className={'pull-right'}>
						<Menu onClick={this.handleMenuSelect} menuItem={menuItem}/>
						<Dropdown overlay={profileDropdown} trigger={['click']} placement={"bottomRight"}>
							<div className={'dropdown'}>
								<Icon type="user" className={'icon'}/><Icon type="down"/>
							</div>
						</Dropdown>
					</div>
				</div>
			</header>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	processLogout: bindActionCreators(logoutUser, dispatch),
})

const mapStateToProps = () => ({})

export default compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces('components'))(Header)
