import React from 'react'
import PropTypes from 'prop-types'
import {Icon} from 'antd'
import { Link } from 'react-router-dom'
class Item extends React.Component {
	static propTypes = {
		icon: PropTypes.shape(),
		image: PropTypes.string,
		title: PropTypes.string,
		onClick: PropTypes.func,
		label: PropTypes.shape(),
		cardContentCustom: PropTypes.shape(),
		content: PropTypes.shape(),
		onRemove: PropTypes.func
	}

	constructor(props) {
		super(props)
	}

	render() {
		const { icon, image, title, label, cardContentCustom, content, onRemove, path } = this.props
		const tempContent = (<React.Fragment>
			<div className={'campaign-cover'} style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover' }}>
				{icon && <div className={'cover-label'}>
					{icon.type && <Icon style={{fontSize: '16px'}} type={icon.type} />}
					{icon.title && <span>{icon.title}</span>}
				</div>}
			</div>
			{content && <div className={'card-content'}>
				<div className={'campaign-title'}>
					{title && <h3>{title}</h3>}
				</div>
				<div className={'campaign-date'}>
					{content.icon1 && <Icon type={content.icon1} theme="filled" />}
					{content.subtitle1 && <span>{content.subtitle1}</span>}
				</div>
				<div className={'campaign-time'}>
					{content.icon2 && <Icon type={content.icon2} theme="filled" />}
					{content.subtitle2 && <span>{content.subtitle2}</span>}
				</div>
				<div className={'row justify-content-between'}>
					{content.subContent}
				</div>
			</div>}
			{cardContentCustom && <div className={'card-content'}>{cardContentCustom}</div>}
			{label && <div className={'card-button'} style={{ backgroundColor: label.color || 'green' }}>
				{label.title}
			</div>}
		</React.Fragment>)

		const ctx = (path) ? <Link to={path}>{tempContent}</Link> : tempContent
		return (
			<div className={'campaign-card'}>
				{onRemove && <div className={"campaign-remove"} onClick={onRemove}><Icon type="delete" theme="filled" /></div> }
				{ctx}
			</div>
		)
	}
}

export default (Item)
