import React from 'react'
import Campaign from '../../components/campaigns/Campaign'
import { Row } from 'antd'
import { get, isEmpty, map } from 'lodash'

import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"
import { loadCampaigns } from "../../actions/campaignActions"

import { withNamespaces } from 'react-i18next'
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/header/Breadcrumbs"
import Loading from "../../atoms/Loading"
import { withPermissions } from '../../utils/permissionsHoc'
import { PERMISSION } from '../../enums/general'

class CampaignsPage extends React.Component {

	static propTypes = {
		t: PropTypes.func.isRequired,
		loadCampaigns: PropTypes.func.isRequired,
		campaigns: PropTypes.shape()
	}

	componentDidMount() {
		const { loadCampaigns } = this.props
		loadCampaigns()
	}

	breadcrumbsInit = () => {
		const { t } = this.props
		return {
			items: [{
				name: t('paths:campaigns.title')
			}],
			actions: [{
				path: t('paths:create-campaign.path'),
				title: t('paths:create-campaign.title')
			}],
			title: t('pages:Select campaign')
		}
	}

	render() {
		const { t, campaigns } = this.props

		const activeCampaigns = map(get(campaigns, 'data.active'), (campaign) => <Campaign key={get(campaign, 'id')} type="active" campaign={campaign}/>)
		const draftCampaigns = map(get(campaigns, 'data.draft'), (campaign) => <Campaign key={get(campaign, 'id')} type="draft" campaign={campaign}/>)
		const finishedCampaigns = map(get(campaigns, 'data.finished'), (campaign) => <Campaign key={get(campaign, 'id')} type="finished" campaign={campaign}/>)
		const stoppedCampaigns = map(get(campaigns, 'data.stopped'), (campaign) => <Campaign key={get(campaign, 'id')} type="stopped" campaign={campaign}/>)

		return (
			<React.Fragment>
				<div className={'container-fluid breadcrumbs'}>
					<Breadcrumbs breadcrumbs={this.breadcrumbsInit()}/>
				</div>
				<div className={"container"}>
					{!isEmpty(activeCampaigns) && <React.Fragment><Row>
						<div className={'category-heading'}>
							<h2>
								<div className={'dot green-bg'}></div>{t('pages:CampaignsPage.Active campaigns')}
							</h2>
						</div>
					</Row>
					<Row type={'flex'}>{activeCampaigns}</Row></React.Fragment>}
					{!isEmpty(draftCampaigns) && <React.Fragment><Row>
						<div className={'category-heading'}>
							<h2>
								<div className={'dot lightest-gray-bg'}></div>{t('pages:CampaignsPage.Draft campaigns')}
							</h2>
						</div>
					</Row>
					<Row type={'flex'}>{draftCampaigns}</Row></React.Fragment>}
					{!isEmpty(stoppedCampaigns) && <React.Fragment><Row>
						<div className={'category-heading'}>
							<h2>
								<div className={'dot red-bg'}></div>{t('pages:CampaignsPage.Stopped campaigns')}
							</h2>
						</div>
					</Row>
					<Row type={'flex'}>{stoppedCampaigns}</Row></React.Fragment>}
					{!isEmpty(finishedCampaigns) && <React.Fragment><Row>
						<div className={'category-heading'}>
							<h2>
								<div className={'dot blue-bg'}></div>{t('pages:CampaignsPage.Finished campaigns')}
							</h2>
						</div>
					</Row>
					<Row type={'flex'}>{finishedCampaigns}</Row></React.Fragment>}
				</div>
				<Loading/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	campaigns: get(state, 'campaigns.campaigns')
})

const mapDispatchToProps = (dispatch) => ({
	loadCampaigns: bindActionCreators(loadCampaigns, dispatch),
})

export default compose(
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces(['paths', 'pages']),
	connect(mapStateToProps, mapDispatchToProps)
)(CampaignsPage)
