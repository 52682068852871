import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { loginUser } from '../../actions/authActions'
import { isLoggedIn } from "../../utils/auth"
import LoginForm from "../../components/forms/LoginForm"
import { withNamespaces } from "react-i18next"
import logo from '../../public/images/Euronics_logotype_2col_wht_rgb.svg'

class LoginPage extends React.Component {
	static propTypes = {
		processLogin: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	loginHandler = (data) => {
		const { processLogin } = this.props
		processLogin(data)
	}

	render() {
		const { t } = this.props
		if (isLoggedIn()) {
			return (<Redirect to={t('paths:base.path')}/>)
		}
		return (
			<div className={'form-container'}>
				<img src={logo} alt={'Euronics'}/>
				<LoginForm onSubmit={this.loginHandler}/>
			</div>
		)
	}

}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => {
	return {
		processLogin: bindActionCreators(loginUser, dispatch)
	}
}

export default compose(withNamespaces(['pages', 'paths']), connect(mapStateToProps, mapDispatchToProps))(LoginPage)
