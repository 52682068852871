import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { Input, DatePicker, TimePicker, Icon, Select } from 'antd'
import { FORMS } from '../../enums/forms'
import { withNamespaces } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import { COMPONENTS, PATHS } from '../../enums/namespaces'
import Mockup from '../../atoms/Mockup'
import { makeField, makeSelectField, makeFieldDatePicker } from '../../atoms/utils'
import validateVoucher from '../forms/validators/validateVoucher'
import { get, map, find } from 'lodash'
import moment from 'moment'
import { minutesToMoment, momentToMinutes } from '../../utils/formatters'
import { connect } from 'react-redux'
import { submitVoucher } from '../../actions/campaignActions'
import AUploadImage from '../../atoms/UploadImage'

const AInput = makeField(Input)
const ATextArea = makeField(Input.TextArea)
const ADatePicker = makeFieldDatePicker(DatePicker, { format: 'DD.MM.YYYY' })
const ATimePicker = makeFieldDatePicker(TimePicker, { format: 'HH:mm' })
const ASelect = makeSelectField(Select)
const { Option } = Select

class VoucherForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		nextPage: PropTypes.func.isRequired,
		prevPage: PropTypes.func.isRequired,
		initialize: PropTypes.func.isRequired,
		submitVoucher: PropTypes.func.isRequired,
		categories: PropTypes.shape().isRequired,
		t: PropTypes.func.isRequired,
		voucher: PropTypes.shape(),
		campaignId: PropTypes.number,
		reset: PropTypes.func
	}

	constructor(props) {
		super(props)

		this.state = {
			current: 0,
		}
	}

	componentDidMount = () => {
		const { voucher, categories, initialize, reset } = this.props

		const mockup = [{
			id: get(voucher, 'cover.id'),
			uid: get(voucher, 'cover.id'),
			name: get(voucher, 'cover.id'),
			url: get(voucher, 'cover.url')
		}]

		const gallery = get(voucher, 'gallery')
		const editedGallery = map(gallery, (image) => {
			return {
				id: get(image, 'id'),
				uid: get(image, 'id'),
				name: get(image, 'id'),
				url: get(image, 'url')
			}
		})

		const category = find(get(categories, 'categories'), (category) => get(category, 'id') === get(voucher, 'categoryID'))

		reset()
		initialize({
			endTime: get(voucher, 'endTime') ? minutesToMoment(get(voucher, 'endTime')) : null,
			validTo: get(voucher, 'validTo') ? moment(get(voucher, 'validTo')) : null,
			name: get(voucher, 'name'),
			categoryID: get(category, 'name') || [],
			description: get(voucher, 'description'),
			label: get(voucher, 'label'),
			labelType: get(voucher, 'labelType'),
			mediaID: mockup,
			gallery: editedGallery
		})
	}

	voucherFormHandler = (values) => {
		const { nextPage, campaignId, submitVoucher } = this.props

		values.validTo = get(values, 'validTo') ? get(values, 'validTo').unix() * 1000 : null
		values.endTime = get(values, 'endTime') ? momentToMinutes(moment(get(values, 'endTime'))) : null

		values.gallery = map(get(values, 'gallery'), (image) => {
			return get(image,'id')
		})
		values.mediaID = get(values, 'mediaID.0.id')

		submitVoucher(campaignId, values)

		nextPage()
	}

	uploadedFilesHandler = () => {

	}

	render = () => {
		const { handleSubmit, t, prevPage, categories } = this.props

		const categoryItems = map(get(categories, 'categories'), (category) => {
			// it is important to get key/value as a string cause query params
			return <Option key={`${get(category, 'id')}`} value={`${get(category, 'id')}`}>{get(category, 'name')}</Option>
		})

		return (
			<form onSubmit={handleSubmit(data => this.voucherFormHandler(data))} noValidate>
				<div className={'row'}>
					<div className={'col-12'}>
						<h2>{t('components:voucherForm.title')}</h2>
						<p>{t('components:voucherForm.text')}</p>
					</div>

					<div className={'col-md-8'}>
						<Field
							name="categoryID"
							size="large"
							label={t('components:Category')}
							placeholder={t('components:VoucherForm.Zvoľte kategóriu')}
							required
							component={ASelect}
						>
							{categoryItems}
						</Field>
						<Field
							label={t('components:Voucher name')}
							name={'name'}
							type={'text'}
							size={'large'}
							required
							component={AInput}
							placeholder={t('components:VoucherForm.Zadajte názov kupónu')}
						/>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<Field
									label={t('components:Ribbon value')}
									name={'label'}
									type={'text'}
									size={'large'}
									component={AInput}
									placeholder={t('components:VoucherForm.Zadajte hodnotu zľavy')}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									label={t('components:Ribbon label')}
									name={'labelType'}
									type={'text'}
									size={'large'}
									component={AInput}
									placeholder={t('components:VoucherForm.Zadajte jednotku zľavy')}
								/>
							</div>
						</div>
						<Field
							label={t('components:Detailed description')}
							name={'description'}
							type={'text'}
							size={'large'}
							required
							component={ATextArea}
							rows={5}
							placeholder={t('components:VoucherForm.Zadajte popis')}
						/>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<Field
									label={t('components:Extended valid to')}
									name={'validTo'}
									component={ADatePicker}
									size={'large'}
									hasFeedback
									placeholder={t('components:To')}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									label={t('components:End time')}
									name="endTime"
									component={ATimePicker}
									placeholder={t('components:CampaignInfoForm.Čas ukončenia')}
									hasFeedback
									minuteStep={30}
									size="large"
									onFocus={e => e.preventDefault()}
									onBlur={e => e.preventDefault()}
								/>
							</div>

						</div>
						<div className={'row'}>
							<div className={'col-12'} style={{ paddingTop: '20px' }}>
								<div className="clearfix">
									<Field
										required name="gallery"
										label={t('components:Galéria')}
										showUploadList={{ showPreviewIcon: false }}
										listType="picture-card"
										width={250}
										height={230}
										showCropper={true}
										gallery
										t={t}
										component={AUploadImage}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={'col-md-4'}>
						<Mockup/>
					</div>
					<div className={'col-md-12 steps-action'}>
						<button className={'btn'} style={{ float: 'left' }} onClick={prevPage}>
							<Icon type="left"/>&nbsp;{t('components:Previous step')}</button>
						<button className={'btn primary-yellow'} style={{ float: 'right' }} type={'submit'}>{t('components:Next step')}&nbsp;
							<Icon type="right"/></button>
					</div>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (state) => ({
	categories: get(state, 'settings.settings.data'),
})

const mapDispatchToProps = (dispatch) => ({
	submitVoucher: bindActionCreators(submitVoucher, dispatch),
})

const form = reduxForm({
	form: FORMS.VOUCHER_FORM,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateVoucher,
})(VoucherForm)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withNamespaces([COMPONENTS, PATHS]),
)(form)
