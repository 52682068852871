export const FORMS = {
	REGISTRATION_FORM: 'REGISTRATION_FORM',
	LOGIN_FORM: 'LOGIN_FORM',
	PROFILE_FORM: 'PROFILE_FORM',
	PASSWORD_FORM: 'PASSWORD_FORM',
	FORGOT_PASSWORD_FORM: 'FORGOT_PASSWORD_FORM',
	RESET_PASSWORD_FORM: 'RESET_PASSWORD_FORM',
	CAMPAIGN_INFO_FORM: 'CAMPAIGN_INFO_FORM',
	VOUCHER_FORM: 'VOUCHER_FORM',
	GIFT_CARD_STEP_1: 'GIFT_CARD_STEP_1',
	ADD_LOCATION: 'ADD_LOCATION',
	STORE_FORM: 'STORE_FORM',
	GIFT_CARD_VALIDATE_CODE_MODAL: 'GIFT_CARD_VALIDATE_CODE_MODAL',
	REPORTS_FILTER: 'REPORTS_FILTER',
	EDIT_LOCATION: 'EDIT_LOCATION',
	ADMIN_BRANCHES_FILTER: 'ADMIN_BRANCHES_FILTER',
	ADMIN_BRANCHES_FORM: 'ADMIN_BRANCHES_FORM',
	ADMIN_USERS_FILTER: 'ADMIN_USERS_FILTER',
	ADMIN_USERS_FORM: 'ADMIN_USERS_FORM',
	CREATE_CAMPAIGN_FORM: 'CREATE_CAMPAIGN_FORM'
}
