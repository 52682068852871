import { t } from "i18next"

import { RESET_STORE } from "../types/global"
import { getReq, postReq, deleteReq, patchReq } from "../utils/request"
import {
	STORE_LOAD_DONE,
	STORE_LOAD_FAIL,
	STORE_LOAD_START,
	STORES_LOAD_DONE,
	STORES_LOAD_FAIL,
	STORES_LOAD_START,
	STORE_CREATE_START,
	STORE_CREATE_DONE,
	STORE_CREATE_FAIL,
	STORE_DELETE_START,
	STORE_DELETE_DONE,
	STORE_DELETE_FAIL,
	CAMPAIGN_STORES_UNASSIGN_START,
	CAMPAIGN_STORES_UNASSIGN_DONE,
	CAMPAIGN_STORES_UNASSIGN_FAIL,
	CAMPAIGN_STORES_ASSIGN_START,
	CAMPAIGN_STORES_ASSIGN_DONE,
	CAMPAIGN_STORES_ASSIGN_FAIL, STORE_UPDATE_DONE, STORE_UPDATE_START, STORE_UPDATE_FAIL,
} from "../types/store"
import { ENDPOINTS } from "../enums/endpoints"
import { history } from "../utils/history"
import { loadCampaign } from "./campaignActions"
import { failedLoading, hideLoading, showLoading } from "./loadingActions"

export const storesLoadStart = () => {
	return {
		type: STORES_LOAD_START
	}
}

export const storesLoadFail = () => {
	return {
		type: STORES_LOAD_FAIL
	}
}

export const storesLoadDone = (stores) => {
	return {
		type: STORES_LOAD_DONE,
		payload: {
			stores
		}
	}
}

export const storeLoadStart = () => {
	return {
		type: STORE_LOAD_START,
	}
}

export const storeLoadFail = () => {
	return {
		type: STORE_LOAD_FAIL,
	}
}

export const storeLoadDone = (store) => {
	return {
		type: STORE_LOAD_DONE,
		payload: store
	}
}

export const loadStores = () => {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch(storesLoadStart())
			const { data } = await getReq(ENDPOINTS.STORES)
			dispatch(storesLoadDone(data.stores))
			dispatch(hideLoading())
		} catch (e) {
			dispatch(storesLoadFail())
			dispatch(failedLoading())
		}
	}
}

export const loadStore = (storeId) => {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch(storeLoadStart())
			const { data } = await getReq(ENDPOINTS.STORE(storeId))
			dispatch(storeLoadDone(data))
			dispatch(hideLoading())
		} catch (e) {
			dispatch(storeLoadFail())
			dispatch(failedLoading())
		}
	}
}

export const createStore = (store) => {
	return async (dispatch) => {
		dispatch({
			type: STORE_CREATE_START
		})
		try {
			const { data } = await postReq(ENDPOINTS.STORES, null, store)
			dispatch({
				type: STORE_CREATE_DONE
			})
			history.push(t('paths:store.path', { storeId: data.data.id } ))
		} catch (error) {
			dispatch({
				type: STORE_CREATE_FAIL
			})
		}
	}
}

export const updateStore = (store) => {
	return async (dispatch) => {
		dispatch({
			type: STORE_UPDATE_START
		})
		try {
			await patchReq(ENDPOINTS.STORE(store.id), null, store)
			dispatch({
				type: STORE_UPDATE_DONE,
			})
		} catch (error) {
			dispatch({
				type: STORE_UPDATE_FAIL
			})
		}
	}
}

export const createCampaignStore = (campaignId, store) => {
	return async (dispatch) => {
		dispatch({
			type: STORE_CREATE_START
		})
		try {
			await postReq(ENDPOINTS.CAMPAIGN_STORES(campaignId), null, store)
			dispatch({
				type: STORE_CREATE_DONE
			})
			history.push(t('paths:campaign.path', { campaignId, step: 'obchody'  } ))
		} catch (error) {
			dispatch({
				type: STORE_CREATE_FAIL
			})
		}
	}
}

export const assignCampaignStores = (campaignId, storeIDs) => {
	return async (dispatch) => {
		dispatch({
			type: CAMPAIGN_STORES_ASSIGN_START
		})
		try {
			await patchReq(ENDPOINTS.CAMPAIGN_STORES(campaignId), null, { storeIDs })
			dispatch(loadCampaign(campaignId))
			dispatch({
				type: CAMPAIGN_STORES_ASSIGN_DONE
			})
		} catch (error) {
			dispatch({
				type: CAMPAIGN_STORES_ASSIGN_FAIL
			})
		}
	}
}

export const unassignCampaignStore = (campaignId, storeId) => {
	return async (dispatch) => {
		dispatch({
			type: CAMPAIGN_STORES_UNASSIGN_START
		})
		try {
			await deleteReq(ENDPOINTS.CAMPAIGN_STORE(campaignId,storeId))
			dispatch({
				type: CAMPAIGN_STORES_UNASSIGN_DONE
			})
			dispatch(loadCampaign(campaignId))
		} catch (error) {
			dispatch({
				type: CAMPAIGN_STORES_UNASSIGN_FAIL
			})
		}
	}
}
export const deleteStore = (storeId) => {
	return async (dispatch) => {
		dispatch(showLoading())
		dispatch({
			type: STORE_DELETE_START
		})
		try {
			await deleteReq(ENDPOINTS.STORE(storeId))
			dispatch(loadStores())
			dispatch({
				type: STORE_DELETE_DONE
			})
			dispatch(hideLoading())
		} catch (error) {
			dispatch({
				type: STORE_DELETE_FAIL
			})
			dispatch(hideLoading())
		}
	}
}

export function resetStore() {
	return {
		type: RESET_STORE
	}
}

