import React from 'react'
import moment from 'moment'
import { Form } from 'antd'
import { isArray, map, get } from 'lodash'

const wrapper = (Component, { input, meta, children, label, colon, extra, help, labelCol, required, wrapperCol, ...rest }) => {

	const hasError = get(meta, 'touched') && get(meta, 'invalid')
	const helpProp = help || (hasError && get(meta, 'error'))
	
	return (
		<Form.Item
			label={label}
			colon={colon}
			extra={extra}
			help={helpProp}
			labelCol={labelCol}
			required={required}
			wrapperCol={wrapperCol}
			validateStatus={hasError ? "error" : "success"}
		>
			<Component {...input} {...rest} >{children}</Component>
		</Form.Item>
	)
}

export const makeField = Component => (props) => wrapper(Component, props)

export const makeSelectField = Component => (props) => {
	const input = {
		...props.input,
		onBlur: () => {},
		onChange: (val) => {
			const value = (val === undefined) ? [] : val
			props.input.onChange(value)
		}
	}
	return wrapper(Component, { ...props, ...input })
}

export const makeCheckboxField = Component => (props) => {
	const input = {
		...props.input,
		value: props.input.value === '' ? [] : props.input.value,
		onBlur: () => {},
		onChange: (val) => {
			const value = (val === undefined || val === '') ? [] : val
			props.input.onChange(value)
		}
	}
	return wrapper(Component, { ...props, ...input })
}

export const makeFieldDatePicker = (Component, settings) => (props) => {

	let value
	if (props.input.value) {
		value = moment(props.input.value)
	}
	const input = {
		...props.input,
		value,
		...settings,
		onBlur: () => {}
	}
	return wrapper(Component, { ...props, input })
}

export const makeFieldDateRange = (Component, settings) => (props) => {
	
	let value = []
	if (isArray(props.input.value)) {
		value = map(props.input.value, (val) => moment(val))
	} else if (props.input.value) {
		value = [moment(props.input.value)]
	}
	
	const input = {
		...props.input,
		value,
		...settings,
		onBlur: () => {}
	}
	return wrapper(Component, { ...props, input })
}
