import React, { Component, Suspense, lazy } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import axios from 'axios'
import { get, map } from 'lodash'
import { notification } from 'antd'
import { compose, bindActionCreators } from "redux"
import { withNamespaces } from 'react-i18next'
import { connect } from "react-redux"
import PropTypes from "prop-types"

import Route from './Route'
import MainLayout from '../layouts/MainLayout'
import SimpleLayout from "../layouts/SimpleLayout"
import * as AuthActions from '../../actions/authActions'
import { MSG_TYPE, MSG_TYPE_TRANSLATION } from '../../enums/general'

/**
 * PAGES
 */
import LoginPage from "../authorization/LoginPage"
import RegistrationPage from "../authorization/RegistrationPage"
import Page404 from "../Page404"
import UserPage from "../user/UserPage"
import CampaignsPage from "../campaigns/CampaignsPage"
import CreateCampaignPage from "../campaigns/CreateCampaignPage"
import CampaignPage from "../campaigns/CampaignPage"
import AddCampaignLocationPage from "../campaigns/AddLocationPage"
import EditCampaignLocationPage from "../campaigns/EditLocationPage"
import AdminPage from '../admin/AdminPage'
const GiftCardsPage = lazy(() => import('../giftCards/GiftCardsPage'))
const GiftCardPage = lazy(() => import( '../giftCards/GiftCardPage'))
const GiftCardsValidatePage = lazy(() => import('../giftCards/GiftCardsValidatePage'))
const StoresPage = lazy(() => import( "../stores/StoresPage"))
const CreateStorePage = lazy(() => import( "../stores/CreateStorePage"))
const EditStorePage = lazy(() => import( "../stores/EditStorePage"))
const ReportPage = lazy(() => import( '../reports/ReportPage'))
const CreateCampaignStorePage = lazy(() => import( "../campaigns/CreateStorePage"))
const RegistrationConfirmPage = lazy(() => import( "../authorization/RegistrationConfirmPage"))
const ForgotPasswordPage = lazy(() => import( '../authorization/ForgotPasswordPage'))
const ResetPasswordPage = lazy(() => import( '../authorization/ResetPasswordPage'))
const LocationsPage = lazy(() => import('../locations/LocationsPage'))
const CreateLocationPage = lazy(() => import('../locations/CreateLocationPage'))
const EditLocationPage = lazy(() => import('../locations/EditLocationPage'))

const AuthorizedRoute = lazy(() => import('./AuthorizedRoute'))

const msgSwitcher = (msgType) => {
	switch (msgType) {
		case MSG_TYPE.ERROR:
			return notification.error
		case MSG_TYPE.WARNING:
			return notification.warning
		case MSG_TYPE.SUCCESS:
			return notification.success
		case MSG_TYPE.INFO:
			return notification.info
		default:
			return notification.error
	}
}

const blobToJSON = (data) => new Promise((resolve) => {
	const fr = new FileReader()
	if (data.type === 'application/json') {
		fr.onload = () => resolve(JSON.parse(fr.result))
	} else {
		fr.onload = () => resolve(fr.result)
	}
	fr.readAsText(data)
})

// axios global response error handler
const reponseErrorHandler = (authActions) => async error => {
	let messages = []
	if (get(error, 'response.status') >= 500) {
		messages.push({
			text: 'Ups, niečo sa pokazilo',
			type: MSG_TYPE.ERROR
		})
	} else if (get(error, 'response.status') === 401) {
		authActions.logoutUser()
	}
	// if responseType is set to blob then every response is transform on blob so it have to be convert back to json
	if (get(error, 'response.data') instanceof Blob) {
		
		const res = await blobToJSON(get(error, 'response.data'))
		messages = [ ...messages,  ...get(res, 'messages', [])]
	} else {
		messages = [ ...messages, ...get(error, 'response.data.messages', [])]
	}

	map(messages, (message) => {
		const notificationTypes = msgSwitcher(get(message, 'type'))
		notificationTypes({
			message: MSG_TYPE_TRANSLATION[get(message, 'type')],
			description: get(message, 'text', '')
		})
	})
	return Promise.reject(error)
}

// axios global response success handler
const reponseSuccessHandler = async response => {
	if (get(response, 'status') === 200) {
		let messages
		// if responseType is set to blob then every response is transform on blob so it have to be convert back to json
		if (get(response, 'data') instanceof Blob) {
			
			const res = await blobToJSON(get(response, 'data'))
			messages = get(res, 'messages', [])
		} else {
			messages = get(response, 'data.messages')
		}
		map(messages, (message) => {
			const notificationTypes = msgSwitcher(get(message, 'type'))
			notificationTypes({
				message: MSG_TYPE_TRANSLATION[get(message, 'type')],
				description: get(message, 'text', '')
			})
		})

	}
	return Promise.resolve(response)
}

class Routes extends Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		authActions: PropTypes.shape().isRequired
	}

	constructor(props) {
		super(props)
		this.state = {}
		axios.interceptors.response.use(reponseSuccessHandler, reponseErrorHandler(props.authActions))
	}

	render = () => {
		const { t } = this.props
		return (
			<Suspense fallback={<div className={"flex-container"}><div className="loader">Loading...</div></div>}>
				<Switch>
					<AuthorizedRoute
						exact
						path={t('paths:base.path')}
						component={GiftCardsPage}
						layout={MainLayout}
						menuItem="giftCards"
					/>
					<Route
						exact
						path={t('paths:login.path')}
						component={LoginPage}
						layout={SimpleLayout}
					/>
					<Route
						exact
						path={t('paths:register.path')}
						component={RegistrationPage}
						layout={SimpleLayout}
					/>
					<Route
						exact
						path={t('paths:forgot-password.path')}
						component={ForgotPasswordPage}
						layout={SimpleLayout}
					/>
					<Route
						exact
						path={t('paths:reset-password.path')}
						component={ResetPasswordPage}
						layout={SimpleLayout}
					/>
					<Route
						exact
						path={t('paths:activation-account.path')}
						component={RegistrationConfirmPage}
						layout={SimpleLayout}
					/>
					<AuthorizedRoute
						exact
						path={t('paths:profile.path')}
						component={UserPage}
						layout={MainLayout}
					/>
					<AuthorizedRoute
						exact
						path={t('paths:campaigns.path')}
						component={CampaignsPage}
						layout={MainLayout}
						menuItem="campaigns"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:gift-cards.path')}
						component={GiftCardsPage}
						layout={MainLayout}
						menuItem="giftCards"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:create-giftCard.path')}
						component={GiftCardPage}
						layout={MainLayout}
						menuItem="giftCards"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:edit-giftCard.path', { giftCardId: ':giftCardId' })}
						component={GiftCardPage}
						layout={MainLayout}
						menuItem="giftCards"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:validate-codes-giftCard.path')}
						component={GiftCardsValidatePage}
						layout={MainLayout}
						menuItem="giftCards"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:create-campaign.path')}
						component={CreateCampaignPage}
						layout={MainLayout}
						menuItem="campaigns"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:campaign.path', { campaignId: ':campaignId', step: ':step' })}
						component={CampaignPage}
						layout={MainLayout}
						menuItem="campaigns"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:campaign-add-location.path', { campaignId: ':campaignId' })}
						component={AddCampaignLocationPage}
						layout={MainLayout}
						menuItem="campaigns"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:campaign-edit-location.path', {
							campaignId: ':campaignId',
							locationId: ':locationId'
						})}
						component={EditCampaignLocationPage}
						layout={MainLayout}
						menuItem="campaigns"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:campaign-add-store.path', { campaignId: ':campaignId' })}
						component={CreateCampaignStorePage}
						layout={MainLayout}
						menuItem="campaigns"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:create-store.path')}
						component={CreateStorePage}
						layout={MainLayout}
						menuItem="stores"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:stores.path')}
						component={StoresPage}
						layout={MainLayout}
						menuItem="stores"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:reports.path')}
						component={ReportPage}
						layout={MainLayout}
						menuItem="reports"
					/>
					<AuthorizedRoute
						path={t('paths:admin.path')}
						component={AdminPage}
						layout={MainLayout}
						menuItem="admin"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:locations.path')}
						component={LocationsPage}
						layout={MainLayout}
						menuItem="locations"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:create-location.path')}
						component={CreateLocationPage}
						layout={MainLayout}
						menuItem="locations"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:edit-location.path', { locationId: ':locationId' })}
						component={EditLocationPage}
						layout={MainLayout}
						menuItem="locations"
					/>
					<AuthorizedRoute
						exact
						path={t('paths:store', { storeId: ':storeId' })}
						component={EditStorePage}
						layout={MainLayout}
						menuItem="stores"
					/>
				</Switch>
			</Suspense>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	authActions: bindActionCreators(AuthActions, dispatch)
})

export default compose(
	withRouter,
	connect(null, mapDispatchToProps),
	withNamespaces('paths')
)(Routes)
