import React, { Component } from 'react'
import PropTypes from 'prop-types'

import i18n from '../utils/i18n.js'

import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router'
import { Provider } from 'react-redux'

import { ConfigProvider } from 'antd'
import renderEmptyNode from '../atoms/EmptyNode'

import Routes from './routes/Routes'
import 'moment/locale/sk'

class Root extends Component {
	render() {
		const { store, history } = this.props
		return (
			<Provider store={store}>
				<I18nextProvider i18n={i18n}>
					<ConfigProvider renderEmpty={renderEmptyNode}>
						<Router history={history}>
							<Routes/>
						</Router>
					</ConfigProvider>
				</I18nextProvider>
			</Provider>
		)
	}
}

Root.propTypes = {
	store: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
}

export default Root

