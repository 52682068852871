import React from 'react'
import { Select, Input, Spin } from 'antd'
import { reduxForm, Field } from 'redux-form'
import { map, get, debounce, isArray } from 'lodash'
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { makeField } from '../../../../../atoms/utils'

// enums
import { FORMS } from '../../../../../enums/forms'
import { ENDPOINTS } from '../../../../../enums/endpoints'

// utils
import { getReq } from '../../../../../utils/request'

const ASelect = makeField(Select)
const AInput = makeField(Input)

const { Option } = Select

class BranchForm extends React.Component {

	static propTypes = {
		t: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		settings: PropTypes.shape().isRequired,
		submitBtnText: PropTypes.string.isRequired,
		initialize: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		branch: PropTypes.shape().isRequired
	}

	constructor(props) {
		super(props)
		this.fetchUser = debounce(this.fetchUser, 300)
	}

	state = {
		searchUsers: {
			data: [],
			isLoading: false
		}
	}

	_mounted = false

	componentDidMount = () => {
		const { initialize, branch } = this.props
		this._mounted = true
		let values = {
			name: null,
			email: null,
			street: null,
			country: null,
			city: null,
			parentID: [],
			webUsersIDs: []
		}
		if (branch) {
			values = {
				name: get(branch, 'data.name'),
				email: get(branch, 'data.email'),
				street: get(branch, 'data.address.street'),
				city: get(branch, 'data.address.city'),
				country: get(branch, 'data.address.country'),
				parentID: get(branch, 'data.parent.id', []),
				webUsersIDs: []
			}
		}

		initialize(values)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	fetchUser = async (search) => {
		if (!search) {
			return
		}
		try {
			if (this._mounted) {
				this.setState({
					searchUsers: {
						data: [],
						isLoading: true
					}
				})
			}

			const { data } = await getReq(ENDPOINTS.WEB_USERS, { search })
			if (this._mounted) {
				this.setState({
					searchUsers: {
						data: get(data, 'users', []),
						isLoading: false
					}
				})
			}
		} catch (e) {
			if (this._mounted) {
				this.setState({
					searchUsers: {
						data: [],
						isLoading: false
					}
				})
			}
		}
	}

	render = () => {
		const { handleSubmit, t, settings, submitBtnText } = this.props
		const { searchUsers } = this.state

		const networkOptions = map(get(settings, 'networks'), (network) => {
			return <Option key={get(network, 'id')} value={get(network, 'id')}>{get(network, 'name')}</Option>
		})
		return (<form onSubmit={handleSubmit}>
			<div className={'row'}>
				<div className={'col-12'}>
					<div className={'panel clearfix'}>
						<div className={'row'} style={{ marginBottom: '20px' }}>
							<div className={'col-6'}>
								<Field
									required
									label={t('pages:BranchForm.Názov pobočky')}
									name="name"
									component={AInput}
									size={'large'}
									placeholder={t('pages:BranchForm.Názov pobočky')}
								/>
							</div>
							<div className={'col-6'}>
								<Field
									required
									label={t('pages:BranchForm.Email')}
									name="email"
									component={AInput}
									size={'large'}
									placeholder={t('pages:BranchForm.Email')}
								/>
							</div>
							<div className={'col-6'}>
								<Field
									required
									label={t('pages:BranchForm.Ulica')}
									name="street"
									component={AInput}
									size={'large'}
									placeholder={t('pages:BranchForm.Ulica')}
								/>
							</div>
							<div className={'col-6'}>
								<Field
									required
									label={t('pages:BranchForm.Mesto')}
									name="city"
									component={AInput}
									size={'large'}
									placeholder={t('pages:BranchForm.Mesto')}
								/>
							</div>
							<div className={'col-6'}>
								<Field
									required
									label={t('pages:BranchForm.Štát')}
									name="country"
									component={AInput}
									size={'large'}
									placeholder={t('pages:BranchForm.Štát')}
								/>
							</div>
							<div className={'col-6'}>
								<Field
									required
									label={t('pages:BranchForm.Sieť')}
									name="parentID"
									component={ASelect}
									size={'large'}
									placeholder={t('pages:BranchForm.Zvoľte sieť')}
								>
									{networkOptions}
								</Field>
							</div>
							<div className={'col-12'} id={'fixed-scroll'}>
								<Field
									labelInValue
									filterOption={false}
									mode="multiple"
									label={t('pages:BranchForm.Používatelia')}
									notFoundContent={get(searchUsers, 'isLoading') ? <Spin size="small"/> : null}
									name="webUsersIDs"
									onSearch={this.fetchUser}
									component={ASelect}
									getPopupContainer={() => document.getElementById('fixed-scroll')}
									size={'large'}
									placeholder={t('pages:BranchForm.Vyhľadajte používateľov')}
								>
									{map(get(searchUsers, 'data'), (user) => <Option key={get(user, 'id')}>{get(user, 'name')}</Option>)}
								</Field>
							</div>
						</div>
					</div>
				</div>
				<div className={'col-12'} style={{ marginBottom: '30px' }}>
					<button
						className={'btn primary pull-right'}
						type="submit"
					>
						{submitBtnText}
					</button>
				</div>
			</div>
		</form>)
	}
}

const validate = (values, { t }) => {
	const errors = {}

	if (!values.name) {
		errors.name = t('pages:BranchForm.Toto pole je povinné')
	}
	if (!values.email) {
		errors.email = t('pages:BranchForm.Toto pole je povinné')
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) { // regex from redux form example https://redux-form.com/8.1.0/examples/syncvalidation/
		errors.email = t('pages:BranchForm.Nesprávny formát emailovej adresy')
	}

	if (!values.street) {
		errors.street = t('pages:BranchForm.Toto pole je povinné')
	}

	if (!values.city) {
		errors.city = t('pages:BranchForm.Toto pole je povinné')
	}
	if (!values.country) {
		errors.country = t('pages:BranchForm.Toto pole je povinné')
	}
	if (isArray(values.parentID)) {
		errors.parentID = t('pages:BranchForm.Toto pole je povinné')
	}
	return errors
}

const mapStateToProps = (state) => ({
	settings: state.settings.settings
})

export default compose(
	reduxForm({
		form: FORMS.ADMIN_BRANCHES_FORM,
		touchOnChange: true,
		validate
	}),
	connect(mapStateToProps, null)
)(BranchForm)
