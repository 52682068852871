import React from 'react'

const SimpleLayout = ({ children }) => {
	return (
		<div className={"flex-container simple-layout"}>
			{children}
		</div>
	)
}

export default SimpleLayout
