import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { Input } from 'antd'

import { FORMS } from '../../enums/forms'
import Modal from './Modal'
import { makeField } from '../../atoms/utils'

const AInput = makeField(Input)

class CreateCampaignModal extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		submitting: PropTypes.bool.isRequired,
		reset: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired,
	}

	state = {
		shown: false,
	}

	handleCloseModal = (e) => {
		e.preventDefault()
		const { reset, dismissHandler } = this.props
		reset()
		dismissHandler()
	}

	render = () => {
		const { handleSubmit, dismissHandler, shown, submitting, t } = this.props

		return (
			<Modal dismiss={dismissHandler} shown={shown} size="m">
				<form onSubmit={handleSubmit} noValidate>
					<div className={'modal-header'}>
						<h3>{t('components:campaign-name')}</h3>
					</div>
					<div className={'modal-content'}>
						<Field
							label={t('components:campaign-name')}
							placeholder={t('components:CreateCampaignModal.Zadajte názov kampane')}
							name='campaignName'
							type={'text'}
							component={AInput}
						/>
					</div>
					<div className={'modal-footer clearfix'}>
						<button onClick={this.handleCloseModal} className={'btn'} style={{ marginRight: '20px' }}>
							{t('components:Cancel')}
						</button>
						<button disabled={submitting} type="submit" className={'btn primary'}>
							{t('components:Save')}
						</button>
					</div>
				</form>
			</Modal>
		)
	}
}

export default compose(reduxForm({
	form: FORMS.CREATE_CAMPAIGN_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
}), withNamespaces(['components', 'paths']))(CreateCampaignModal)
