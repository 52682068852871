import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { Empty } from 'antd'

class EmptyNode extends Component {
	static propTypes = {
		t: PropTypes.func,
	}

	render() {
		const { t } = this.props
		
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={t('components:No Data')}
			/>
		)
	}
}

const FinalEmptyNode = withNamespaces(['components'])(EmptyNode)

export default () => {
	return (
		<FinalEmptyNode />
	)
}
