import axios from 'axios'
import { getAccessToken, isLoggedIn } from './auth'

export function buildHeaders(items) {
	const headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Access-Control-Allow-Credentials': true,
		'Cache-Control': 'no-cache, no-store',
		'Pragma': 'no-cache',
		'Language': 'SK'
	}

	if (isLoggedIn()) {
		headers.Authorization = `Bearer ${getAccessToken()}`
	}

	return {
		...headers,
		...items || {}
	}
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { string } accept header
 * @param { Object } headers
 * @return Promise response
 * Performs get request to url and returns callback with result
 */
export function getReq(url, params, accept, headers, configParam = {}) {
	const config = {
		headers: buildHeaders(headers),
	}
	delete config['Content-Type']

	if (accept) {
		config.headers.Accept = accept
	}

	if (params) {
		config.params = params
	}

	return axios.get(url, { ...config, ...configParam })
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } data
 * @param { Object } headers
 * @return Promise response
 * Performs post request to url and returns callback with result
 */
export function postReq(url, params, data, headers, configParam = {}) {
	const config = {
		headers: buildHeaders(headers),
	}
	if (params) {
		config.params = params
	}
	return axios.post(url, data || {}, { ...config, ...configParam })
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } data body
 * @param { Object } headers
 * @return Promise response
 * Performs put request to url and returns callback with result
 */
export function putReq(url, params, data, headers) {
	const config = {
		headers: buildHeaders(headers),
	}

	if (params) {
		config.params = params
	}

	return axios.put(url, data || {}, config)
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } data body
 * @param { Object } headers
 * @return Promise response
 * Performs put request to url and returns callback with result
 */
export function patchReq(url, params, data, headers) {
	const config = {
		headers: buildHeaders(headers),
	}

	if (params) {
		config.params = params
	}

	return axios.patch(url, data || {}, config)
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } headers
 * @return Promise response
 * Performs delete request to url and returns callback with result
 */
export function deleteReq(url, params, data, headers) {
	const config = {
		headers: buildHeaders(headers),
		data
	}

	if (params) {
		config.params = params
	}

	return axios.delete(url, config)
}

/**
 * @param { string } url endpoint
 * @param { Object } params object
 * @param { Object } headers
 * @return Promise response
 * Performs delete request to url and returns callback with result
 */
export function downloadReq(url, params, headers) {
	const config = {
		headers: buildHeaders(headers),
		responseType: 'blob'
	}

	if (params) {
		config.params = params
	}

	return axios.get(url, config)
}
