import { UPDATE_SETTINGS } from "../types/settings"
import { RESET_STORE } from "../types/global"

export const initState = {
	settings: {
		data: null,
		networks: [],
		permissions: []
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case UPDATE_SETTINGS:
			return {
				...state,
				settings: {
					data: action.payload.settings,
					networks: action.payload.networks,
					permissions: action.payload.permissions
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}

