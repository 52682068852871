import { isEmpty } from 'lodash'

export default (values, { t }) => {
	const errors = {}

	if (!values.name) {
		errors.name = t('components:validator.validate.Toto pole je povinné')
	}

	if (isEmpty(values.activeDate)) {
		errors.activeDate = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.views) {
		errors.views = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.viewMode) {
		errors.viewMode = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.activationMode) {
		errors.activationMode = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.language) {
		errors.language = t('components:validator.validate.Toto pole je povinné')
	}
	return errors
}
