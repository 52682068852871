import {
	GIFT_CARDS_LOAD_DONE,
	GIFT_CARDS_LOAD_FAIL,
	GIFT_CARDS_LOAD_START,
	GIFT_CARD_LOAD_DONE,
	GIFT_CARD_LOAD_FAIL,
	GIFT_CARD_LOAD_START,
	GIFT_CARD_BRANCHES_MASTER_LOAD_DONE,
	GIFT_CARD_BRANCHES_MASTER_LOAD_FAIL,
	GIFT_CARD_BRANCHES_MASTER_LOAD_START,
	GIFT_CARD_BRANCHES_SLAVE_LOAD_DONE,
	GIFT_CARD_BRANCHES_SLAVE_LOAD_FAIL,
	GIFT_CARD_BRANCHES_SLAVE_LOAD_START,
	GIFT_CARD_VALIDATION_GIFT_CODE_START,
	GIFT_CARD_VALIDATION_GIFT_CODE_DONE,
	GIFT_CARD_VALIDATION_GIFT_CODE_FAIL,
	GIFT_CARD_VALIDATION_GIFT_CODE_REMOVE,
	GIFT_CARD_VALIDATION_GIFT_CODE_REMOVE_ALL,
	GIFT_CARD_USE_GIFT_CODES_START,
	GIFT_CARD_USE_GIFT_CODES_DONE,
	GIFT_CARD_USE_GIFT_CODES_FAIL
} from '../types/giftCards'

import { RESET_STORE } from "../types/global"

export const initState = {
	giftCards: {
		isLoading: false,
		isFailure: false,
		data: []
	},
	giftCard: {
		isFailure: false,
		isLoading: false,
		data: null
	},
	giftCardBranches: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	validationGiftCodes: {
		isLoading: false,
		isFailure: false,
		data: {
			giftCodes: [],
			usedGiftCodesCount: 0,
			usedGiftCodesValue: 0
		}
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case GIFT_CARDS_LOAD_START:
			return {
				...state,
				giftCards: {
					data: [],
					isFailure: false,
					isLoading: true
				}
			}
		case GIFT_CARDS_LOAD_DONE:
			return {
				...state,
				giftCards: {
					data: action.payload.giftCards,
					isFailure: false,
					isLoading: false
				}
			}

		case GIFT_CARDS_LOAD_FAIL:
			return {
				...state,
				giftCards: {
					data: [],
					isFailure: true,
					isLoading: false
				}
			}
		case GIFT_CARD_LOAD_DONE:
			return {
				...state,
				giftCard: {
					data: action.payload.giftCard,
					isLoading: false,
					isFailure: false
				}
			}
		case GIFT_CARD_LOAD_FAIL:
			return {
				...state,
				giftCard: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case GIFT_CARD_LOAD_START:
			return {
				...state,
				giftCard: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case GIFT_CARD_BRANCHES_MASTER_LOAD_DONE:
			return {
				...state,
				giftCardBranches: {
					data: action.payload.giftCardBranches,
					isLoading: false,
					isFailure: false
				}
			}
		case GIFT_CARD_BRANCHES_MASTER_LOAD_FAIL:
			return {
				...state,
				giftCardBranches: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case GIFT_CARD_BRANCHES_MASTER_LOAD_START:
			return {
				...state,
				giftCardBranches: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case GIFT_CARD_BRANCHES_SLAVE_LOAD_DONE:
			return {
				...state,
				giftCardBranches: action.payload.masterWithSlavesDone
			}
		case GIFT_CARD_BRANCHES_SLAVE_LOAD_FAIL:
			return {
				...state,
				giftCardBranches: action.payload.masterWithSlavesFail
			}
		case GIFT_CARD_BRANCHES_SLAVE_LOAD_START:
			return {
				...state,
				giftCardBranches: action.payload.masterWithSlavesStart
			}
		case GIFT_CARD_VALIDATION_GIFT_CODE_START:
			return {
				...state,
				validationGiftCodes: {
					isLoading: true,
					isFailure: false,
					data: {
						...state.validationGiftCodes.data,
						giftCodes: state.validationGiftCodes.data.giftCodes
					}
				}
			}
		case GIFT_CARD_VALIDATION_GIFT_CODE_DONE:
			return {
				...state,
				validationGiftCodes: {
					isLoading: false,
					isFailure: false,
					data: {
						...state.validationGiftCodes.data,
						giftCodes: action.payload.validationGiftCodes
					}
				}
			}
		case GIFT_CARD_VALIDATION_GIFT_CODE_FAIL:
			return {
				...state,
				validationGiftCodes: {
					isLoading: false,
					isFailure: true,
					data: {
						...state.validationGiftCodes.data,
						giftCodes: []
					}
				}
			}
		case GIFT_CARD_VALIDATION_GIFT_CODE_REMOVE:
			return {
				...state,
				validationGiftCodes: {
					isLoading: false,
					isFailure: false,
					data: {
						...state.validationGiftCodes.data,
						giftCodes: action.payload.validationGiftCodes
					}
				}
			}
		case GIFT_CARD_VALIDATION_GIFT_CODE_REMOVE_ALL:
			return {
				...state,
				validationGiftCodes: {
					isLoading: false,
					isFailure: false,
					data: {
						...state.validationGiftCodes.data,
						giftCodes: []
					}
				}
			}
		case GIFT_CARD_USE_GIFT_CODES_START:
			return {
				...state,
				validationGiftCodes: {
					isLoading: true,
					isFailure: false,
					data: {
						...state.validationGiftCodes.data,
						giftCodes: state.validationGiftCodes.data.giftCodes
					}
				}
			}
		case GIFT_CARD_USE_GIFT_CODES_DONE:
			return {
				...state,
				validationGiftCodes: {
					isLoading: false,
					isFailure: false,
					data: {
						giftCodes: action.payload.validationGiftCodes,
						usedGiftCodesCount: action.payload.usedGiftCodesCount,
						usedGiftCodesValue: action.payload.usedGiftCodesValue,
					}
				}
			}
		case GIFT_CARD_USE_GIFT_CODES_FAIL:
			return {
				...state,
				validationGiftCodes: {
					isLoading: false,
					isFailure: true,
					data: {
						...state.validationGiftCodes.data,
						giftCodes: state.validationGiftCodes.data.giftCodes
					}
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
