import {
	LOADING_START,
	LOADING_END,
	LOADING_FAIL
} from '../types/loading'
import {RESET_STORE} from "../types/global"

export const initState = {
	visible: false,
	failure: false
}

export default (state = initState, action) => {
	switch (action.type) {
		case LOADING_START:
			return {
				visible: true,
				failure: false
			}
		case LOADING_FAIL:
			return {
				failure: true,
				visible: false
			}
		case LOADING_END:
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
