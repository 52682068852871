import {
	LOCATIONS_LOAD_START,
	LOCATIONS_LOAD_DONE,
	LOCATIONS_LOAD_FAIL,
	LOCATION_LOAD_START,
	LOCATION_LOAD_DONE,
	LOCATION_LOAD_FAIL,
	LOCATION_CREATE_START,
	LOCATION_CREATE_DONE,
	LOCATION_CREATE_FAIL,
	LOCATION_UPDATE_START,
	LOCATION_UPDATE_DONE,
	LOCATION_UPDATE_FAIL,
	LOCATION_DELETE_START,
	LOCATION_DELETE_DONE,
	LOCATION_DELETE_FAIL,
	CAMPAIGN_LOCATIONS_ASSIGN_START,
	CAMPAIGN_LOCATIONS_ASSIGN_DONE,
	CAMPAIGN_LOCATIONS_ASSIGN_FAIL,
	CAMPAIGN_LOCATIONS_UNASSIGN_START,
	CAMPAIGN_LOCATIONS_UNASSIGN_DONE,
	CAMPAIGN_LOCATIONS_UNASSIGN_FAIL
} from "../types/location"
import { RESET_STORE } from "../types/global"

export const initState = {
	locations: {
		data: [],
		isLoading: false,
		isFailure: false
	},
	location: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case LOCATIONS_LOAD_START:
			return {
				...state,
				locations: {
					data: [],
					isFailure: false,
					isLoading: true
				}
			}
		case LOCATIONS_LOAD_DONE:
			return {
				...state,
				locations: {
					data: action.payload.locations,
					isFailure: false,
					isLoading: false
				}
			}
		case LOCATIONS_LOAD_FAIL:
			return {
				...state,
				locations: {
					data: [],
					isFailure: true,
					isLoading: false
				}
			}
		case LOCATION_LOAD_START:
			return {
				...state,
				location: {
					data: null,
					isFailure: false,
					isLoading: true
				}
			}
		case LOCATION_LOAD_DONE:
			return {
				...state,
				location: {
					data: action.payload.location,
					isFailure: false,
					isLoading: false
				}
			}
		case LOCATION_LOAD_FAIL:
			return {
				...state,
				location: {
					data: null,
					isFailure: true,
					isLoading: false
				}
			}
		case LOCATION_CREATE_START:
		case LOCATION_UPDATE_START:
		case LOCATION_DELETE_START:
		case CAMPAIGN_LOCATIONS_ASSIGN_START:
		case CAMPAIGN_LOCATIONS_UNASSIGN_START:
			return {
				...state,
				location: {
					data: state.location.data,
					isLoading: true,
					isFailure: false
				}
			}

		case LOCATION_CREATE_DONE:
		case LOCATION_UPDATE_DONE:
		case LOCATION_DELETE_DONE:
		case CAMPAIGN_LOCATIONS_ASSIGN_DONE:
		case CAMPAIGN_LOCATIONS_UNASSIGN_DONE:
			return {
				...state,
				location: {
					data: state.location.data,
					isLoading: false,
					isFailure: false
				}
			}

		case LOCATION_CREATE_FAIL:
		case LOCATION_UPDATE_FAIL:
		case LOCATION_DELETE_FAIL:
		case CAMPAIGN_LOCATIONS_ASSIGN_FAIL:
		case CAMPAIGN_LOCATIONS_UNASSIGN_FAIL:
			return {
				...state,
				location: {
					data: state.location.data,
					isLoading: false,
					isFailure: true
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}

