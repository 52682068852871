import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'

class MenuItem extends React.Component {
	static propTypes = {
		item: PropTypes.shape({
			title: PropTypes.string.isRequired,
			link: PropTypes.string.isRequired
		}).isRequired,
		active: PropTypes.bool.isRequired
	}

	render = () => {
		const { item, active } = this.props

		return (
			<li className={cx({ active })}>
				<Link className={'menu-item'} to={item.link}>{item.title}</Link>
			</li>
		)
	}
}

export default MenuItem
