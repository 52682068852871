import {
	ADMIN_BRANCHES_LOAD_FAIL, 
	ADMIN_BRANCHES_LOAD_DONE,
	ADMIN_BRANCHES_LOAD_START,
	ADMIN_BRANCH_LOAD_DONE,
	ADMIN_BRANCH_LOAD_FAIL,
	ADMIN_BRANCH_LOAD_START,
	ADMIN_USERS_LOAD_FAIL,
	ADMIN_USERS_LOAD_START,
	ADMIN_USERS_LOAD_DONE,
	ADMIN_USER_LOAD_START,
	ADMIN_USER_LOAD_FAIL,
	ADMIN_USER_LOAD_DONE,
	ADMIN_BRANCH_USERS_LOAD_START,
	ADMIN_BRANCH_USERS_LOAD_DONE,
	ADMIN_BRANCH_USERS_LOAD_FAIL
} from '../types/admin'

import { RESET_STORE } from "../types/global"

export const initState = {
	branches: {
		isLoading: false,
		isFailure: false,
		data: [],
		pagination: {
			page: 1
		}
	},
	branch: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	branchUsers: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	users: {
		isLoading: false,
		isFailure: false,
		data: [],
		pagination: {
			page: 1
		}
	},
	user: {
		isLoading: false,
		isFailure: false,
		data: null
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case ADMIN_BRANCHES_LOAD_FAIL:
			return {
				...state,
				branches: {
					...state.branches,
					isFailure: true
				}
			}
		case ADMIN_BRANCHES_LOAD_START:
			return {
				...state,
				branches: {
					...state.branches,
					isLoading: true,
					isFailure: false
				}
			}
		case ADMIN_BRANCHES_LOAD_DONE:
			return {
				...state,
				branches: {
					...state.branches,
					isLoading: false,
					isFailure: false,
					data: action.payload.branches,
					pagination: action.payload.pagination
				}
			}
		case ADMIN_BRANCH_LOAD_START: 
			return {
				...state,
				branch: {
					...initState.branch,
					isLoading: true
				}
			}
		case ADMIN_BRANCH_LOAD_FAIL: 
			return {
				...state,
				branch: {
					...initState.branch,
					isFailure: true
				}
			}
		case ADMIN_BRANCH_LOAD_DONE: 
			return {
				...state,
				branch: {
					...initState.branch,
					data: action.payload.branch
				}
			}
		case ADMIN_USERS_LOAD_FAIL:
			return {
				...state,
				users: {
					...state.users,
					isFailure: true
				}
			}
		case ADMIN_USERS_LOAD_START:
			return {
				...state,
				users: {
					...state.users,
					isLoading: true,
					isFailure: false
				}
			}
		case ADMIN_USERS_LOAD_DONE:
			return {
				...state,
				users: {
					...state.users,
					isLoading: false,
					isFailure: false,
					data: action.payload.users,
					pagination: action.payload.pagination
				}
			}
		case ADMIN_USER_LOAD_START: 
			return {
				...state,
				user: {
					...initState.user,
					isLoading: true
				}
			}
		case ADMIN_USER_LOAD_FAIL: 
			return {
				...state,
				user: {
					...initState.user,
					isFailure: true
				}
			}
		case ADMIN_USER_LOAD_DONE:
			return {
				...state,
				user: {
					...initState.user,
					data: action.payload.user
				}
			}
		case ADMIN_BRANCH_USERS_LOAD_START:
			return {
				...state, 
				branchUsers: {
					...state.branchUsers,
					isLoading: true
				}
			}
		case ADMIN_BRANCH_USERS_LOAD_DONE:
			return {
				...state,
				branchUsers: {
					...state.branchUsers,
					data: action.payload.users,
					isFailure: false,
					isLoading: false
				}
			}
		case ADMIN_BRANCH_USERS_LOAD_FAIL:
			return {
				...state,
				branchUsers: {
					...initState.branchUsers,
					isFailure: true
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
