import React, { Suspense } from 'react'
import { compose } from "redux"
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
import { Tabs } from 'antd'
import { Switch, Redirect } from 'react-router-dom'

import Route from '../routes/Route'

// components 
import Breadcrumbs from "../../components/header/Breadcrumbs"
import AdminBranchesPage from './subPages/branches/AdminBranchesPage'
import AdminUsersPage from './subPages/users/AdminUsersPage'
import AdminBranchesCreatePage from './subPages/branches/AdminBranchesCreatePage'
import AdminBranchesEditPage from './subPages/branches/AdminBranchesEditPage'
import AdminUserCreatePage from './subPages/users/AdminUserCreatePage'
import AdminUserEditPage from './subPages/users/AdminUserEditPage'

// enums
import { PATHS, PAGES } from "../../enums/namespaces"

// utils
import { history } from '../../utils/history'
import { withPermissions } from '../../utils/permissionsHoc'
import { PERMISSION } from '../../enums/general'

const ADMIN_TABS = {
	BRANCHES: 'BRANCHES',
	USERS: 'USERS'
}

class AdminPage extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		location: PropTypes.shape().isRequired
	}

	componentDidMount = () => {
	}

	breadcrumbsInit = () => {
		const { t } = this.props
		return {
			items: [],
			title: t('pages:AdminPage.Admin')
		}
	}

	render = () => {
		const { t, location } = this.props

		const branchesRegExp = new RegExp(/pobocky/)
		const usersRegExp = new RegExp(/uzivatelia/)
		const isBranches = branchesRegExp.test(location.pathname)
		const isUsers = usersRegExp.test(location.pathname)

		let defaultActiveKey
		if (isBranches) {
			defaultActiveKey = ADMIN_TABS.BRANCHES
		} else if (isUsers) {
			defaultActiveKey = ADMIN_TABS.USERS
		}
		return (
			<React.Fragment>
				<div className={'container-fluid breadcrumbs'}>
					<Breadcrumbs breadcrumbs={this.breadcrumbsInit()}/>
				</div>
				<Suspense fallback={<div>Loading...</div>}>
					<div className={"container"}>
						<Tabs type="card" activeKey={defaultActiveKey} defaultActiveKey={defaultActiveKey} onTabClick={(tab) => {
							switch (tab) {
								case ADMIN_TABS.BRANCHES:
									history.push(t('paths:admin.branches.path'))
									break
								case ADMIN_TABS.USERS:
									history.push(t('paths:admin.users.path'))
									break
								default:
							}
						}}>
							<Tabs.TabPane tab={t('pages:AdminPage.Uživatelia')} key={ADMIN_TABS.USERS}/>
							<Tabs.TabPane tab={t('pages:AdminPage.Pobočky')} key={ADMIN_TABS.BRANCHES}/>
						</Tabs>
						<Switch>
							<Route
								exact
								path={t('paths:admin.branches.path')}
								component={AdminBranchesPage}
							/>
							<Route
								exact
								path={t('paths:admin.users.path')}
								component={AdminUsersPage}
							/>
							<Route
								exact
								path={t('paths:admin.branches.create.path')}
								component={AdminBranchesCreatePage}
							/>
							<Route
								exact
								path={t('paths:admin.branches.edit.path', { branchId: ':branchId' })}
								component={AdminBranchesEditPage}
							/>
							<Route
								exact
								path={t('paths:admin.users.create.path')}
								component={AdminUserCreatePage}
							/>
							<Route
								exact
								path={t('paths:admin.users.edit.path', { userId: ':userId' })}
								component={AdminUserEditPage}
							/>
							{/* default route */}
							<Redirect from={t('paths:admin.path')} to={t('paths:admin.branches.path')}/>
						</Switch>
					</div>
				</Suspense>
			</React.Fragment>
		)
	}
}

export default compose(
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces([PAGES, PATHS]),
	// connect(mapStateToProps, mapDispatchToProps)
)(AdminPage)
