import { t } from "i18next"
import { get, map } from 'lodash'

import {
	CAMPAIGN_LOAD_DONE,
	CAMPAIGN_LOAD_FAIL,
	CAMPAIGN_LOAD_START,
	CAMPAIGNS_LOAD_DONE,
	CAMPAIGNS_LOAD_FAIL,
	CAMPAIGNS_LOAD_START,
	CONFIRM_CAMPAIGN_START,
	CONFIRM_CAMPAIGN_FAIL,
	CONFIRM_CAMPAIGN_DONE,
	SAVE_CAMPAIGN_DONE,
	SAVE_CAMPAIGN_FAIL,
	SAVE_CAMPAIGN_START,
	CAMPAIGN_CREATE_DONE,
	CAMPAIGN_CREATE_FAIL,
	CAMPAIGN_CREATE_START,
	REMOVE_CAMPAIGN_DONE,
	REMOVE_CAMPAIGN_FAIL,
	REMOVE_CAMPAIGN_START, SAVE_VOUCHER_START, SAVE_VOUCHER_DONE, SAVE_VOUCHER_FAIL,
} from '../types/campaign'
import { getReq, patchReq, postReq, deleteReq } from "../utils/request"
import { ENDPOINTS } from "../enums/endpoints"
import { MSG_TYPE } from "../enums/general"
import { hideLoading, showLoading, failedLoading } from "./loadingActions"
import { history } from "../utils/history"
import { statusPushCreator } from "./statusActions"

export const campaignsLoadStart = () => {
	return {
		type: CAMPAIGNS_LOAD_START
	}
}

export const campaignsLoadFail = () => {
	return {
		type: CAMPAIGNS_LOAD_FAIL
	}
}

export const campaignsLoadDone = (campaigns) => {
	return {
		type: CAMPAIGNS_LOAD_DONE,
		payload: {
			campaigns
		}
	}
}

export const campaignLoadStart = () => {
	return {
		type: CAMPAIGN_LOAD_START,
	}
}

export const campaignLoadFail = () => {
	return {
		type: CAMPAIGN_LOAD_FAIL,
	}
}

export const campaignLoadDone = (campaign) => {
	return {
		type: CAMPAIGN_LOAD_DONE,
		payload: {
			campaign
		}
	}
}

export const loadCampaigns = () => {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch(campaignsLoadStart())
			const { data } = await getReq(ENDPOINTS.CAMPAIGNS)
			dispatch(campaignsLoadDone(data))
			dispatch(hideLoading())
		} catch (e) {
			dispatch(campaignsLoadFail())
			dispatch(hideLoading())
		}
	}
}

export const loadCampaign = (campaignId) => {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch(campaignLoadStart())
			const { data } = await getReq(ENDPOINTS.CAMPAIGN(campaignId))

			const newData = {
				...data,
				stores: map(get(data, 'stores'), (store) => ({ key: get(store, 'id'), ...store }))
			}
			dispatch(campaignLoadDone(newData))
			dispatch(hideLoading())
		} catch (e) {
			dispatch(campaignLoadFail())
			dispatch(failedLoading())
		}
	}
}

export const createCampaign = (data) => {
	return async (dispatch) => {
		dispatch(showLoading())
		dispatch({
			type: CAMPAIGN_CREATE_START
		})
		try {
			const response = await postReq(ENDPOINTS.CAMPAIGNS, null, data)
			console.log(response)
			if (response.data.data.id) {
				history.push(t('paths:campaign.path', { campaignId: response.data.data.id, step: 'kampan' } ))
			}
			dispatch(hideLoading())
			dispatch({
				type: CAMPAIGN_CREATE_DONE
			})
			dispatch(statusPushCreator({
				type: MSG_TYPE.INFO,
				msg: 'Created'
			}))
		} catch (error) {
			dispatch(hideLoading())
			dispatch({
				type: CAMPAIGN_CREATE_FAIL
			})
		}
	}
}

export const removeCampaign = (campaignId) => {
	return async (dispatch) => {
		dispatch({
			type: REMOVE_CAMPAIGN_START
		})
		try {
			await deleteReq(ENDPOINTS.CAMPAIGN(campaignId), null)
			await dispatch(loadCampaigns())
			await dispatch({
				type: REMOVE_CAMPAIGN_DONE
			})
			history.push(t('paths:campaigns.path'))
		} catch (error) {
			dispatch({
				type: REMOVE_CAMPAIGN_FAIL
			})
		}
	}
}

export const confirmCampaign = (campaignId) => {
	return async (dispatch) => {
		dispatch({
			type: CONFIRM_CAMPAIGN_START
		})
		dispatch(showLoading())
		try {
			await postReq(ENDPOINTS.CONFIRM_CAMPAIGN(campaignId))
			history.push(t('paths:campaigns.path'))
			dispatch({
				type: CONFIRM_CAMPAIGN_DONE
			})
			dispatch(hideLoading())
		} catch (error) {
			dispatch({
				type: CONFIRM_CAMPAIGN_FAIL
			})
			dispatch(hideLoading())
		}
	}
}

export const submitCampaignInfo = (campaignId, data) => {
	return async (dispatch) => {
		dispatch({
			type: SAVE_CAMPAIGN_START
		})
		dispatch(showLoading())
		try {
			await patchReq(ENDPOINTS.CAMPAIGN(campaignId), null, data)
			dispatch({
				type: SAVE_CAMPAIGN_DONE
			})
			dispatch(loadCampaign(campaignId))
			dispatch(hideLoading())
		} catch (error) {
			dispatch({
				type: SAVE_CAMPAIGN_FAIL
			})
			dispatch(hideLoading())
		}
	}
}


export const submitVoucher = (campaignId, data) => {
	return async (dispatch) => {
		dispatch({
			type: SAVE_VOUCHER_START
		})
		dispatch(showLoading())
		try {
			await patchReq(ENDPOINTS.VOUCHER(campaignId), null, data)
			dispatch({
				type: SAVE_VOUCHER_DONE
			})
			dispatch(loadCampaign(campaignId))
			dispatch(hideLoading())
		} catch (error) {
			dispatch({
				type: SAVE_VOUCHER_FAIL
			})
			dispatch(hideLoading())
		}
	}
}


