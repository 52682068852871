import React from 'react'
import PropTypes from "prop-types"
import { map, isEmpty, get, deburr } from 'lodash'
import { withNamespaces } from "react-i18next"
import { Link } from 'react-router-dom'
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"
import { Icon, notification, Modal, Form, Button, Select } from 'antd'
import Location from '../../atoms/Location'
import { PATHS, PAGES, COMPONENTS } from "../../enums/namespaces"
import { MSG_TYPE_TRANSLATION } from '../../enums/general'
import { loadUserLocations as loadLocations, assignCampaignLocations } from '../../actions/locationsActions'
class Locations extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		locations: PropTypes.array.isRequired,
		userLocations: PropTypes.array.isRequired,
		campaignId: PropTypes.number.isRequired,
		prevPage: PropTypes.func.isRequired,
		nextPage: PropTypes.func.isRequired,
		loadLocations: PropTypes.func.isRequired,
		assignCampaignLocations: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			modalVisible: false,
			isSelectedAll: false,
			selectLocations: {
				selectedItems: [],
				error: null
			}
		}
	}

	componentDidMount = () => {
		const { loadLocations } = this.props

		loadLocations()
	}

	handleNextClick = () => {
		const { locations, nextPage, t } = this.props

		if (!isEmpty(locations)) {
			nextPage()
		} else {
			notification.error({
				message: MSG_TYPE_TRANSLATION.ERROR,
				description: t('components:locationError.text')
			})
		}
	}

	handleShowModal = () => {
		this.setState({
			modalVisible: true,
			selectLocations: {
				selectedItems: [],
				error: null
			}
		})
	}

	handleCancelModal = () => {
		this.setState({
			modalVisible: false
		})
	}

	handleSelectAllLocations = () => {
		const { userLocations } = this.props
		const { isSelectedAll } = this.state

		// if none location is selected, select all ids from locations
		const selectedLocations = !isSelectedAll ? map(userLocations, (location) => {
			return location.id
		}) : []

		this.setState({
			isSelectedAll: !isSelectedAll,
			selectLocations: {
				selectedItems: selectedLocations,
				error: null
			}
		})
	}

	handleSelectLocationChange = (values) => {
		const { userLocations } = this.props

		const isSelectedAll = userLocations.length === values.length

		this.setState({
			isSelectedAll,
			selectLocations: {
				selectedItems: values,
				error: null,
			}
		})
	}

	handleAssignLocation = async () => {
		const { t, campaignId, assignCampaignLocations } = this.props
		const { selectLocations } = this.state

		const selectLocationsError = (get(selectLocations, 'selectedItems.length') < 1) && t('components:locations.Výber polohy je povinný')
		if (selectLocationsError) {
			this.setState({
				...this.state,
				selectLocations: {
					selectedItems: get(selectLocations, 'selectedItems'),
					error: selectLocationsError
				}
			})

			return
		}

		this.setState({
			confirmLoading: true
		})

		await assignCampaignLocations(campaignId, get(selectLocations, 'selectedItems'))

		this.setState({
			modalVisible: false,
			confirmLoading: false
		})
	}

	render() {
		const { t, locations, userLocations, campaignId, prevPage } = this.props
		const { modalVisible, selectLocations, isSelectedAll, confirmLoading } = this.state

		const items = map(locations, (item, index) => <Location
			key={index}
			locationId={item.id}
			campaignId={campaignId}
			name={item.name}
			radius={item.radius}
			long={item.longitude}
			zoom={item.zoom}
			lat={item.latitude}
		/>)

		const locationOptions = modalVisible && map(userLocations, (location) =>
			<Select.Option
				key={get(location, 'id')}
				value={get(location, 'id')}
			>
				{get(location, 'name')}
			</Select.Option>
		)

		return (
			<React.Fragment>
				<div className={'row'}>
					<div className={'col-12'}>
						<h2>{t('components:locations.title')}</h2>
						<p>{t('components:locations.text')}</p>
					</div>
					<div className={'col-12'}>
						<Link to={t('paths:campaign-add-location.path', { campaignId })} className={'pull-right'}>
							<button className={'btn primary-yellow'}>{t('paths:campaign-add-location.title', { campaignId })}</button>
						</Link>
						<button
							className={'btn pull-right'}
							style={{ marginRight: '20px' }}
							onClick={this.handleShowModal}
						>
							{t('components:campaign-my-locations')}
						</button>
					</div>
				</div>
				<div className={'row'}>
					{items}
				</div>
				<div className={'row'}>
					<div className={'col-md-12 steps-action'}>
						<button className={'btn'} style={{ float: 'left' }} onClick={prevPage}><Icon type="left" />&nbsp;{t('components:Previous step')}</button>
						<button className={'btn primary-yellow'} style={{ float: 'right' }} onClick={this.handleNextClick}>{t('components:Next step')}&nbsp;<Icon type="right" /></button>
					</div>
				</div>
				<Modal
					title={t('components:campaign-my-locations')}
					visible={modalVisible}
					confirmLoading={confirmLoading}
					onCancel={this.handleCancelModal}
					footer={[
						<Button key="selectAll" onClick={this.handleSelectAllLocations} style={{ float: 'left' }}>
							{
								isSelectedAll ? t('components:Deselect All') : t('components:Select All')
							}
						</Button>,
						<Button key="back" onClick={this.handleCancelModal} style={{ background: 'red', color: 'white' }}>
							{t('pages:Cancel')}
						</Button>,
						<Button key="submit" type="primary" onClick={this.handleAssignLocation}>
							{t('components:campaign-add-locations.Add locations')}
						</Button>,
					]}
				>
					<Form.Item
						validateStatus={get(selectLocations, 'error') && 'error'}
						help={get(selectLocations, 'error')}
					>
						<Select
							style={{ width: '100%' }}
							size={'large'}
							placeholder={t('pages:Click to select')}
							onChange={this.handleSelectLocationChange}
							mode={'multiple'}
							value={get(selectLocations, 'selectedItems')}
							allowClear
							filterOption={(input, option) =>
								deburr(option.props.children.toLowerCase()).indexOf(deburr(input.toLowerCase())) >= 0
							}
						>
							{locationOptions}
						</Select>
					</Form.Item>
				</Modal>
			</React.Fragment>

		)
	}
}

const mapStateToProps = (state) => ({
	userLocations: get(state, 'locations.locations.data'),
})

const mapDispatchToProps = (dispatch) => ({
	loadLocations: bindActionCreators(loadLocations, dispatch),
	assignCampaignLocations: bindActionCreators(assignCampaignLocations, dispatch)
})

export default compose(
	withNamespaces([PAGES, PATHS, COMPONENTS]),
	connect(mapStateToProps, mapDispatchToProps)
)(Locations)
