import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { map, get } from 'lodash'
import { Link } from 'react-router-dom'

import Permissions from '../../utils/permissionsHoc'

class Breadcrumbs extends React.Component {
	static propTypes = {
		breadcrumbs: PropTypes.shape({
			items: PropTypes.array.isRequired,
			title: PropTypes.string.isRequired,
			actions: PropTypes.arrayOf(PropTypes.shape({
				path: PropTypes.string,
				callback: PropTypes.func,
				title: PropTypes.string.isRequired
			}))
		})
	}

	render() {
		const { breadcrumbs } = this.props
		const links = map(breadcrumbs.items, (item, index) => {
			if (item.link) {
				return (<li key={index}><Link to={item.link}>{item.name}</Link></li>)
			}
			return (<li key={index}>{item.name}</li>)
		})
		const actions = map(breadcrumbs.actions, (action, index) => {
			let content = null
			if (action.path) {
				content = (<Link key={index} to={action.path} className={'pull-right'} style={{ marginLeft: '15px' }}>
					<Button className={'primary-btn'} size="large" style={action.style}>{action.title}</Button>
				</Link>)
			} else if (action.callback) {
				content = <Button key={index} className={'primary-btn pull-right'} size="large" style={action.style} onClick={action.callback}>{action.title}</Button>
			} else {
				content = <Button key={index} className={'primary-btn pull-right'} style={action.style} size="large">{action.title}</Button>
			}
			return <Permissions key={index} allowed={action.allowedPermissions} >{content}</Permissions>
		})

		return (
			<div className={'container'}>
				<div className={'row'}>
					<div className={'col-sm-6'}>
						<h3>{breadcrumbs.title}</h3>
						<ul>
							{links}
						</ul>
					</div>
					<div className={'col-sm-6'} style={{ marginTop: '5px' }}>
						{actions}
					</div>
				</div>
			</div>
		)
	}
}

export default Breadcrumbs
