import { map, get, identity, pickBy } from 'lodash'
import { getFormInitialValues, initialize } from 'redux-form'
import { t } from 'i18next'

import {
	ADMIN_BRANCHES_LOAD_START,
	ADMIN_BRANCHES_LOAD_DONE,
	ADMIN_BRANCHES_LOAD_FAIL,
	ADMIN_BRANCH_LOAD_START,
	ADMIN_BRANCH_LOAD_DONE,
	ADMIN_BRANCH_LOAD_FAIL,
	ADMIN_USERS_LOAD_DONE,
	ADMIN_USERS_LOAD_FAIL,
	ADMIN_USERS_LOAD_START,
	ADMIN_USER_LOAD_DONE,
	ADMIN_USER_LOAD_FAIL,
	ADMIN_USER_LOAD_START,
	ADMIN_BRANCH_USERS_LOAD_START,
	ADMIN_BRANCH_USERS_LOAD_DONE,
	ADMIN_BRANCH_USERS_LOAD_FAIL
} from '../types/admin'
import { getReq } from "../utils/request"
import { ENDPOINTS } from "../enums/endpoints"
import { FORMS } from '../enums/forms'
import { PERMISSION } from '../enums/general'
import { showLoading, hideLoading, failedLoading } from "./loadingActions"

export const changeBranchFilters = (values, page) => {
	return async (dispatch, getState) => {
		dispatch(initialize(FORMS.ADMIN_BRANCHES_FILTER, values))
		loadBranches(page)(dispatch, getState)
	}
}

export const loadBranch = (branchId) => {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch({
				type: ADMIN_BRANCH_LOAD_START
			})

			const { data } = await getReq(ENDPOINTS.ADMIN_BRANCH(branchId))
			dispatch({
				type: ADMIN_BRANCH_LOAD_DONE,
				payload: { branch: data }
			})
			dispatch(hideLoading())
		} catch (e) {
			dispatch({
				type: ADMIN_BRANCH_LOAD_FAIL
			})
			dispatch(failedLoading())
		}
		
	}
}

export const loadBranches = (page = 1) => {
	return async (dispatch, getState) => {
		dispatch(showLoading())
		try {
			const state = getState()
			// get init values cause on every submit form set current form values as init values
			const filterValues = getFormInitialValues(FORMS.ADMIN_BRANCHES_FILTER)(state)

			dispatch({
				type: ADMIN_BRANCHES_LOAD_START
			})

			// remove unwanted values like empty string null or undefined from object
			const normalizeFilters = pickBy(filterValues, identity)

			const { data } = await getReq(ENDPOINTS.ADMIN_BRANCHES, { page, limit: 20, ...normalizeFilters })

			const { pagination, branches } = data

			const normalizeTableData = map(branches, (branch) => {
				return {
					key: get(branch, 'id'),
					branchName: get(branch, 'name'),
					branchAddress: `${get(branch, 'address.street')} ${get(branch, 'address.city')} ${get(branch, 'address.country')}`,
					branchContact: get(branch, 'email'),
					masterBranchName: get(branch, 'parent.name')
				}
			})

			dispatch({
				type: ADMIN_BRANCHES_LOAD_DONE,
				payload: {
					branches: normalizeTableData,
					pagination
				}
			})
			dispatch(hideLoading())
		} catch (e) {
			dispatch({
				type: ADMIN_BRANCHES_LOAD_FAIL
			})
			dispatch(failedLoading())
		}
	}
}

export const loadBranchUsers = (branchId, page = 1) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: ADMIN_BRANCH_USERS_LOAD_START
			})

			const { data } = await getReq(ENDPOINTS.ADMIN_BRANCH_USERS(branchId), { page, limit: 20 })
			const users = map(get(data, 'users'), (user) => ({ key: user.id, ...user }))

			dispatch({
				type: ADMIN_BRANCH_USERS_LOAD_DONE,
				payload: {
					users: {
						data: users,
						pagination: get(data, 'pagination')
					}
				}
			})
		} catch (e) {
			dispatch({
				type: ADMIN_BRANCH_USERS_LOAD_FAIL
			})
		}
	}
}

export const changeUserFilters = (values, page) => {
	return async (dispatch, getState) => {
		dispatch(initialize(FORMS.ADMIN_USERS_FILTER, values))
		loadUsers(page)(dispatch, getState)
	}
}

export const loadUsers = (page = 1) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			// get init values cause on every submit form set current form values as init values
			const filterValues = getFormInitialValues(FORMS.ADMIN_USERS_FILTER)(state)

			dispatch({
				type: ADMIN_USERS_LOAD_START
			})

			// remove unwanted values like empty string null or undefined from object
			const normalizeFilters = pickBy(filterValues, identity)

			const { data } = await getReq(ENDPOINTS.ADMIN_USERS, { page, limit: 20, ...normalizeFilters })

			const { pagination, users } = data

			const normalizeTableData = map(users, (user) => {
				let permission
				if (user.permission) {
					switch (user.permission) {
						case PERMISSION.ADMINISTRATOR:
							permission = t('atoms:ADMINISTRATOR')
							break
						case PERMISSION.MANAGER:
							permission = t('atoms:MANAGER')
							break
						case PERMISSION.CASHIER:
							permission = t('atoms:CASHIER')
							break
					}
				}

				return {
					key: get(user, 'id'),
					userName: `${get(user, 'firstName') || '' } ${get(user, 'lastName') || ''}`,
					email: get(user, 'email'),
					permission,
					masterBranchName: get(user, 'branch')
				}
			})

			dispatch({
				type: ADMIN_USERS_LOAD_DONE,
				payload: {
					users: normalizeTableData,
					pagination
				}
			})
		} catch (e) {
			dispatch({
				type: ADMIN_USERS_LOAD_FAIL
			})
		}
	}
}

export const loadUser = (userId) => {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			dispatch({
				type: ADMIN_USER_LOAD_START
			})

			const { data } = await getReq(ENDPOINTS.ADMIN_USER(userId))
			dispatch({
				type: ADMIN_USER_LOAD_DONE,
				payload: { user: data }
			})
			dispatch(hideLoading())
		} catch (e) {
			dispatch({
				type: ADMIN_USER_LOAD_FAIL
			})
			dispatch(failedLoading())
		}
		
	}
}
