import React from 'react'
import { Select, Input, TreeSelect } from 'antd'
import { reduxForm, Field } from 'redux-form'
import { compose } from "redux"
import { connect } from "react-redux"
import { map, get } from 'lodash'

import { FORMS } from '../../../../../enums/forms'
import { makeField, makeSelectField } from '../../../../../atoms/utils'
import { SEARCH_SEPARATOR } from '../../../../../enums/general'

const ASelect = makeField(Select)
const AInput = makeField(Input)
const ATreeSelect = makeSelectField(TreeSelect)

const { Option } = Select

class UserForm extends React.Component {

	state = {
		networkBranchesOptions: [],
		originalNetworkBranchesOptions: []
	}

	componentDidMount = () => {
		const { initialize, user, settings } = this.props
		this._mounted = true
		let values = {
			firstName: null,
			lastName: null,
			email: null,
			permission: [],
			branches: []
		}
		if (user) {
			values = {
				firstName: get(user, 'data.firstName'),
				lastName: get(user, 'data.lastName'),
				email: get(user, 'data.email'),
				permission: get(user, 'data.permission', []),
				branches: map(get(user, 'data.branches'), (branch) => `${branch.id}${SEARCH_SEPARATOR}${branch.name}`)
			}
		}

		initialize(values)

		const networkBranchesOptions = map(get(settings, 'networks'), (network) => {
			const children = map(get(network, 'branches'), (branch) => {
				return {
					title: get(branch, 'name'),
					value:  `${get(branch, 'id')}${SEARCH_SEPARATOR}${get(branch, 'name')}`, // mixed id and name for searching purpose
					key: get(branch, 'id')
				}
			})
			return {
				key: get(network, 'id'),
				value: `${get(network, 'id')}${SEARCH_SEPARATOR}${get(network, 'name')}`, // mixed id and name for searching purpose
				title: get(network, 'name'),
				children
			}
		})

		this.setState({
			networkBranchesOptions
		})
	}

	render = () => {
		const { handleSubmit, t, settings, submitBtnText, user } = this.props
		const permissionOptions = map(get(settings, 'permissions'), (permission) => {
			return <Option key={get(permission, 'id')} value={get(permission, 'id')}>{get(permission, 'name')}</Option>
		})
		return (<form onSubmit={handleSubmit}>
			<div className={'row'}>
				<div className={'col-12'}>
					<div className={'panel clearfix'}>
						<div className={'row'} style={{ marginBottom: '20px' }}>
							<div className={'col-6'}>
								<Field
									required
									label={t('pages:UserForm.Meno')}
									name="firstName"
									component={AInput}
									size={'large'}
									placeholder={t('pages:UserForm.Meno používateľa')}
								/>
							</div>
							<div className={'col-6'}>
								<Field
									required
									label={t('pages:UserForm.Priezvisko')}
									name="lastName"
									component={AInput}
									size={'large'}
									require
									placeholder={t('pages:UserForm.Priezvisko používateľa')}
								/>
							</div>
							<div className={'col-6'}>
								<Field
									required
									disabled={user}
									label={t('pages:UserForm.Email')}
									name="email"
									component={AInput}
									size={'large'}
									placeholder={t('pages:UserForm.Email')}
								/>
							</div>
							<div className={'col-6'}>
								<Field
									required
									label={t('pages:UserForm.Pozícia')}
									name="permission"
									component={ASelect}
									size={'large'}
									placeholder={t('pages:UserForm.Zvoľte pozíciu')}
								>
									{permissionOptions}
								</Field>
							</div>
							<div className={'col-12'}>
								<Field
									required
									label={t('pages:UserForm.Sieť/Pobočka')}
									name="branches"
									treeData={this.state.networkBranchesOptions}
									multiple
									treeCheckable
									component={ATreeSelect}
									size={'large'}
									placeholder={t('pages:UserForm.Zvoľte sieť / pobočku')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button
				className={'btn primary pull-right'}
				type="submit"
			>
				{submitBtnText}
			</button>
		</form>)
	}
}

const validate = (values, { t }) => {
	const errors = {}

	if (!values.firstName) {
		errors.firstName = t('pages:UserForm.Toto pole je povinné')
	}

	if (!values.lastName) {
		errors.lastName = t('pages:UserForm.Toto pole je povinné')
	}

	if (!values.email) {
		errors.email = t('pages:UserForm.Toto pole je povinné')
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) { // regex from redux form example https://redux-form.com/8.1.0/examples/syncvalidation/
		errors.email = t('pages:UserForm.Nesprávny formát emailovej adresy')
	}

	if (!values.permission) {
		errors.permission = t('pages:UserForm.Toto pole je povinné')
	}

	if (!values.branches) {
		errors.branches = t('pages:UserForm.Toto pole je povinné')
	}
	return errors
}

const mapStateToProps = (state) => ({
	settings: state.settings.settings
})

export default compose(
	reduxForm({
		form: FORMS.ADMIN_USERS_FORM,
		touchOnChange: true,
		validate
	}),
	connect(mapStateToProps, null)
)(UserForm)
