import { REPORTS_LOAD_START, REPORTS_LOAD_DONE, REPORTS_LOAD_FAIL } from '../types/reports'

import { RESET_STORE } from "../types/global"

export const initState = {
	reports: {
		isLoading: false,
		isFailure: false,
		data: null
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case REPORTS_LOAD_START:
			return {
				...state,
				reports: {
					...state.reports,
					isFailure: false,
					isLoading: true
				}
			}
		case REPORTS_LOAD_DONE:
			return {
				...state,
				reports: {
					...state.reports,
					data: action.payload.reports,
					isFailure: false,
					isLoading: false
				}
			}

		case REPORTS_LOAD_FAIL:
			return {
				...state,
				reports: {
					...initState.reports,
					isFailure: true
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
