import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Select, Input } from 'antd'
import PropTypes from "prop-types"
import { map, get } from 'lodash'
import { compose } from 'redux'
import { withNamespaces } from 'react-i18next'

import { FORMS } from '../../../../enums/forms'
import { makeField, makeSelectField } from '../../../../atoms/utils'
import { PATHS, PAGES } from '../../../../enums/namespaces'

const ASelect = makeSelectField(Select)
const AInput = makeField(Input)

const { Option } = Select

class AdminBranchFilterForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		settings: PropTypes.shape()
	}

	render = () => {
		const { t, handleSubmit, settings } = this.props
		const networkOptions = map(get(settings, 'networks'), (network) => {
			// it important to get key/value as a string cause query params
			return <Option key={`${get(network, 'id')}`} value={`${get(network, 'id')}`}>{get(network, 'name')}</Option>
		})
		return (
			<form onSubmit={handleSubmit}>
				<div className={'row'}>
					<div className={'col-12'}>
						<div className={'panel clearfix'}>
							<div className={'row'} style={{ marginBottom: '20px' }}>
								<div className={'col-6'}>
									<Field
										label={t('pages:AdminBranchFilterForm.Hľadať pobočku')}
										name="search"
										component={AInput}
										size={'large'}
										placeholder={t('pages:AdminBranchFilterForm.Názov pobočky')}
									/>
								</div>
								<div className={'col-3'}>
									<Field
										allowClear
										label={t('pages:AdminBranchFilterForm.Sieť')}
										name="parentID"
										component={ASelect}
										size={'large'}
										placeholder={t('pages:AdminBranchFilterForm.Zvoľte sieť')}
									>
										{networkOptions}
									</Field>
								</div>
								<div className={'col-3'}>
									<button
										className={'btn primary'}
										type="submit"
										style={{ marginTop: "39px" }}
									>
										{t('pages:AdminBranchFilterForm.Vyfiltrovať')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.ADMIN_BRANCHES_FILTER,
	touchOnChange: true,
	destroyOnUnmount: false
})(AdminBranchFilterForm)

export default compose(
	withNamespaces([PAGES, PATHS])
)(form)
