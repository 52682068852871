import React, { Component } from 'react'
import failure_screen from '../public/images/failure_screen.svg'
import { Link } from 'react-router-dom'

class Page404 extends Component {
	render() {
		return (
			<div>
				<img src={failure_screen} alt={'failure'}/>
				<h2>Stránka neexistuje</h2>
				<Link to={'/'}>
					<button className={'btn primary-blue'} style={{ marginTop: '20px' }}>Späť na hlavnú stránku</button>
				</Link>
			</div>

		)
	}
}

export default Page404
