import { USER_LOAD_SUCCESS, USER_UPDATE_SUCCESS} from "../types/user"

import { USER_LOGIN_SUCCESS, USER_LOGOUT} from "../types/auth"

import {RESET_STORE} from "../types/global"

export const initState = {
	profile: null
}

export default (state = initState, action) => {
	switch (action.type) {
		case USER_LOGIN_SUCCESS:
		case USER_LOAD_SUCCESS:
			return {
				...state,
				...state,
				profile: action.payload.user
			}
		case USER_UPDATE_SUCCESS:
			return {
				...state,
				profile: {
					...state.profile,
					...action.payload.user
				}
			}
		case USER_LOGOUT:
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}

