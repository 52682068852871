import React from 'react'
import { compose, bindActionCreators } from "redux"
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
import ReactQueryParams from 'react-query-params'
import { connect } from "react-redux"
import { get, uniq, map } from 'lodash'
import { Table, Modal } from 'antd'

// forms
import BranchForm from './form/BranchForm'

// enums
import { PATHS, PAGES } from "../../../../enums/namespaces"
import { ENDPOINTS } from '../../../../enums/endpoints'

import { patchReq, deleteReq } from '../../../../utils/request'

import * as AdminActions from '../../../../actions/adminActions'
import Loading from "../../../../atoms/Loading"
import { withPermissions } from '../../../../utils/permissionsHoc'
import { PERMISSION } from '../../../../enums/general'

class AdminBranchesEditPage extends ReactQueryParams {
	static propTypes = {
		t: PropTypes.func.isRequired,
	}

	state = {
		removeBranchUser: false
	}

	submitForm = async (values) => {
		try {
			const { computedMatch, adminActions } = this.props
			const { params } = computedMatch
			const webUsersIDs = uniq(map(get(values, 'webUsersIDs'), (user) => user.key))
			await patchReq(ENDPOINTS.ADMIN_BRANCH(params.branchId), null, { ...values, webUsersIDs })

			// fetch fresh users data
			adminActions.loadBranchUsers(params.branchId)
		} catch (e) {
			console.log(e)
		}
	}

	componentDidMount = () => {
		const { computedMatch, adminActions } = this.props
		const { params } = computedMatch
		adminActions.loadBranch(params.branchId)
		adminActions.loadBranchUsers(params.branchId)
	}

	onChangeTable = async (pagination) => {
		const { reportsActions, computedMatch } = this.props
		const { params } = computedMatch
		reportsActions.loadBranchUsers(params.branchId , pagination.current)
	}

	deleteBranchUser = async() => {
		try {
			const { removeBranchUser } = this.state
			const { computedMatch, adminActions } = this.props
			const { params } = computedMatch

			await deleteReq(ENDPOINTS.ADMIN_BRANCH_USER(params.branchId, get(removeBranchUser, 'id')))

			// refetch data for table
			adminActions.loadBranchUsers(params.branchId)

			// close modal
			this.setState({ removeBranchUser: false })
		} catch (e) {
			console.log(e)
		}
	}

	render = () => {
		const { t, branch, branchUsers } = this.props
	
		const columns = [{
			title: t('pages:AdminBranchesEditPage.Meno a priezvisko'),
			dataIndex: 'name',
			key: 'name',
		}, {
			title: t('pages:AdminBranchesEditPage.Email'),
			dataIndex: 'email',
			key: 'email',
		}, {
			title: t('pages:AdminBranchesEditPage.Pozícia'),
			dataIndex: 'permissions',
			key: 'permissions',
		}, {
			title: t('pages:AdminBranchesEditPage.Akcia'),
			key: 'action',
			render: (text, record) => (
				<span>
					<a onClick={() => { this.setState({ removeBranchUser: record })}}>{t('pages:AdminBranchesEditPage.Odstrániť')}</a>
				</span>
			),
		}]

		return (
			<div style={{ paddingTop: '30px' }}>
				{get(branch, 'data') && <BranchForm submitBtnText={t('pages:AdminBranchesEditPage.Upraviť')} branch={branch} t={t} onSubmit={this.submitForm} />}
				<Modal
					title={t('pages:AdminBranchesEditPage.Odstránenie uživatela z pobočky')}
					visible={!!this.state.removeBranchUser}
					onOk={this.deleteBranchUser}
					cancelText={t('pages:AdminBranchesEditPage.Zrušiť')}
					onCancel={() => this.setState({ removeBranchUser: false })}
				>
					<p>{t('pages:AdminBranchesEditPage.Ste si istý')}</p>
				</Modal>
				<Table
					dataSource={get(branchUsers, 'data.data')}
					loading={get(branchUsers, 'isLoading')}
					columns={columns}
					onChange={this.onChangeTable}
					pagination={{
						defaultPageSize: 20,
						pageSize: get(branchUsers, 'data.pagination.limit'),
						total: get(branchUsers, 'data.pagination.count'),
						current: get(branchUsers, 'data.pagination.page')
					}}
				/>
				<Loading/>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	adminActions: bindActionCreators(AdminActions, dispatch)
})

const mapStateToProps = (state) => ({
	settings: state.settings.settings,
	branch: state.admin.branch,
	branchUsers: state.admin.branchUsers
})

export default compose(
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces([PAGES, PATHS, 'components']),
	connect(mapStateToProps, mapDispatchToProps)
)(AdminBranchesEditPage)
