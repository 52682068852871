export const ENDPOINTS = {
	LOGS: '/api/v0/logs',
	LOGIN: '/api/web/v1/login',
	REGISTER: '/api/web/v1/register',
	LOGOUT: '/api/web/v1/logout',
	WEB_RESET_PASSWORD: '/api/web/v1/reset-password',
	CAMPAIGNS: '/api/web/v1/campaigns',
	CAMPAIGN: (campaignId) => `/api/web/v1/campaigns/${campaignId}`,
	CONFIRM_CAMPAIGN: (campaignId) => `/api/web/v1/campaigns/${campaignId}/confirm`,
	VOUCHER: (campaignId) => `/api/web/v1/campaigns/${campaignId}/voucher`,
	GIFT_CARDS: '/api/web/v1/gifts',
	GIFT_CARD: (giftCardId) => `/api/web/v1/gifts/${giftCardId}`,
	GIFT_CARD_BRANCHES: (giftCardId) => `/api/web/v1/gifts/${giftCardId}/branches`,
	GIFT_CARD_BRANCH: (giftCardId, branchId) => `/api/web/v1/gifts/${giftCardId}/branches/${branchId}`,
	GIFT_CARD_IMPORT_EMAILS: (giftCardId, branchId) => `/api/web/v1/gifts/${giftCardId}/branches/${branchId}/import`,
	GIFT_CARD_IMPORT_EMAIL: (giftCardId) => `/api/web/v1/gifts/${giftCardId}/users`,
	GIFT_CARD_EXPORT: (giftCardId) => `/api/web/v1/gifts/${giftCardId}/export`,
	GIFT_CARD_CANCEL: (giftCardId) => `/api/web/v1/gifts/${giftCardId}/cancel`,
	GIFT_CODE: (giftCodeId) => `api/web/v1/giftCodes/${giftCodeId}`,
	GIFT_CARD_VALIDATE_CODE: `/api/web/v1/gifts/code/validate`,
	GIFT_CARD_USE_CODES: `/api/web/v1/gifts/code/use`,
	CAMPAIGN_LOCATION: (campaignId, locationId) => `/api/web/v1/campaigns/${campaignId}/locations/${locationId}`,
	CAMPAIGN_LOCATIONS: (campaignId) => `/api/web/v1/campaigns/${campaignId}/locations`,
	CAMPAIGN_ASSIGN_LOCATIONS: (campaignId) => `/api/web/v1/campaigns/${campaignId}/locations/assign`,
	CAMPAIGN_UNASSIGN_LOCATIONS: (campaignId, locationId) => `/api/web/v1/campaigns/${campaignId}/locations/${locationId}/unassign`,
	CAMPAIGN_STORE: (campaignId, storeId) => `/api/web/v1/campaigns/${campaignId}/stores/${storeId}`,
	CAMPAIGN_STORES: (campaignId) => `/api/web/v1/campaigns/${campaignId}/stores`,
	PROFILE: '/api/web/v1/profile',
	SETTINGS: '/api/web/v1/settings',
	STORE: (storeId) => `/api/web/v1/stores/${storeId}`,
	STORES: '/api/web/v1/stores',
	LOCATIONS: '/api/web/v1/locations',
	LOCATION: (locationId) => `/api/web/v1/locations/${locationId}`,
	REGISTER_CONFIRM: '/api/web/v1/register/confirm',
	FORGOT_PASSWORD: '/api/web/v1/forgot-password',
	REPORTS: '/api/web/v1/reports',
	DAILY_REPORT_EXPORT: '/api/web/v1/reports/export-daily-report',
	NETWORKS: '/api/web/v1/networks',
	PERMISSIONS: '/api/web/v1/permissions',
	WEB_USERS: '/api/web/v1/webUsers',
	UPLOAD_IMAGE: '/api/web/v1/media/images',
	BRANCHES_SEARCH: (giftId) => `/api/web/v1/gifts/${giftId}/branches/autocomplete`,
	DISTRIBUTION_NOTIFY: (giftId) => `/api/web/v1/gifts/${giftId}/notify`,
	// ADMIN
	ADMIN_BRANCHES: '/api/admin/v1/branches',
	ADMIN_BRANCH: (branchId) => `/api/admin/v1/branches/${branchId}`,
	ADMIN_BRANCH_USERS: (branchId) => `/api/admin/v1/branches/${branchId}/users`,
	ADMIN_BRANCH_USER: (branchId, userId) => `/api/admin/v1/branches/${branchId}/users/${userId}`,
	ADMIN_USERS: '/api/admin/v1/users',
	ADMIN_USER: (userId) => `/api/admin/v1/users/${userId}`,
	// APP
	REGISTER_CONFIRM_APP: '/api/v1/register/confirm',
	APP_RESET_PASSWORD: '/api/v1/forgot-password/reset',
}
