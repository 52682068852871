import { postReq } from '../utils/request'
import { ENDPOINTS } from '../enums/endpoints'
import { t } from 'i18next'
import { GIFT_CODE_VALIDATION_STATE } from '../enums/general'

/**
 * Upload file function
 * @param {Object/string} fileToUpload
 * @return {string/undefined} access path to file
 */
export function uploadFile(fileToUpload) {
	return new Promise(async (resolve, reject) => {
		try {
			const { name, file } = fileToUpload
			var bodyFormData = new FormData()
			bodyFormData.append('image', file, name)
			const response = await postReq(ENDPOINTS.UPLOAD_IMAGE, null, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
			return resolve(response)
		} catch (err) {
			return reject(err)
		}
	})
}

export const translateValidationState = (state) => {
	switch (state) {
		case GIFT_CODE_VALIDATION_STATE.ACTIVE:
			return t('components:GiftCardValidationTable.Nevydaná')
		case GIFT_CODE_VALIDATION_STATE.NOT_ASSIGNED_TO_BRANCH:
			return t('components:GiftCardValidationTable.Nepriradená pobočke')
		case GIFT_CODE_VALIDATION_STATE.USED:
			return t('components:GiftCardValidationTable.Použitá')
		case GIFT_CODE_VALIDATION_STATE.CANCELED:
			return t('components:GiftCardValidationTable.Zrušená')
		case GIFT_CODE_VALIDATION_STATE.NON_EXISTING:
			return t('components:GiftCardValidationTable.Neexistujúca')
		case GIFT_CODE_VALIDATION_STATE.EXPIRED:
			return t('components:GiftCardValidationTable.Expirovaná')
		case GIFT_CODE_VALIDATION_STATE.NOT_STARTED:
			return t('components:GiftCardValidationTable.Nespustená')
		default:
			return ''
	}
}
