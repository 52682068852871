import React, { Component } from "react"
import PropTypes from "prop-types"
import { Redirect } from 'react-router'
import { connect } from "react-redux"
import { get, some } from 'lodash'

export const checkPermissions = (role = '', allowed = [], except = []) => {
	if (except.length > 0 && some(except, permission => permission === role)) {
		return false
	}
	if (allowed.length > 0) {
		if (some(allowed, permission => permission === role)) {
			return true
		}
		return false
	}
	return true
}

export const withPermissions = (allowed = [], except = []) =>
	(WrappedComponent) => {
		class WithPermissionsClass extends Component {
			static propTypes = {
				permission: PropTypes.string.isRequired
			}
			
			constructor(props) {
				super(props)

				this.state = {
					visible: checkPermissions(props.permission, allowed, except)
				}
			}

			componentDidUpdate() {
				const { permission } = this.props
				const { visible } = this.state
				this.checkValidPermissions(permission, visible)
			}

			checkValidPermissions = (permission, visible = false) => {
				const newVisibility = checkPermissions(permission, allowed, except)
				if (visible !== newVisibility) {
					this.setState({
						visible: newVisibility
					})
				}
			}

			render =() => {
				const { visible } = this.state
				if (visible) {
					return (<WrappedComponent {...this.props} />)
				} else {
					return <Redirect to={'/403'} />
				}

			}
		}

		return connect(state => ({
			permission: get(state, 'user.profile.permission')
		}))(WithPermissionsClass)
	}

class Permissions extends React.Component {
	static propTypes = {
		permission: PropTypes.string.isRequired,
		allowed: PropTypes.arrayOf(PropTypes.string),
		except: PropTypes.arrayOf(PropTypes.string),
		render: PropTypes.func
	}
	
	constructor(props) {
		super(props)
		this.state = {
			hasPermissions: checkPermissions(props.permission, props.allowed, props.except)
		}
	}

	componentDidUpdate() {
		this.checkValidPermissions()
	}

	checkValidPermissions = () => {
		const { permission, allowed, except } = this.props
		const { hasPermissions } = this.state

		const newVisibility = checkPermissions(permission, allowed, except)
		if (hasPermissions !== newVisibility) {
			this.setState({
				hasPermissions: newVisibility
			})
		}
	}

	render = () => {
		const { hasPermissions } = this.state
	
		if (hasPermissions) {
			return (
				<React.Fragment>
					{this.props.children}
				</React.Fragment>
			)
		}
		
		return null
	}
}

const mapStateToProps = state => ({
	permission: get(state, 'user.profile.permission')
})

export default connect(mapStateToProps)(Permissions)

