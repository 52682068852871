export const GIFT_CARDS_LOAD_START = 'GIFT_CARDS_LOAD_START'
export const GIFT_CARDS_LOAD_DONE = 'GIFT_CARDS_LOAD_DONE'
export const GIFT_CARDS_LOAD_FAIL = 'GIFT_CARDS_LOAD_FAIL'

export const GIFT_CARD_LOAD_START = 'GIFT_CARD_LOAD_START'
export const GIFT_CARD_LOAD_DONE = 'GIFT_CARD_LOAD_DONE'
export const GIFT_CARD_LOAD_FAIL = 'GIFT_CARD_LOAD_FAIL'

export const GIFT_CARD_BRANCHES_MASTER_LOAD_START = 'GIFT_CARD_BRANCHES_MASTER_LOAD_START'
export const GIFT_CARD_BRANCHES_MASTER_LOAD_DONE = 'GIFT_CARD_BRANCHES_MASTER_LOAD_DONE'
export const GIFT_CARD_BRANCHES_MASTER_LOAD_FAIL = 'GIFT_CARD_BRANCHES_MASTER_LOAD_FAIL'

export const GIFT_CARD_BRANCHES_SLAVE_LOAD_DONE = 'GIFT_CARD_BRANCHES_SLAVE_LOAD_DONE'
export const GIFT_CARD_BRANCHES_SLAVE_LOAD_FAIL = 'GIFT_CARD_BRANCHES_SLAVE_LOAD_FAIL'
export const GIFT_CARD_BRANCHES_SLAVE_LOAD_START = 'GIFT_CARD_BRANCHES_SLAVE_LOAD_START'

export const GIFT_CARD_VALIDATION_GIFT_CODE_START = 'GIFT_CARD_VALIDATION_GIFT_CODE_START'
export const GIFT_CARD_VALIDATION_GIFT_CODE_DONE = 'GIFT_CARD_VALIDATION_GIFT_CODE_DONE'
export const GIFT_CARD_VALIDATION_GIFT_CODE_FAIL = 'GIFT_CARD_VALIDATION_GIFT_CODE_FAIL'
export const GIFT_CARD_VALIDATION_GIFT_CODE_REMOVE = 'GIFT_CARD_VALIDATION_GIFT_CODE_REMOVE'
export const GIFT_CARD_VALIDATION_GIFT_CODE_REMOVE_ALL = 'GIFT_CARD_VALIDATION_GIFT_CODE_REMOVE_ALL'

export const GIFT_CARD_USE_GIFT_CODES_START = 'GIFT_CARD_USE_GIFT_CODES_START'
export const GIFT_CARD_USE_GIFT_CODES_DONE = 'GIFT_CARD_USE_GIFT_CODES_DONE'
export const GIFT_CARD_USE_GIFT_CODES_FAIL = 'GIFTUSEDATIOSN_GIFT_CODE_FAIL'
