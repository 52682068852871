import {
	CAMPAIGN_LOAD_DONE,
	CAMPAIGN_LOAD_FAIL,
	CAMPAIGN_LOAD_START,
	CAMPAIGNS_LOAD_START,
	CAMPAIGNS_LOAD_FAIL,
	CAMPAIGNS_LOAD_DONE
} from '../types/campaign'

import {RESET_STORE} from "../types/global"

export const initState = {
	campaigns: {
		isLoading: false,
		isFailure: false,
		data: []
	},
	campaign: {
		isLoading: false,
		isFailure: false,
		data: {}
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case CAMPAIGNS_LOAD_START:
			return {
				...state,
				campaigns: {
					data: [],
					isFailure: false,
					isLoading: true
				}
			}
		case CAMPAIGNS_LOAD_DONE:
			return {
				...state,
				campaigns: {
					data:  action.payload.campaigns,
					isFailure: false,
					isLoading: false
				}
			}

		case CAMPAIGNS_LOAD_FAIL:
			return {
				...state,
				campaigns: {
					data:  [],
					isFailure: true,
					isLoading: false
				}
			}
		case CAMPAIGN_LOAD_START:
			return {
				...state,
				campaign: {
					data: [],
					isFailure: false,
					isLoading: true
				}
			}
		case CAMPAIGN_LOAD_DONE:
			return {
				...state,
				campaign: {
					data:  action.payload.campaign,
					isFailure: false,
					isLoading: false
				}
			}

		case CAMPAIGN_LOAD_FAIL:
			return {
				...state,
				campaign: {
					data:  [],
					isFailure: true,
					isLoading: false
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
