import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { compose, bindActionCreators } from "redux"
import { COMPONENTS, PATHS } from "../../enums/namespaces"
import { Icon, Table, Modal, Select, Form, Button } from 'antd'
import { connect } from "react-redux"
import { unassignCampaignStore, loadStores, assignCampaignStores } from "../../actions/storeActions"
import { get, map, differenceBy, deburr } from 'lodash'

const { Option } = Select

class Stores extends React.Component {
	static propTypes = {
		campaignStores: PropTypes.array.isRequired,
		stores: PropTypes.array,
		campaignId: PropTypes.number.isRequired,
		unassignCampaignStore: PropTypes.func.isRequired,
		assignCampaignStores: PropTypes.func.isRequired,
		loadStores: PropTypes.func.isRequired,
		prevPage: PropTypes.func.isRequired,
		nextPage: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
		this.state = {
			modalVisible: false,
			confirmLoading: false,
			branches: {
				error: null,
				selectedItems: []
			}
		}
	}

	componentDidMount = () => {
		this.loadStores()
	}

	loadStores = async () => {
		const { loadStores } = this.props
		try {
			loadStores()
		} catch (error) {
			console.log(error)
		}
	}

	showModal = () => {
		this.setState({
			modalVisible: true,
		})
	}

	handleAssignStore = async () => {
		const { assignCampaignStores, campaignId, t } = this.props
		const { branches } = this.state

		const branchesError = (get(branches, 'selectedItems').length < 1) && t('components:GiftCardStep2.Výber pobočky je povinný')
		if (branchesError) {
			this.setState({
				...this.state,
				branches: {
					error: branchesError,
					selectedItems: get(branches, 'selectedItems'),
				}
			})

			return
		}

		this.setState({
			confirmLoading: true,
		})
		this.setState({
			modalVisible: false,
			confirmLoading: false,
		})

		await assignCampaignStores(campaignId, get(branches, 'selectedItems'))
	}

	handleCancel = () => {
		this.setState({
			modalVisible: false,
		})
	}

	handleChange = (values) => {
		const { t } = this.props

		let error = null
		if (values.length < 1) {
			error = t('components:GiftCardStep2.Výber pobočky je povinný')
		}

		this.setState({
			branches: {
				error,
				selectedItems: values
			}
		})

		this.setState({ selectedItems: values })
	}

	handleSelectAllBranches = () => {
		const { stores, campaignStores } = this.props
		const { branches } = this.state

		const unassignedStores = differenceBy(stores, campaignStores, 'id')
		const allUnassignedBranchIds = map(unassignedStores, store => {
			return get(store, 'id')
		})

		this.setState({
			...this.state,
			branches: {
				error: get(branches, 'error'),
				selectedItems: allUnassignedBranchIds,
			}
		})
	}

	handleUnassignStore = (storeId) => {
		const { campaignId, unassignCampaignStore } = this.props
		unassignCampaignStore(campaignId, storeId)
	}

	render = () => {
		const { t, stores, campaignStores, campaignId, prevPage, nextPage } = this.props
		const { branches } = this.state

		const columns = [{
			title: t('pages:Name'),
			dataIndex: 'name',
			key: 'name',
			render: (text, record) => (
				<React.Fragment>
					<span className={'store-name'}>
						{record.name}
					</span>
					{record.street &&
					<span className={'store-street'}>
						<Icon type="environment"/>{record.street}
					</span>
					}
					{record.phone &&
					<span className={'store-phone'}>
						<Icon type="phone"/>{record.phone}
					</span>}
				</React.Fragment>
			)
		}, {
			title: t('pages:City'),
			dataIndex: 'city',
			key: 'city',
		}, {
			key: 'action',
			render: (text, record) => (
				<div className={'store-actions'}>
					<Icon onClick={() => this.handleUnassignStore(record.id)} type="close"/>
				</div>
			),
		}]

		const unassignedStores = differenceBy(stores, campaignStores, 'id')
		const templateOptions = map(unassignedStores, store =>
			<Option
				key={get(store, 'id')}
				value={get(store, 'id')}
			>
				{`${get(store, 'name')} ${get(store, 'street')},${get(store, 'city')}`}
			</Option>
		)
		return (
			<React.Fragment>
				<div className={'row'}>
					<div className={'col-12'}>
						<h2>{t('components:stores.title')}</h2>
						<p>{t('components:stores.text')}</p>
					</div>
					<div className={'col-12'}>
						<Link to={t('paths:campaign-add-store.path', { campaignId })} className={'pull-right'}>
							<button className={'btn primary-yellow'}>{t('paths:campaign-add-store.title')}</button>
						</Link>
						<button onClick={this.showModal} className={'btn pull-right'} style={{ marginRight: '20px' }}>{t('pages:select-store.title')}</button>
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-md-12'}>
						<Table columns={columns} dataSource={campaignStores} style={{ marginTop: '40px' }}/>
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-md-12 steps-action'}>
						<button className={'btn'} style={{ float: 'left' }} onClick={prevPage}><Icon type="left"/>&nbsp;{t('components:Previous step')}</button>
						<button className={'btn primary-yellow'} style={{ float: 'right' }} onClick={nextPage}>{t('components:Next step')}&nbsp;<Icon type="right"/></button>
					</div>
				</div>
				<Modal
					title={t('pages:Add stores')}
					visible={this.state.modalVisible}
					confirmLoading={this.state.confirmLoading}
					onCancel={this.handleCancel}
					footer={[
						<Button key="selectAll" onClick={this.handleSelectAllBranches} style={{ float: 'left' }}>
							{t('components:Select All')}
						</Button>,
						<Button key="back" onClick={this.handleCancel} style={{ background: 'red', color: 'white' }}>
							{t('pages:Cancel')}
						</Button>,
						<Button key="submit" type="primary" onClick={this.handleAssignStore}>
							{t('pages:Add')}
						</Button>,
					]}
				>
					<Form.Item
						validateStatus={get(branches, 'error') && 'error'}
						help={get(branches, 'error')}
					>
						<Select
							style={{ width: '100%' }}
							size={'large'}
							placeholder={t('pages:Click to select')}
							onChange={this.handleChange}
							mode={'multiple'}
							value={get(branches, 'selectedItems')}
							allowClear
							filterOption={(input, option) =>
								deburr(option.props.children.toLowerCase()).indexOf(deburr(input.toLowerCase())) >= 0
							}
						>
							{templateOptions}
						</Select>
					</Form.Item>
				</Modal>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	stores: get(state, 'stores.stores.data'),
})

const mapDispatchToProps = (dispatch) => ({
	unassignCampaignStore: bindActionCreators(unassignCampaignStore, dispatch),
	assignCampaignStores: bindActionCreators(assignCampaignStores, dispatch),
	loadStores: bindActionCreators(loadStores, dispatch)
})

export default compose(withNamespaces([COMPONENTS, PATHS]), connect(mapStateToProps, mapDispatchToProps))(Stores)
