import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { Input } from 'antd'
import { FORMS } from '../../enums/forms'
import { withNamespaces } from 'react-i18next'
import { COMPONENTS, PATHS, PAGES } from '../../enums/namespaces'
import { makeField } from '../../atoms/utils'
import logo from '../../public/images/Euronics_logotype_2col_wht_rgb.svg'

const AInput = makeField(Input)

class RegistrationForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
	}

	componentDidMount() {
		const { reset } = this.props
		reset()
	}

	render() {
		const { handleSubmit, t } = this.props
		return (
			<div className={'form-container'}>
				<img src={logo} alt={'Euronics'}/>
				<form onSubmit={handleSubmit} noValidate>
					<Field
						label={t('components:E-mail')}
						name='email'
						type={'email'}
						size={'large'}
						placeholder={t('components:E-mail')}
						component={AInput}
					/>
					<Field
						label={t('components:Name')}
						name='firstName'
						type={'text'}
						size={'large'}
						placeholder={t('components:Name')}
						component={AInput}
					/>
					<Field
						label={t('components:Surname')}
						name='lastName'
						type={'text'}
						size={'large'}
						placeholder={t('components:Surname')}
						component={AInput}
					/>
					<Field
						label={t('components:Password')}
						name='password'
						type={'password'}
						size={'large'}
						placeholder={t('components:Password')}
						component={AInput}
					/>
					<Field
						label={t('components:Confirm password')}
						name='confirmPassword'
						type={'password'}
						size={'large'}
						placeholder={t('components:Confirm password')}
						component={AInput}
					/>
					<button type="submit" className={'btn primary vertical'}>
						{t('components:Create account')}
					</button>
				</form>
			</div>
		)
	}
}

const validate = (values, { t }) => {
	const errors = {}

	if (!values.email) {
		errors.email = t('pages:UserForm.Toto pole je povinné')
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) { // regex from redux form example https://redux-form.com/8.1.0/examples/syncvalidation/
		errors.email = t('pages:UserForm.Nesprávny formát emailovej adresy')
	}
	if (!values.password) {
		errors.password = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.firstName) {
		errors.firstName = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.lastName) {
		errors.lastName = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.confirmPassword) {
		errors.confirmPassword = t('components:validator.validate.Toto pole je povinné')
	} else if (values.confirmPassword !== values.password) {
		errors.confirmPassword = t('components:validator.validate.Heslá sa nezhodujú')
	}

	return errors
}

const form = reduxForm({
	form: FORMS.REGISTRATION_FORM,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validate,
})(RegistrationForm)

export default withNamespaces([PAGES, COMPONENTS, PATHS])(form)
