import React from 'react'
import { get } from 'lodash'

import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"
import { loadCampaign } from "../../actions/campaignActions"

import { PATHS, PAGES } from "../../enums/namespaces"
import { CAMPAIGN_TYPES, PERMISSION } from "../../enums/general"
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
import { Steps } from 'antd'
import CampaignInfoForm from '../../components/campaigns/CampaignInfoForm'
import VoucherForm from '../../components/campaigns/VoucherForm'
import Summary from '../../components/campaigns/Summary'
import Breadcrumbs from "../../components/header/Breadcrumbs"
import Locations from "../../components/campaigns/Locations"
import Stores from "../../components/campaigns/Stores"
import Loading from "../../atoms/Loading"
import { withPermissions } from '../../utils/permissionsHoc'
import { history } from "../../utils/history"
import { t } from "i18next"

const Step = Steps.Step

class CampaignPage extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		computedMatch: PropTypes.shape().isRequired,
		loadCampaign: PropTypes.func.isRequired,
		campaign: PropTypes.shape().isRequired,
		createCampaign: PropTypes.shape()
	}

	constructor(props) {
		super(props)
		this.state = {
			current: 0,
		}
	}

	componentDidMount = async () => {
		const { computedMatch, loadCampaign } = this.props
		const campaignId = get(computedMatch, 'params.campaignId')
		const step = get(computedMatch, 'params.step')
		await loadCampaign(campaignId)
		const stepNumber = await this.computeStep(step)
		this.setState({
			current: stepNumber
		})

	}

	computeStep = async (step) => {
		const { campaign } = this.props
		if (campaign.data.campaign.type === CAMPAIGN_TYPES.GEO_FENCING) {
			switch (step) {
				case t(`paths:campaignSteps.kampan`):
					return 0
				case t(`paths:campaignSteps.voucher`):
					return 1
				case t(`paths:campaignSteps.locations`):
					return 2
				case t(`paths:campaignSteps.stores`):
					return 3
				case t(`paths:campaignSteps.summary`):
					return 4
				default:
					return 0
			}
		} else {
			switch (step) {
				case t(`paths:campaignSteps.kampan`):
					return 0
				case t(`paths:campaignSteps.voucher`):
					return 1
				case t(`paths:campaignSteps.summary`):
					return 2
				default:
					return 0
			}
		}
	}

	createHandler = async (values) => {
		const { createCampaign } = this.props
		const { type } = this.state
		createCampaign({
			type: type,
			name: values.campaignName
		})
		this.dismissModal()
	}

	next = () => {
		const current = this.state.current + 1
		this.setState({ current })
	}

	prev = () => {
		const current = this.state.current - 1
		this.setState({ current })
	}

	selectStep = (step) => {
		const { campaign, computedMatch } = this.props
		const campaignId = get(computedMatch, 'params.campaignId')
		this.stepToUrl(step, campaignId, get(campaign, 'data.campaign.type'))
		const current = step
		this.setState({ current })
	}

	campaignInfoFormHandler = () => {
	}

	voucherFormHandler = (values) => {
		console.log(values)
	}

	storeFormHandler = () => {
	}

	breadcrumbsInit = () => {
		const { t, campaign } = this.props
		return {
			items: [{
				link: t('paths:campaigns.path'),
				name: t('paths:campaigns.title')
			}, {
				name: get(campaign, 'data.campaign.name')
			}],
			title: t('pages:Campaign detail')
		}
	}

	stepToUrl = (step, id, type) => {
		const { t } = this.props
		if (type === CAMPAIGN_TYPES.GEO_FENCING) {
			switch (step) {
				case 0:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.kampan`) }))
					break
				case 1:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.voucher`) }))
					break
				case 2:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.locations`) }))
					break
				case 3:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.stores`) }))
					break
				case 4:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.summary`) }))
					break
				default:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.kampan`) }))
			}
		} else {
			switch (step) {
				case 0:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.kampan`) }))
					break
				case 1:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.voucher`) }))
					break
				case 2:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.summary`) }))
					break
				default:
					history.push(t('paths:campaign.path', { campaignId: id, step: t(`paths:campaignSteps.kampan`) }))
			}
		}
	}

	render = () => {
		const { t, campaign } = this.props
		const { current } = this.state

		let steps = []
		const stepsGeolocation = [{
			title: t('components:Campaign'),
			content: get(campaign, 'data.campaign')
				&& <CampaignInfoForm
					nextPage={this.next}
					campaign={get(campaign, 'data.campaign')}
				/>
		}, {
			title: t('components:Voucher'),
			content:
				<VoucherForm
					prevPage={this.prev}
					nextPage={this.next}
					campaignId={get(campaign, 'data.campaign.id')}
					voucher={get(campaign, 'data.voucher')}
				/>
		}, {
			title: t('components:Locations'),
			content:
				<Locations
					prevPage={this.prev}
					nextPage={this.next}
					campaignId={get(campaign, 'data.campaign.id')}
					locations={get(campaign, 'data.locations')}
				/>
		}, {
			title: t('components:Stores'),
			content:
				<Stores
					prevPage={this.prev}
					nextPage={this.next}
					campaignId={get(campaign, 'data.campaign.id')}
					campaignStores={get(campaign, 'data.stores')}
				/>
		}, {
			title: t('components:Summary'),
			content:
				<Summary
					prevPage={this.prev}
					nextPage={this.next}
				/>
		}]

		const stepsProduct = [{
			title: t('components:Campaign'),
			content: get(campaign, 'data.campaign')
				&& <CampaignInfoForm
					nextPage={this.next}
					campaign={get(campaign, 'data.campaign')}
				/>
		}, {
			title: t('components:Voucher'),
			content:
				<VoucherForm
					prevPage={this.prev}
					nextPage={this.next}
					campaignId={get(campaign, 'data.campaign.id')}
					voucher={get(campaign, 'data.voucher')}
				/>
		}, {
			title: t('components:Summary'),
			content:
				<Summary
					campaignId={get(campaign, 'campaign.id')}
					prevPage={this.prev}
					nextPage={this.next}
				/>
		}]

		let title = ''
		if (get(campaign, 'data.campaign.type') === CAMPAIGN_TYPES.GEO_FENCING) {
			steps = stepsGeolocation
			title = t('components:Geo fencing campaign')
		}

		if (get(campaign, 'data.campaign.type') === CAMPAIGN_TYPES.PRODUCT_PROMOTION) {
			steps = stepsProduct
			title = t('components:Product promotion')
		}

		return (
			<React.Fragment>
				<div className={'container-fluid breadcrumbs'}>
					<Breadcrumbs breadcrumbs={this.breadcrumbsInit()} campaign={campaign}/>
				</div>
				<div className={'container-fluid form-steps'}>
					<div className={"container"}>
						<h2 style={{ textAlign: 'center', marginBottom: '30px' }}>{title}</h2>
						<Steps current={current} labelPlacement={'vertical'}>
							{steps.map((item, index) => <Step key={index} title={item.title} onClick={() => this.selectStep(index)}/>)}
						</Steps>
					</div>
				</div>
				<div className={"container"}>
					<div className="steps-content" style={{ paddingTop: '35px' }}>{get(steps[current], 'content')}</div>
				</div>
				<Loading/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	campaign: get(state, 'campaigns.campaign'),
})

const mapDispatchToProps = (dispatch) => ({
	loadCampaign: bindActionCreators(loadCampaign, dispatch),
})

export default compose(
	withPermissions([PERMISSION.ADMINISTRATOR]),
	withNamespaces([PAGES, PATHS, 'components']),
	connect(mapStateToProps, mapDispatchToProps)
)(CampaignPage)
