export const NAMESPACES = [
	'translation',
	'atoms',
	'pages',
	'components',
	'paths'
]

export const LANGUAGES = ['sk', 'en']

export const DEFAULT_LOCALE = 'sk'
