export const CAMPAIGNS_LOAD_START = 'CAMPAIGNS_LOAD_START'
export const CAMPAIGNS_LOAD_DONE = 'CAMPAIGNS_LOAD_DONE'
export const CAMPAIGNS_LOAD_FAIL = 'CAMPAIGNS_LOAD_FAIL'
export const CAMPAIGN_LOAD_START = 'CAMPAIGN_LOAD_START'
export const CAMPAIGN_LOAD_DONE = 'CAMPAIGN_LOAD_DONE'
export const CAMPAIGN_LOAD_FAIL = 'CAMPAIGN_LOAD_FAIL'
export const CAMPAIGN_CREATE_FAIL = 'CAMPAIGN_CREATE_FAIL'
export const CAMPAIGN_CREATE_START = 'CAMPAIGN_CREATE_START'
export const CAMPAIGN_CREATE_DONE = 'CAMPAIGN_CREATE_DONE'
export const SAVE_CAMPAIGN_FAIL = 'SAVE_CAMPAIGN_FAIL'
export const SAVE_CAMPAIGN_START = 'SAVE_CAMPAIGN_START'
export const SAVE_CAMPAIGN_DONE = 'SAVE_CAMPAIGN_DONE'
export const SAVE_VOUCHER_FAIL = 'SAVE_VOUCHER_FAIL'
export const SAVE_VOUCHER_START = 'SAVE_VOUCHER_START'
export const SAVE_VOUCHER_DONE = 'SAVE_VOUCHER_DONE'
export const REMOVE_CAMPAIGN_FAIL = 'REMOVE_CAMPAIGN_FAIL'
export const REMOVE_CAMPAIGN_START = 'REMOVE_CAMPAIGN_START'
export const REMOVE_CAMPAIGN_DONE = 'REMOVE_CAMPAIGN_DONE'
export const CONFIRM_CAMPAIGN_FAIL = 'CONFIRM_CAMPAIGN_FAIL'
export const CONFIRM_CAMPAIGN_START = 'CONFIRM_CAMPAIGN_START'
export const CONFIRM_CAMPAIGN_DONE = 'CONFIRM_CAMPAIGN_DONE'
