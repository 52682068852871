import React from 'react'
import { compose } from "redux"
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
import ReactQueryParams from 'react-query-params'
import { map, get, uniq } from 'lodash'

import Loading from "../../../../atoms/Loading"

// forms
import BranchForm from './form/BranchForm'

// enums
import { PATHS, PAGES } from "../../../../enums/namespaces"
import { ENDPOINTS } from '../../../../enums/endpoints'
import { PERMISSION } from '../../../../enums/general'

// utils
import { postReq } from '../../../../utils/request'
import { withPermissions } from '../../../../utils/permissionsHoc'
import { history } from '../../../../utils/history'
import { t } from 'i18next'

class AdminBranchesCreatePage extends ReactQueryParams {
	static propTypes = {
		t: PropTypes.func.isRequired,
	}

	submitForm = async (values) => {
		try {
			const webUsersIDs = uniq(map(get(values, 'webUsersIDs'), (user) => user.key))
			await postReq(ENDPOINTS.ADMIN_BRANCHES, null, { ...values, webUsersIDs })
			history.push(t('paths:admin.branches.path'))
		} catch (e) {
			console.log(e)
		}
	}

	render = () => {
		const { t } = this.props
		return (
			<div style={{ paddingTop: '30px', paddingBottom: '100px' }}>
				<BranchForm submitBtnText={t('pages:AdminBranchesCreatePage.Vytvoriť')} t={t} onSubmit={this.submitForm} />
				<Loading/>
			</div>
		)
	}
}

export default compose(
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces([PAGES, PATHS, 'components']),
)(AdminBranchesCreatePage)

