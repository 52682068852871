import {
	USER_UPDATE_SUCCESS,
	USER_LOAD_SUCCESS,
	RESET_PASSWORD_START,
	RESET_PASSWORD_DONE,
	RESET_PASSWORD_FAIL,
	USER_UPDATE_FAIL,
	USER_UPDATE_START
} from "../types/user"
import {ENDPOINTS} from "../enums/endpoints"
import {hideLoading, showLoading} from "./loadingActions"
import {postReq, getReq, patchReq} from "../utils/request"

// function userUpdateSuccess(user) {
// 	return {
// 		type: USER_UPDATE_SUCCESS,
// 		payload: {
// 			user
// 		}
// 	}
// }

export function userLoadSuccess(user) {
	return {
		type: USER_LOAD_SUCCESS,
		payload: {
			user
		}
	}
}

export function updateUser(data) {
	return async (dispatch) => {
		dispatch({
			type: USER_UPDATE_START
		})
		try {
			await patchReq(ENDPOINTS.PROFILE, null, data, null)
			dispatch({
				type: USER_UPDATE_SUCCESS,
				payload: {
					user: data
				}
			})
		} catch (error) {
			dispatch({
				type: USER_UPDATE_FAIL
			})
		}
	}
}

export function loadUser() {
	return async (dispatch) => {
		dispatch(showLoading())
		try {
			const response = await getReq(ENDPOINTS.PROFILE)
			const user = response.data
			dispatch(userLoadSuccess(user))
			dispatch(hideLoading())
		} catch (error) {
			dispatch(hideLoading())
		}
	}
}

export function resetPassword(data) {
	return async (dispatch) => {
		dispatch({
			type: RESET_PASSWORD_START
		})
		try {
			await postReq(ENDPOINTS.RESET_PASSWORD, null, data, null)
			dispatch({
				type: RESET_PASSWORD_DONE
			})
		} catch (error) {
			dispatch({
				type: RESET_PASSWORD_FAIL
			})
		}
	}
}

