import { persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import storageSession from "redux-persist/es/storage/session"
import { reducer as formReducer } from 'redux-form'

import userReducer from './userReducer'
import menuReducer from './menuReducer'
import loadingReducer from './loadingReducer'
import campaignReducer from './campaignReducer'
import giftCardsReducer from './giftCardsReducer'
import locationReducer from './locationReducer'
import settingsReducer from './settingsReducer'
import storesReducer from './storesReducer'
import adminReducer from './adminReducer'
import reportsReducer from './reportsReducer'

export const REDUCER_KEYS = {
	USER: 'USER',
	SETTINGS: 'SETTINGS',
	FORM: 'FORMS',
	ADMIN: 'ADMIN',
	REPORTS: 'REPORTS',
	GIFT_CARDS: 'GIFT_CARDS'
}

const rootReducer = combineReducers({
	user: persistReducer({
		key: REDUCER_KEYS.USER,
		storage: storageSession
	}, userReducer),
	form: persistReducer({
		key: REDUCER_KEYS.FORM,
		storage: storageSession
	}, formReducer),
	menu: menuReducer,
	loading: loadingReducer,
	campaigns: campaignReducer,
	giftCards: persistReducer({
		key: REDUCER_KEYS.SETTINGS,
		storage: storageSession
	}, giftCardsReducer),
	locations: locationReducer,
	stores: storesReducer,
	settings: persistReducer({
		key: REDUCER_KEYS.SETTINGS,
		storage: storageSession
	}, settingsReducer),
	admin: persistReducer({
		key: REDUCER_KEYS.ADMIN,
		storage: storageSession
	}, adminReducer),
	reports: persistReducer({
		key: REDUCER_KEYS.REPORTS,
		storage: storageSession
	}, reportsReducer)
})

export default rootReducer
