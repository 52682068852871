import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from './MenuItem'

import { map } from 'lodash'
import { withNamespaces } from 'react-i18next'

import { COMPONENTS, PATHS } from '../../enums/namespaces'
import { PERMISSION } from '../../enums/general'
import Permissions from '../../utils/permissionsHoc'

export const menu = (t) => [{
	key: 'campaigns',
	title: t('paths:campaigns.title'),
	link: t('paths:campaigns.path'),
	allowPermissions: [PERMISSION.ADMINISTRATOR]
},{
	key: 'giftCards',
	title: t('paths:gift-cards.title'),
	link: t('paths:gift-cards.path'),
	allowPermissions: [PERMISSION.ADMINISTRATOR, PERMISSION.CASHIER, PERMISSION.MANAGER]
}, {
	key: 'reports',
	title: t('paths:reports.title'),
	link: t('paths:reports.path'),
	allowPermissions: [PERMISSION.ADMINISTRATOR, PERMISSION.CASHIER, PERMISSION.MANAGER]
},{
	key: 'admin',
	title: t('paths:admin.title'),
	link: t('paths:admin.path'),
	allowPermissions: [PERMISSION.ADMINISTRATOR]
}, {
	key: 'stores',
	title: t('paths:stores.title'),
	link: t('paths:stores.path'),
	allowPermissions: [PERMISSION.ADMINISTRATOR]
}, {
	key: 'locations',
	title: t('paths:locations.title'),
	link: t('paths:locations.path'),
	allowPermissions: [PERMISSION.ADMINISTRATOR]
}]

class Menu extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		menuItem: PropTypes.string
	}

	render = () => {
		const { t, menuItem } = this.props
		const items = map(menu(t), (item, index) => {
			return (<Permissions key={index} allowed={item.allowPermissions}>
				<MenuItem key={index} item={item} active={item.key === menuItem} />
			</Permissions>)
		})

		return <ul className={'main-menu'}>{items}</ul>
	}
}

export default withNamespaces([COMPONENTS, PATHS])(Menu)
