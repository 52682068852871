import React from 'react'
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {registerUser} from '../../actions/authActions'
import {isLoggedIn} from "../../utils/auth"
import RegistrationForm from "../../components/forms/RegistrationForm"
import {compose} from "redux"
import {withNamespaces} from "react-i18next"

class RegistrationPage extends React.Component {
	static propTypes = {
		processRegistration: PropTypes.func.isRequired
	}

	submitHandler = (data) => {
		const {processRegistration} = this.props
		processRegistration(data)
	}

	render() {
		const {t} = this.props
		if (isLoggedIn()) {
			return (<Redirect to={t('paths:base.path')}/>)
		}
		return (
			<React.Fragment>
				<RegistrationForm onSubmit={this.submitHandler}/>
			</React.Fragment>
		)
	}

}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => {
	return {
		processRegistration: bindActionCreators(registerUser, dispatch)
	}
}

export default compose(withNamespaces(['paths']), connect(mapStateToProps, mapDispatchToProps))(RegistrationPage)
