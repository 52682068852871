import React from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import PropTypes from 'prop-types'

const modalRoot = document.getElementById('modal-root')

class Modal extends React.Component {
	static propTypes = {
		shown: PropTypes.bool,
		size: PropTypes.string,
		dismiss: PropTypes.func
	}

	constructor(props) {
		super(props)
		this.el = document.createElement('div')
	}

	componentDidMount() {
		modalRoot.appendChild(this.el)
	}

	componentWillUnmount() {
		modalRoot.removeChild(this.el)
	}

	dismissModal = (e) => {
		const { dismiss } = this.props
		if (dismiss && e.target.className === 'modal dismissible shown') {
			dismiss()
		}
	}

	render() {
		const {shown, size} = this.props

		const element = (
			<div onClick={this.dismissModal} className={cx('modal', 'dismissible', {'shown': !!shown})} data-size={size || 'm'}>
				<div className="modal-wrapper">
					{this.props.children}
				</div>
			</div>
		)

		return (
			ReactDOM.createPortal(element, this.el)
		)
	}
}

export default Modal
