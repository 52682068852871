import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Select, Input } from 'antd'
import PropTypes from "prop-types"
import { map, get } from 'lodash'
import { withNamespaces } from 'react-i18next'
import { compose } from 'redux'

import { FORMS } from '../../../../enums/forms'
import { makeField, makeSelectField } from '../../../../atoms/utils'
import { PATHS, PAGES } from '../../../../enums/namespaces'

const ASelect = makeSelectField(Select)
const AInput = makeField(Input)

const { Option } = Select

class AdminUserFilterForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		settings: PropTypes.shape()
	}

	render = () => {
		const { t, handleSubmit, settings } = this.props
		
		const permissionOptions = map(get(settings, 'permissions'), (permission) => {
			return <Option key={get(permission, 'id')} value={get(permission, 'id')}>{get(permission, 'name')}</Option>
		})

		return (
			<form onSubmit={handleSubmit}>
				<div className={'row'}>
					<div className={'col-12'}>
						<div className={'panel clearfix'}>
							<div className={'row'} style={{ marginBottom: '20px' }}>
								<div className={'col-6'}>
									<Field
										label={t('pages:AdminUserFilterForm.Hľadať používateľa')}
										name="search"
										component={AInput}
										size={'large'}
										placeholder={t('pages:AdminUserFilterForm.Meno používateľa')}
									/>

								</div>
								<div className={'col-3'}>
									<Field
										label={t('pages:AdminUserFilterForm.Pozícia')}
										name="permission"
										allowClear
										component={ASelect}
										size={'large'}
										placeholder={t('pages:AdminUserFilterForm.Zvoľte pozíciu')}
									>
										{permissionOptions}
									</Field>
								</div>
								<div className={'col-3'}>
									<button
										className={'btn primary'}
										type="submit"
										style={{ marginTop: "39px" }}
									>
										{t('pages:AdminUserFilterForm.Vyfiltrovať')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

const form = reduxForm({
	form: FORMS.ADMIN_USERS_FILTER,
	touchOnChange: true,
	destroyOnUnmount: false
})(AdminUserFilterForm)

export default compose(
	withNamespaces([PAGES, PATHS])
)(form)
