import { isEmpty, get } from 'lodash'

export default (values, { t }) => {
	const errors = {}

	if (!values.name){
		errors.name = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.categoryID || isEmpty(values.categoryID)){
		errors.categoryID = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.description){
		errors.description = t('components:validator.validate.Toto pole je povinné')
	}

	if (!get(values,'mediaID.0.id')){
		errors.mediaID = t('components:validator.validate.Toto pole je povinné')
	}

	return errors
}
