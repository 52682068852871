import React from 'react'
import PropTypes from 'prop-types'
import { withScriptjs, withGoogleMap, GoogleMap, Circle, Marker } from "react-google-maps"
import { compose } from "redux"

export class MapContainer extends React.Component {

	static propTypes = {
		lat: PropTypes.number.isRequired,
		mapLat: PropTypes.number.isRequired,
		mapLng: PropTypes.number.isRequired,
		long: PropTypes.number.isRequired,
		zoom: PropTypes.number.isRequired,
		radius: PropTypes.number.isRequired,
		radiusMarker: PropTypes.bool,
		onLocationChange: PropTypes.func.isRequired,
		markers: PropTypes.shape({}),
		options: PropTypes.shape({}),
		latLng: PropTypes.object
	}

	render = () => {
		const { zoom, lat, mapLat, mapLng, radius, long, onLocationChange, options, radiusMarker } = this.props
		return (
			<GoogleMap
				defaultCenter={{ lat, lng: long }}
				defaultZoom={zoom}
				options={{
					scrollwheel: false,
					fullscreenControl: false,
					mapTypeControl: false,
					streetViewControl: false,
					...options
				}}
				center={{ lat: mapLat, lng: mapLng }}

				onRightClick={(e) => onLocationChange(e)}
			>
				{radiusMarker && <Circle
					center={{ lat, lng: long }}
					radius={radius}
					ref={circle => { this.circle = circle }}
					options={{
						strokeColor: '#144F9F',
						strokeOpacity: 0.8,
						strokeWeight: 1,
						fillColor: '#144F9F',
						fillOpacity: 0.2,
					}}
					onRightClick={(e) => onLocationChange(e)}
				/>}
				<Marker
					position={{ lat, lng: long }}
					icon={'/images/marker-red.svg'}
					draggable={true}
					onDragEnd={(e) => onLocationChange(e)}
				/>
			</GoogleMap>
		)
	}
}

export default compose(withScriptjs,withGoogleMap)(MapContainer)
