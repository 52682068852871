export const STORES_LOAD_START = 'STORES_LOAD_START'
export const STORES_LOAD_DONE = 'STORES_LOAD_DONE'
export const STORES_LOAD_FAIL = 'STORES_LOAD_FAIL'
export const STORE_LOAD_START = 'STORE_LOAD_START'
export const STORE_LOAD_DONE = 'STORE_LOAD_DONE'
export const STORE_LOAD_FAIL = 'STORE_LOAD_FAIL'
export const STORE_CREATE_FAIL = 'STORE_CREATE_FAIL'
export const STORE_CREATE_START = 'STORE_CREATE_START'
export const STORE_CREATE_DONE = 'STORE_CREATE_DONE'
export const STORE_UPDATE_FAIL = 'STORE_UPDATE_FAIL'
export const STORE_UPDATE_START = 'STORE_UPDATE_START'
export const STORE_UPDATE_DONE = 'STORE_UPDATE_DONE'
export const STORE_DELETE_FAIL = 'STORE_DELETE_FAIL'
export const STORE_DELETE_START = 'STORE_DELETE_START'
export const STORE_DELETE_DONE = 'STORE_DELETE_DONE'
export const CAMPAIGN_STORES_ASSIGN_FAIL = 'CAMPAIGN_STORES_ASSIGN_FAIL'
export const CAMPAIGN_STORES_ASSIGN_START = 'CAMPAIGN_STORES_ASSIGN_START'
export const CAMPAIGN_STORES_ASSIGN_DONE = 'CAMPAIGN_STORES_ASSIGN_DONE'
export const CAMPAIGN_STORES_UNASSIGN_FAIL = 'CAMPAIGN_STORES_UNASSIGN_FAIL'
export const CAMPAIGN_STORES_UNASSIGN_START = 'CAMPAIGN_STORES_UNASSIGN_START'
export const CAMPAIGN_STORES_UNASSIGN_DONE = 'CAMPAIGN_STORES_UNASSIGN_DONE'
