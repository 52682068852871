import decode from 'jwt-decode'
import {get} from 'lodash'
import moment from 'moment'

export const ACCESS_TOKEN_KEY = 'access_token'
export const LOGIN_URL = '/login'

/**
 * Removes saved token from localstorage
 */
export function clearAccessToken() {
	localStorage.removeItem(ACCESS_TOKEN_KEY)
}

/**
 * Redirect user into login page
 */
export function login() {
	window.location.replace(LOGIN_URL)
}

/**
 * @return string token
 *
 * Returns acces token saved into storage or null
 */
export function getAccessToken() {
	return localStorage.getItem(ACCESS_TOKEN_KEY)
}

/**
 * @param token
 *
 * Save user token into localstorage
 */
export function setAccessToken(token) {
	localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

/**
 * @return boolean
 *
 * check if token is exist and try to decoded
 */
export function isLoggedIn() {
	try {
		const token = getAccessToken()
		if (!token){
			return false
		}
		const payload = decode(token)
		const exp = get(payload, 'exp')
		if (!exp) {
			return false
		}
		const timestamp = moment().unix()
		return timestamp < exp
	} catch (error) {
		return false
	}
}

/**
 * @return boolean
 *
 * Check if token is not expired
 */
export function verifyTokenExpiration(token) {
	try {
		if (!token){
			return false
		}
		const payload = decode(token)
		const exp = get(payload, 'exp')
		if (!exp) {
			return false
		}
		const timestamp = moment().unix()
		return timestamp < exp
	} catch (error) {
		return false
	}
}

/**
 * @return object
 *
 * Check if token is not expired
 */
export function decodeToken(token) {
	try {
		if (!token){
			return null
		}
		return decode(token)
	} catch (error) {
		return null
	}
}

export function userMunicipality(user) {
	if (user.isAdmin) {
		return null
	}
	return get(user, 'municipalities[0]')
}
