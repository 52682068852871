import React from 'react'
import Header from '../../components/header/Header'

const MainLayout = ({ children, menuItem }) => {
	return (
		<React.Fragment>
			<Header menuItem={menuItem} />
			{children}
		</React.Fragment>
	)
}

export default MainLayout
