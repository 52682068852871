import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { Input } from 'antd'
import { FORMS } from '../../enums/forms'

import { withNamespaces } from 'react-i18next'

import { COMPONENTS, PAGES, PATHS } from '../../enums/namespaces'

import { makeField } from '../../atoms/utils'

const AInput = makeField(Input)

class ProfileForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		initialize: PropTypes.func.isRequired,
		profile: PropTypes.shape({}),
		t: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		const { profile, initialize } = this.props
		if (profile) {
			initialize(profile)
		}
	}

	render() {
		const { handleSubmit, t } = this.props
		return (
			<div className={'form-container'}>
				<h2>{t('pages:Account settings')}</h2>
				<form onSubmit={handleSubmit}>
					<Field
						label={t('components:E-mail')}
						name='email'
						type={'email'}
						size={'large'}
						placeholder={t('components:E-mail')}
						component={AInput}
						disabled
					/>
					<Field
						required
						label={t('components:Name')}
						name='firstName'
						type={'text'}
						size={'large'}
						placeholder={t('components:Name')}
						component={AInput}
					/>
					<Field
						required
						label={t('components:Surname')}
						name='lastName'
						type={'text'}
						size={'large'}
						placeholder={t('components:Surname')}
						component={AInput}
					/>
					<button type="submit" className={'btn primary vertical'}>
						{t('components:Save settings')}
					</button>
				</form>
			</div>
		)
	}
}

const validate = (values, { t }) => {
	const errors = {}
	if (!values.firstName) {
		errors.firstName = t('components:validator.validate.Toto pole je povinné')
	}

	if (!values.lastName) {
		errors.lastName = t('components:validator.validate.Toto pole je povinné')
	}
	return errors
}

const form = reduxForm({
	form: FORMS.PROFILE_FORM,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validate,
})(ProfileForm)

export default withNamespaces([PAGES, COMPONENTS, PATHS])(form)
