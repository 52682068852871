import React from 'react'
import { compose } from "redux"
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
import ReactQueryParams from 'react-query-params'
import { connect } from "react-redux"
import { map, get, head, split } from 'lodash'

// forms
import UserForm from './form/UserForm'

// enums
import { PATHS, PAGES } from "../../../../enums/namespaces"
import { ENDPOINTS } from '../../../../enums/endpoints'
import { SEARCH_SEPARATOR, PERMISSION } from '../../../../enums/general'

// utils
import { postReq } from '../../../../utils/request'
import Loading from "../../../../atoms/Loading"
import { withPermissions } from '../../../../utils/permissionsHoc'
import { history } from '../../../../utils/history'
import { t } from 'i18next'

class AdminUserCreatePage extends ReactQueryParams {
	static propTypes = {
		t: PropTypes.func.isRequired,
	}

	submitForm = async (values) => {
		try {
			// normalize select box value cause searching logic
			const branches = map(get(values, 'branches'), (branch) => head(split(branch, SEARCH_SEPARATOR)))
			await postReq(ENDPOINTS.ADMIN_USERS, null, { ...values, branches })
			history.push(t('paths:admin.users.path'))
		} catch (e) {
			console.log(e)
		}
	}

	render = () => {
		const { t } = this.props
		return (
			<div style={{ paddingTop: '30px', paddingBottom: '100px' }}>
				<UserForm submitBtnText={t('pages:AdminUserCreatePage.Vytvoriť')} t={t} onSubmit={this.submitForm} />
				<Loading/>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces([PAGES, PATHS, 'components']),
	connect(null, mapDispatchToProps)
)(AdminUserCreatePage)
