import React from 'react'
import { compose, bindActionCreators } from "redux"
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
import ReactQueryParams from 'react-query-params'
import { connect } from "react-redux"
import { get, map, split, head } from 'lodash'

import * as AdminActions from '../../../../actions/adminActions'

// forms
import UserForm from './form/UserForm'

// enums
import { PATHS, PAGES } from "../../../../enums/namespaces"
import { ENDPOINTS } from '../../../../enums/endpoints'

// utils
import { patchReq } from '../../../../utils/request'
import Loading from "../../../../atoms/Loading"
import { SEARCH_SEPARATOR, PERMISSION } from '../../../../enums/general'
import { withPermissions } from '../../../../utils/permissionsHoc'

class AdminUserEditPage extends ReactQueryParams {
	static propTypes = {
		t: PropTypes.func.isRequired,
	}

	componentDidMount = () => {
		const { computedMatch, adminActions } = this.props
		const { params } = computedMatch
		adminActions.loadUser(params.userId)
	}

	submitForm = async (values) => {
		try {
			const { computedMatch } = this.props
			const { params } = computedMatch
			// normalize select box value cause searching logic
			const branches = map(get(values, 'branches'), (branch) => head(split(branch, SEARCH_SEPARATOR)))

			await patchReq(ENDPOINTS.ADMIN_USER(params.userId), null, { ...values, branches })
		} catch (e) {
			console.log(e)
		}
	}

	render = () => {
		const { t, user } = this.props
		return (
			<div style={{ paddingTop: '30px', paddingBottom: '100px' }}>
				{get(user, 'data') && <UserForm submitBtnText={t('pages:AdminUserEditPage.Upraviť')} user={user} t={t} onSubmit={this.submitForm} />}
				<Loading/>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	adminActions: bindActionCreators(AdminActions, dispatch)
})

const mapStateToProps = (state) => ({
	settings: state.settings.settings,
	user: state.admin.user
})

export default compose(
	withPermissions([ PERMISSION.ADMINISTRATOR ]),
	withNamespaces([PAGES, PATHS, 'components']),
	connect(mapStateToProps, mapDispatchToProps)
)(AdminUserEditPage)
