import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withNamespaces } from "react-i18next"
import { connect } from "react-redux"
import { compose, bindActionCreators } from "redux"
import MapContainer from '../atoms/Map'
import { unassignCampaignLocations, deleteUserLocation } from "../actions/locationsActions"
import { Modal } from 'antd'
import { googleMapURL } from '../utils/globals'

class Location extends React.Component {

	static propTypes = {
		t: PropTypes.func.isRequired,
		unassignCampaignLocations: PropTypes.func.isRequired,
		deleteUserLocation: PropTypes.func.isRequired,
		lat: PropTypes.number.isRequired,
		long: PropTypes.number.isRequired,
		campaignId: PropTypes.number,
		locationId: PropTypes.number.isRequired,
		radius: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
	}

	constructor(props) {
		super(props)
		this.state = {
			modalVisible: false,
		}
	}

	changeLocation = () => {}

	removeLocation = () => {
		const { locationId, campaignId, unassignCampaignLocations, deleteUserLocation } = this.props

		if (campaignId) {
			unassignCampaignLocations(campaignId, locationId)
		} else {
			deleteUserLocation(locationId)
		}
	}

	confirmRemove = () => {
		this.setState({
			modalVisible: true,
		})
	}

	handleOk = () => {
		this.setState({
			modalVisible: false,
		})
		this.removeLocation()
	}

	handleCancel = () => {
		this.setState({
			modalVisible: false,
		})
	}

	render = () => {
		const { t, lat, long, radius, name , campaignId, locationId } = this.props

		const locationEditPath = campaignId ? t('paths:campaign-edit-location.path', { campaignId: campaignId, locationId: locationId }) :
			t('paths:edit-location.path', { locationId })

		return (
			<div className={'col-6'}>
				<div className={'location-wrapper'}>
					<div className={'map-cover'}>
						<MapContainer
							googleMapURL={googleMapURL}
							loadingElement={<div style={{ height: '100%' }} />}
							containerElement={<div style={{ height: '180px' }} />}
							mapElement={<div style={{ height: '100%' }} />}
							lat={lat} long={long} zoom={14} radius={radius} mapLat={lat} mapLng={long}
							onLocationChange={this.changeLocation}
							radiusMarker
							options={{ draggable: false }}
						/>
					</div>
					<div className="location-controls">
						<div onClick={this.confirmRemove} className="control remove-location"/>
					</div>
					<Link to={locationEditPath}>
						<div className={'location-content'}>
							<h2>{name}</h2>
							{t('atoms:location.lat')}: {lat}<br/>
							{t('atoms:location.lon')}: {long}
							<div className={'location-radius'}>
								{t('atoms:location.radius')}: <b>{radius}</b>m
							</div>
						</div>
					</Link>
				</div>
				<Modal
					visible={this.state.modalVisible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					cancelText={t('atoms:Cancel')}
					okText={t('atoms:Delete')}
				>
					{
						campaignId ? <p>{t('atoms:location.Are u sure to unassign location')}</p> : <p>{t('atoms:location.Are u sure to delete location')}</p>
					}
				</Modal>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	unassignCampaignLocations: bindActionCreators(unassignCampaignLocations, dispatch),
	deleteUserLocation: bindActionCreators(deleteUserLocation, dispatch),
})

export default compose(
	withNamespaces(['atoms', 'paths']),
	connect(null, mapDispatchToProps)
)(Location)
