import React from 'react'
import { withNamespaces } from "react-i18next"
import PropTypes from "prop-types"
import { Icon, notification } from 'antd'
import { get, isEmpty } from 'lodash'
import { bindActionCreators, compose } from "redux"
import { PAGES, PATHS } from "../../enums/namespaces"
import { connect } from "react-redux"
import moment from "moment"
import { minutesToMoment } from "../../utils/formatters"
import { confirmCampaign } from "../../actions/campaignActions"
import { MSG_TYPE_TRANSLATION, CAMPAIGN_TYPES } from '../../enums/general'

class Summary extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		prevPage: PropTypes.func.isRequired,
		campaign: PropTypes.shape(),
		confirmCampaign: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
	}

	confirmCampaign = () => {
		const { campaign, confirmCampaign, t } = this.props
		
		// check location count if it is geo fancing campaign
		if (get(campaign, 'campaign.type') === CAMPAIGN_TYPES.GEO_FENCING) {
			const locations = get(campaign, 'locations')

			if (!isEmpty(locations)) {
				confirmCampaign(get(campaign, 'campaign.id'))
			} else {
				notification.error({
					message: MSG_TYPE_TRANSLATION.ERROR,
					description: t('components:locationError.text')
				})
			}
		} else {
			confirmCampaign(get(campaign, 'campaign.id'))
		}
	}

	render() {
		const { t, prevPage, campaign } = this.props

		const VIEW_MODE = {
			TURBO: t('components:Turbo title'),
			STANDARD: t('components:Standard title')
		}
		const LANGUAGE = {
			'en': t('components:English'),
			'sk': t('components:Slovak')
		}
		
		const startTime = minutesToMoment(get(campaign, 'campaign.startTime')).local()
		const endTime = minutesToMoment(get(campaign, 'campaign.endTime')).local()

		return (
			<React.Fragment>
				<div className={'row'}>
					<h2>{t('components:summary.title')}</h2>
					<p>{t('components:summary.text')}</p>
				</div>
				<div className={'row'}>
					<div className={'summary-list'}>
						<div className={'item'}><span className={'title'}>{t('components:CampaignInfoForm.Názov kampane')}</span><span className={'value'}>{get(campaign, 'campaign.name')}</span></div>
						<div className={'item'}><span className={'title'}>{t('components:Active from')}</span><span className={'value'}>{get(campaign, 'campaign.validFrom') ? moment(get(campaign, 'campaign.validFrom')).format('DD.MM.YYYY') : ''}</span></div>
						<div className={'item'}><span className={'title'}>{t('components:Active to')}</span><span className={'value'}>{get(campaign, 'campaign.validTo') ? moment(get(campaign, 'campaign.validTo')).format('DD.MM.YYYY') : ''}</span></div>
						<div className={'item'}><span className={'title'}>{t('components:Start time')}</span><span className={'value'}>{startTime.format('HH:mm')}</span></div>
						<div className={'item'}><span className={'title'}>{t('components:End time')}</span><span className={'value'}>{endTime.format('HH:mm')}</span></div>
						<div className={'item'}><span className={'title'}>{t('components:Language')}</span><span className={'value'}>{LANGUAGE[get(campaign, 'campaign.language')]}</span></div>
						<div className={'item'}><span className={'title'}>{t('components:Number of views')}</span><span className={'value'}>{get(campaign, 'campaign.views')}</span></div>
						<div className={'item'}><span className={'title'}>{t('components:CampaignInfoForm.Zobrazovací mód')}</span><span className={'value'}>{VIEW_MODE[get(campaign, 'campaign.viewMode')]}</span></div>
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-md-12 steps-action'}>
						<button className={'btn'} style={{ float: 'left' }} onClick={prevPage}><Icon type="left"/>&nbsp;{t('components:Previous step')}</button>
						<button className={'btn primary-yellow'} style={{ float: 'right' }} onClick={this.confirmCampaign}>{t('components:Confirm campaign')}&nbsp;<Icon type="right"/></button>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	campaign: get(state, 'campaigns.campaign.data'),
})

const mapDispatchToProps = (dispatch) => ({
	confirmCampaign: bindActionCreators(confirmCampaign, dispatch),
})

export default compose(
	withNamespaces([PAGES, PATHS, 'components']),
	connect(mapStateToProps, mapDispatchToProps)
)(Summary)
